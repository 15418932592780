import { Collapse, Divider, Group, Text, UnstyledButton } from '@mantine/core';
import { IconProps } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UseAccess } from '../../Utils/getAccess';
import classes from './LinksGroup.module.css';

// Define the Props for the LinksGroup component
interface Link {
  label: string;
  link: string;
  data?: any;
  access: string
}

interface LinksGroupProps {
  label: string;
  icon: React.ComponentType<IconProps>;
  initiallyOpened?: boolean;
  links?: Link[];
  link?: string;
  access?: string
}

export const LinksGroup: React.FC<LinksGroupProps> = ({ label, icon: Icon, initiallyOpened = false, links, link, access }) => {
  const [opened, setOpened] = useState(initiallyOpened);
  const navigate = useNavigate();
  const authorized = access ? UseAccess(access) : true;

  // Toggle the collapse state when the parent item is clicked
  const handleToggle = () => {
    setOpened(!opened)
    if (link) {
      navigate(link);
    }
  };

  return (
    <div>
      {/* Parent Group, click to toggle the nested links */}
      {authorized && (<><UnstyledButton onClick={handleToggle} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: '5px 10px' }}>
        <Group align="apart" wrap='nowrap'>
          <Group>
            <Icon />
            <Text>{label}</Text>
          </Group>
        </Group>
      </UnstyledButton>

        {/* Divider for styling */}
        <Divider my="sm" />
      </>
      )}

      {/* Collapse to show/hide nested links */}
      {authorized && links && (
        <Collapse in={opened}>
          <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'column', gap: '5px' }}>
            {links.map((link) => {
              const authorizedLink = link.access ? UseAccess(link.access) : false;
              if (!authorizedLink) return null;
              return (
                <UnstyledButton key={link.label} className={classes.UnstyledButton} onClick={() => navigate(link.link, { state: link.data })}>
                  {link.label}
                </UnstyledButton>
              )
            })}
          </div>
        </Collapse>
      )}
    </div>
  );
};
