import { Button } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { useEffect, useState } from "react";


pdfMake.vfs = pdfFonts.vfs;

interface ReceivedQty {
    size: string;
    quantity: number;
}

interface AllocationData {
    style_id: number;
    supplierName: string;
    receivedQty: ReceivedQty[];
    total: number;
    totalAllocationPerSize: number[];
    overstockPerSize: number[];
    poNo: string;
    skuNumbers: string[];
    styleQty_id: number;
    styles: StyleData;
    style_no: string;
    description: string;
    color: string;
    cost: number;
    msrp: number;
}

interface ReceivedQty {
    size: string;
    quantity: number;
}

interface StyleData {

    supplier_name: string;
    total_qty: number;
    location: string;
    first_name: string;
    receivedQty: ReceivedQty[];
}
interface CreatePdfProps {
    po_no: string,
    po_id?: number,
    token: string,
    filter: any;
}

// function capitalizeFirstLetter(str: string): string {
//   return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
// }
const createPdf = (data: AllocationData[], filter: any) => {
    const transformedData = data.map((allocation) => {
        return allocation.receivedQty.map((rq, rqIndex) => [
            {
                text: rqIndex === 0 ? allocation.style_no : '',
                fillColor: rqIndex === 0 ? '#ebebeb' : null,
                style: 'bodyText'
            },
            {
                text: rqIndex === 0 ? allocation.description : '',
                fillColor: rqIndex === 0 ? '#ebebeb' : null,
                style: 'bodyText'
            },
            {
                text: rqIndex === 0 ? allocation.color : '',
                fillColor: rqIndex === 0 ? '#ebebeb' : null,
                style: 'bodyText'
            },
            {
                text: rqIndex === 0 ? [{ text: '$', bold: true }, allocation.cost] : '',
                fillColor: rqIndex === 0 ? '#ebebeb' : null,
                style: 'bodyText'
            },
            {
                text: rqIndex === 0 ? [{ text: '$', bold: true }, allocation.msrp] : '',
                fillColor: rqIndex === 0 ? '#ebebeb' : null,
                style: 'bodyText'
            },
            {
                text: rqIndex === 0 ? allocation.total.toString() : '',
                fillColor: rqIndex === 0 ? '#ebebeb' : null,
                style: 'bodyText'
            },
            { text: rq.size, fillColor: rqIndex === 0 ? '#ebebeb' : null, style: 'bodyText' },
            { text: rq.quantity.toString(), fillColor: rqIndex === 0 ? '#ebebeb' : null, style: 'bodyText' },
            { text: allocation.totalAllocationPerSize[rqIndex].toString(), fillColor: rqIndex === 0 ? '#ebebeb' : null, style: 'bodyText' },
            { text: allocation.overstockPerSize[rqIndex].toString(), fillColor: rqIndex === 0 ? '#ebebeb' : null, style: 'bodyText' },
            {
                text: rqIndex === 0 ? allocation.total * allocation.cost : '',
                fillColor: rqIndex === 0 ? '#ebebeb' : null,
                style: 'bodyText'
            },
        ]);
    }).flat();
    const headerRowStyle = {
        fillColor: '#CCCCCC', // Background color
        color: '#000000', // Text color
        bold: true,

    };

    const documentDefinition: TDocumentDefinitions = {
        content: [
            {
                text: 'PO Worksheet',
                fontSize: 20,
                bold: true,
                alignment: 'center',
                decoration: 'underline',
                margin: [0, 0, 0, 20],
            },
            {
                text: `Supplier: ${data[0].supplierName} | PO No: ${filter.po_no.toUpperCase()}`,
                bold: true,
                margin: [-34, 0, 0, 10],
            },
            {
                margin: [-34, 0, 0, 0],
                table: {
                    headerRows: 1,

                    widths: [70, 105, 35, 37, 40, 30, 30, 30, 30, 35, 35],
                    body: [
                        [
                            { text: 'Style No', style: headerRowStyle },
                            { text: 'Description', style: headerRowStyle },
                            { text: 'Color', style: headerRowStyle },
                            { text: 'Cost', style: headerRowStyle },
                            { text: 'MSRP', style: headerRowStyle },
                            { text: 'Total Qty', style: headerRowStyle },
                            { text: 'Size', style: headerRowStyle },
                            { text: 'RCVQTY', style: headerRowStyle },
                            { text: 'Total Alloc', style: headerRowStyle },
                            { text: 'OS', style: headerRowStyle },
                            { text: 'Total($)', style: headerRowStyle },
                        ],
                        ...transformedData,

                    ],

                },
            },
            {
                columns: [
                    {
                        text: `Total Quantity: ${data.reduce((total, allocation) => total + allocation.total, 0)}`,
                        fontSize: 14,
                        bold: true,
                        alignment: 'left',
                        margin: [0, 15, 0, 0]
                    },
                    {
                        text: `Total Amount: $${data.reduce((total, allocation) => total + allocation.total * allocation.cost, 0)}`,
                        fontSize: 14,
                        bold: true,
                        alignment: 'right',
                        margin: [0, 15, 0, 0]
                    },
                ],
            },
        ],
        styles: {
            bodyText: {
                fontSize: 10
            }
        }
    } as TDocumentDefinitions;

    pdfMake.createPdf(documentDefinition).open();
};

const PoReport: React.FC<CreatePdfProps> = ({ po_id, po_no, filter, token }) => {
    const [reportData, setReportData] = useState<AllocationData[]>([]);

    const getData = async () => {
        // if(po_no === '' || filter.supplier_name === '' || filter.store === '') return;
        try {
            const response = await fetch('/allocation/transferList', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    po_id,
                    ...filter,
                    po_no,
                    report: true
                })
            });
            const { data } = await response.json();
            setReportData(data);
        } catch (error) {
            console.log('Error generating report:', error);
            alert('Error generatin report');
        }
    }
    useEffect(() => {
        getData();
    }, [])
    return (
        <Button onClick={() => createPdf(reportData, filter)} variant="subtle" color="dark" ><IconDownload /></Button>
    )
}

export default PoReport;