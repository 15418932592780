// Login.tsx
import { Button, Container, Grid, PasswordInput, TextInput, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/NavBar/NavBar';
import { RootState } from '../config/auth/store';
import { loginFailure, loginSuccess } from '../config/auth/userSlice';
import { IUserSession } from '../interface/User';
import { getUserInfo } from '../Utils/getUserInfo';

const LoginPage: React.FC = () => {
    const authenticatedUser = useSelector((state: RootState) => state.user.user);
    const isAuthenticated = authenticatedUser?.first_name ? true : false;
    // const [loginForm, setLoginForm] = useState({ username: '', password: '' });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useMantineTheme(); // Access the current Mantine theme
    const isMobile = useMediaQuery('(max-width: 768px)');

    const loginForm = useForm({
        initialValues: {
            email: '',
            password: '',
        },
        // email or password should not be empty
        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            password: (value) => (value.length < 1 ? 'Password is required' : null),
        },
    })

    //handle submit
    const handleLogin = async (values: { email: string; password: string; }) => {
        // dispatch(loginRequest()); // Set loading to true
        try {
            const response = await fetch(`auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });
            const data = await response.json();
            if (!response.ok) {
                alert(data.message);
                return;
            }
            if (response.ok) {
                const sessionId = data.token;

                // const userData = await response.json();
                // const userData = { email: data.user.email, first_name: data.user.first_name, last_name: data.user.last_name, scope: data.user.scope, location: data.user.location };
                const userData = getUserInfo(sessionId) as IUserSession;
                if (!userData || userData === undefined) {
                    navigate('/login');
                }
                if (sessionId) {
                    dispatch(loginSuccess({ sessionId, user: userData }));
                    navigate('/');
                    alert('Login successful!');
                    // Successful login
                } else {
                    // Login failed
                    dispatch(loginFailure('Session ID not found.'));
                    alert('Login failed. Please try again.');
                }

                // // set the user in the Redux store
                // dispatch(setUser({
                //     email: values.email,
                //     location: 'DC-Caledonia',
                //     first_name: 'Hardik',
                //     last_name: 'Prajapati',
                //     scope: 'SuperAdmin',
                // }));
            }
        } catch (error: any) {
            console.error('Error logging in:', error);
            alert(`Error logging in: ${error.message}`);
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, []);

    return (
        <div>
            <NavBar />
            <Container style={{ backgroundColor: theme.colors.gray[1], borderRadius: '10px', width: isMobile ? '90%' : '50%', filter: 'drop-shadow(4px 6px 6px rgba(60, 60, 60, 0.5))' }}>
                <Grid columns={12} style={{ marginTop: '180px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Grid.Col span={12}>
                        <h1 style={{ textAlign: 'center', color: theme.colors.gray[9]}}>Welcome to DC Warehouse Management</h1>
                        <form onSubmit={loginForm.onSubmit(handleLogin)} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <TextInput
                                label="Email"
                                style={{
                                    label: { color: 'white', fontSize: '16px', fontWeight: 'bold' }, // Style the label
                                  }}
                                placeholder="Enter your email"
                                {...loginForm.getInputProps('email')}
                            />
                            <PasswordInput
                                label="Password"
                                placeholder="Enter your password"
                                type="password"
                                {...loginForm.getInputProps('password')}
                            />
                            <Button style={{ marginTop: '30px', marginBottom: '20px',fontSize: '15px', borderRadius: '10px' }} 
                            color={theme.colors.gray[9]} type="submit">Login</Button>
                        </form>
                    </Grid.Col>
                </Grid>
            </Container>
        </div>
    );
};

export default LoginPage;