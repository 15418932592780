import React from 'react';
import NavBar from '../../components/NavBar/NavBar';
import '../Global.css'

const LogSheet:React.FC = () =>{
    return(
        <div>
            <NavBar/>
            <div className={'main'}>
                <h2 className={'title'}>LogSheet</h2>
            </div>
        </div>
    )
}

export default LogSheet;