import { Button, Grid, Modal, PasswordInput, Table, Text, TextInput, useMantineTheme } from '@mantine/core';
import { IconGraph, IconPlus, IconTrash } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NavBar from '../../components/NavBar/NavBar';
import UserModalProps from '../Admin/userModal';
import classes from './ManageUsers.module.css';
import { stat } from 'fs';

const ManageUsers: React.FC = () => {
    const [userList, setUserList] = useState([]);
    const [password, setPassword] = useState<{ password: string, confirmPassword: string }>({ password: '', confirmPassword: '' })
    const [userModal, setUserModal] = useState(false);
    const [user, setUser] = useState({} as UserModalProps);
    const theme = useMantineTheme();
    const token = useSelector((state: any) => state.user.sessionId);
    const authUser = useSelector((state: any) => state.user.user);
    const [stats, setStats] = useState<any>();
    const [viewModal, setViewModal] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<string>('');

    const getData = async () => {
        try {
            const response = await fetch(`/auth/users`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ scope: 'User' })
            });
            const data = await response.json();
            if (response.status !== 200) {
                console.log('Unable to get the data.');
            }
            setUserList(data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getData();
    }, []);



    const getStats = async (user: UserModalProps) => {
        try {
            const response = await fetch(`/stats/userStats`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ userId: user._id, userName: user.email.split('@')[0] })
            })
            const { data, message } = await response.json();
            await setStats(data);
            await setUser(user);
            if (response.status === 200) {
                setViewModal(true);
            }
        } catch (error) {
            console.log('Error getting stats:', error);
            return;
        }
    }

    const handleUserPerformance = async (user: UserModalProps) => {
        getStats(user)
    }
    const handleDeleteUser = async (user: UserModalProps) => {
        try {
            const response = await fetch(`auth/deleteUser`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ userId: user._id, scope: user.scope })
            });
            if (response.status === 200) {
                const { data } = await response.json();
                setUserList(userList.filter((item: any) => item._id !== data));
                alert('User deleted successfully.');
                return;
            }
            alert('Something went wrong.')
        } catch (error) {
            console.log('Error deleting user', error);
        }
    }
    const validateForm = async () => {
        if (!user.email || !user.first_name || !user.last_name)
            return { status: false, message: 'Fill in all the fields.' };
        if (password.password !== password.confirmPassword)
            return { status: false, message: 'Passwords do not match.' };
        // match regular expression for email
        if (!user.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/))
            return { status: false, message: 'Invalid email.' };

        return { status: true, message: '' };
    }
    const handleSave = async () => {
        let pwd = undefined;
        const valid: { status: boolean, message: string } = await validateForm();

        if (!valid.status) {
            alert(valid.message);
            return;
        }
        if (password.password === password.confirmPassword && password.password && password.confirmPassword) {
            pwd = password.password;
        }
        try {
            const response = await fetch('auth/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ ...user, password: pwd, scope: 'User' })
            });
            if (response.status === 400) {
                alert('User already exists.');
                return;
            }
            if (response.status === 200) {
                alert('User updated successfully.');
                setUser({} as UserModalProps);
                setPassword({ password: '', confirmPassword: '' })
                return;
            }
            alert(`Error creating user:${user.first_name}`);
        } catch (error) {
            alert('Something went wrong.')
            console.log(error);
        }
    }
    const renderExpandedRowDetails = (row: any) => {
        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', justifyContent: 'center' }}>
                    <Text>Lead by:</Text>
                    <Text fw={550}>
                        {row.first_name.toUpperCase()}
                    </Text>
                </div>
                <Table withColumnBorders withRowBorders withTableBorder striped stickyHeader style={{ borderCollapse: 'collapse' }}>
                    <Table.Thead>
                        <Table.Tr className={classes.expandedTableHead}>
                            <Table.Th>Stores</Table.Th>
                            <Table.Th>Size</Table.Th>
                            <Table.Th>Quantity</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {row && row.index.map((a: number) => {
                            return row.sizeQuantities[a]?.map((sz: { size: string, quantity: number }, index: number) => {
                                return <Table.Tr>
                                    {index === 0 ? (<Table.Th rowSpan={index === 0 ? row.sizeQuantities[a - 1].length : 1}>{index === 0 ? row.storeName[a] : null}</Table.Th>) : null}
                                    <Table.Td>{sz.size}</Table.Td>
                                    <Table.Td>{sz.quantity}</Table.Td>
                                </Table.Tr>
                            })
                        })}
                    </Table.Tbody>
                </Table>
            </>
        );
    };
    const ViewStatsModal = () => {
        return (
            <Modal
                opened={viewModal}
                onClose={() => setViewModal(false)}
                title={`${user?.first_name}`}
                centered
                size={'lg'}
            >
                <div>
                    <h2>Total:{stats?.stats?.countPerMonth?.reduce((acc: number, curr: { count: number }) => acc + curr.count, 0)} </h2>
                    {stats && (<Table withTableBorder withColumnBorders stickyHeader striped >
                        <Table.Thead >
                            <Table.Tr className={classes.tableHead}>
                                <Table.Th >Month</Table.Th>
                                <Table.Th>Total Styles</Table.Th>
                                <Table.Th>Discrepancy</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {stats?.stats?.countPerMonth?.map((item: any) => {
                                return (<>
                                    <Table.Tr className={classes.mainRows} onClick={() => setSelectedRow(item.style_no)}>
                                        <Table.Th>{item?.month}</Table.Th>
                                        <Table.Td>{item?.count}</Table.Td>
                                        <Table.Td>{item?.style_no}</Table.Td>
                                    </Table.Tr>
                                    {selectedRow === item.style_no &&
                                        <Table.Tr>
                                            <Table.Td colSpan={2}>
                                                {renderExpandedRowDetails(item)}
                                            </Table.Td>
                                        </Table.Tr>
                                    }</>
                                )
                            })}
                        </Table.Tbody>
                    </Table>)}
                    {/* <h2>Total:{stats?.stats?.countPerLead?.reduce((acc: number, curr: { count: number }) => acc + curr.count, 0)} </h2> */}
                    {stats && (<Table withTableBorder withColumnBorders stickyHeader striped >
                        <Table.Thead >
                            <Table.Tr className={classes.tableHead}>
                                <Table.Th>Lead</Table.Th>
                                <Table.Th>Total Styles</Table.Th>
                                <Table.Th>Discrepancy</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {stats?.stats?.countPerLead?.map((item: any) => {
                                return (<>
                                    <Table.Tr className={classes.mainRows} onClick={() => setSelectedRow(item.style_no)}>
                                        <Table.Th>{item?.leadName}</Table.Th>
                                        <Table.Td>{item?.count}</Table.Td>
                                        <Table.Td>{item?.style_no}</Table.Td>
                                    </Table.Tr>
                                    {selectedRow === item.style_no &&
                                        <Table.Tr>
                                            <Table.Td colSpan={2}>
                                                {renderExpandedRowDetails(item)}
                                            </Table.Td>
                                        </Table.Tr>
                                    }</>
                                )
                            })}
                        </Table.Tbody>
                    </Table>)}
                </div>
            </Modal >
        )
    }
    return (
        <>
            <NavBar />
            <ViewStatsModal />
            <Modal
                opened={userModal}
                onClose={() => setUserModal(false)}
                title="Register User"
                size="xl"
            >
                <div className={classes.container}>
                    <Grid className={classes.mainGrid}>
                        <Grid.Col span={12}>
                            <TextInput
                                label='Email'
                                type="email"
                                // disabled
                                value={user.email}
                                // placeholder="Enter your email..."
                                onChange={(e) => setUser({ ...user, email: e.target.value || '' })}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 6 }}>
                            <TextInput
                                label='First name'
                                required
                                value={user.first_name}
                                placeholder="Enter your first name..."
                                onChange={(e) => setUser({ ...user, first_name: e.target.value || '' })}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 6 }}>
                            <TextInput
                                label='Last name'
                                required
                                value={user.last_name}
                                placeholder="Enter your last name..."
                                onChange={(e) => setUser({ ...user, last_name: e.target.value || '' })}
                            />
                        </Grid.Col>
                        {/* <Grid.Col span={{ sm: 12, md: 6 }}>
                            <Select
                                label='Location'
                                data={['DC-Caledonia', 'DC']}
                                defaultValue={authUser.location}
                                onChange={(value) => {
                                    if (value === 'DC-Caledonia' || value === 'DC') {
                                        setUser({ ...user, location: value });
                                    } else {
                                        console.error(`Invalid scope value: ${value}`);
                                    }
                                }}
                            />
                        </Grid.Col> */}
                        {/* <Grid.Col span={{ sm: 12, md: 6 }}>
                            <Select
                                label='Scope'
                                data={['User', 'Admin', 'SuperAdmin']}
                                defaultValue={'User'}
                                disabled
                                onChange={(value) => {
                                    if (value === 'User' || value === 'Admin' || value === 'SuperAdmin') {
                                        setUser({ ...user, scope: value });
                                    } else {
                                        console.error(`Invalid scope value: ${value}`);
                                    }
                                }}
                            />
                        </Grid.Col> */}
                        <Grid.Col span={{ sm: 12, md: 6 }}>
                            <PasswordInput
                                label='Password'
                                type="password"
                                value={password.password}
                                placeholder="Enter your Password..."
                                onChange={(e) => setPassword({ ...password, password: e.target.value || '' })}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 6 }}>
                            <PasswordInput
                                label='Confrim Password'
                                type="password"
                                value={password.confirmPassword}
                                placeholder="Enter your password again..."
                                onChange={(e) => setPassword({ ...password, confirmPassword: e.target.value || '' })}
                            />
                        </Grid.Col>
                    </Grid>
                    <div className={classes.buttonContainer}>
                        <Button className={classes.button} onClick={handleSave}>Register</Button>
                    </div>
                </div>
            </Modal>
            <div className={'main'}>
                <h2 className={'title'}>Manage Users</h2>
                <Grid >
                    {userList.length > 0 && (<Grid.Col span={12} className={classes.tableGrid}>
                        <Table
                            verticalSpacing="xs"
                            className={classes.table}
                            striped
                            stripedColor={theme.colors.blue[0]}
                            highlightOnHover
                            stickyHeader
                        >
                            <Table.Thead className={classes.thead} style={{ width: '100%', color: theme.colors.gray[0], backgroundColor: theme.colors.gray[9] }} >
                                <Table.Tr>
                                    <Table.Th>First Name</Table.Th>
                                    <Table.Th>Last Name</Table.Th>
                                    <Table.Th>Email</Table.Th>
                                    <Table.Th>Location</Table.Th>
                                    <Table.Th>Role</Table.Th>
                                    <Table.Th>Performance</Table.Th>
                                    {/* <Table.Th>Delete</Table.Th> */}
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody className={classes.tbody}>
                                {userList.map((user: UserModalProps, index: number) => (
                                    <Table.Tr key={index}>
                                        <Table.Td>{user.first_name}</Table.Td>
                                        <Table.Td>{user.last_name}</Table.Td>
                                        <Table.Td>{user.email}</Table.Td>
                                        <Table.Td>{user.location}</Table.Td>
                                        <Table.Td>{user.scope}</Table.Td>
                                        <Table.Td><IconGraph size={21} className={classes.icon} onClick={() => handleUserPerformance(user)} /></Table.Td>
                                        {/* <Table.Td><IconTrash size={21} className={classes.icon} onClick={() => handleDeleteUser(user)} /></Table.Td> */}
                                    </Table.Tr>
                                ))}
                            </Table.Tbody>
                        </Table>
                    </Grid.Col>)}
                </Grid>
                <div className={'footerDiv'} style={{ backgroundColor: theme.colors.gray[1], color: theme.colors.gray[0], border: `1px solid ${theme.colors.gray[0]}` }}>
                    <div></div>
                    <div></div>
                    <Button variant="subtle" color="gray" size="xs" className={'btnDark'} onClick={() => setUserModal(true)}>
                        <IconPlus size={21} className={classes.icon} style={{ marginRight: '20px' }} />
                        Add User
                    </Button>
                </div>
            </div>
        </>
    )
};

export default ManageUsers;