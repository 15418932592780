// store.ts
import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // default is localStorage
import userReducer from './userSlice'; // We'll create this slice in the next step

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'sessionId'], // Add the slice name here
};

const persistedReducer = persistReducer(persistConfig, userReducer);

const store = configureStore({
  reducer: {
    user: persistedReducer, // Reducer for user authentication
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({ 
      serializableCheck: {
        ignoredActions:[FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],}}),  
});
const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { persistor, store };
