import { Button, Grid, Group, Modal, Select, Table, Text, TextInput, useMantineTheme } from '@mantine/core';
import { IconArrowLeft, IconArrowRight, IconFilter } from '@tabler/icons-react';
import { DataGrid, highlightFilterValue, stringFilterFn } from 'mantine-data-grid';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';
import TransferReport from '../../components/Reports/TransferReport';
import { STORES } from '../../constants/stores';
import { PoNoWithStatus } from '../../interface/AllocationInvoice';
import '../Global.css';
import classes from './TransferList.module.css';

const TransferList: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [rowData, setRowData] = useState<PoNoWithStatus>();
    const token = useSelector((state: any) => state.user.sessionId);
    const [tableData, setTableData] = useState<any[]>([]);
    const [filterModal, setFilterModal] = useState<boolean>(false);
    const [filter, setFilter] = useState<{ style_no: string, po_no: string, supplier_name: string, store: string }>({
        style_no: '',
        po_no: '',
        supplier_name: '',
        store: ''
    });
    const [pagination, setPagination] = useState<{ page: number, limit: number, total?: number }>({ page: 1, limit: 10, total: 1 });
    const theme = useMantineTheme();

    useEffect(() => {
        const row = location.state as PoNoWithStatus;
        if (row === undefined || row === null) {
            // navigate('/poList', { state: 'transferList' });
            // return;
        }
        setRowData(row);
        setFilter({ ...filter, po_no: filter.po_no && filter.po_no !== '' ? filter.po_no : row.poNo || '' })
        getData(filter.po_no && filter.po_no !== '' ? filter.po_no : row.poNo || '');
    }, [pagination.limit, pagination.page]);

    const getData = async (po_no?: string) => {
        try {
            const response = await fetch('/allocation/transferList', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    po_id: rowData?.poId,
                    ...filter,
                    po_no: po_no ? po_no : filter.po_no,
                    ...pagination
                })
            });
            const { data, total } = await response.json();

            if (response.status === 200) {
                await setTableData(data);
                await setPagination({ ...pagination, total: total });
                return;
            }
            alert('Could not find the data. Please check the filter values again.');
            setTableData([]);
            await setPagination({ ...pagination, page: 1, total: 1 });
            return;

        } catch (error) {
            console.log('Error fetcing transfer data.')
        }
    }
    const handlePagination = (operation: string) => {
        if (operation === 'increase') {
            setPagination({ ...pagination, page: pagination.page === pagination.total ? pagination.page : pagination.page + 1 })
        } else {
            setPagination({ ...pagination, page: pagination.page === 1 ? pagination.page : pagination.page - 1 })
        }
    }
    // Function to render the expanded row details
    const renderExpandedRowDetails = (row: any) => {
        return (
            <Table withColumnBorders withTableBorder striped stickyHeader style={{ width: '96vw', borderCollapse: 'collapse' }}>
                <Table.Thead className={classes.tableHead}>
                    <Table.Tr>
                        <Table.Th>Store</Table.Th>
                        <Table.Th>Size</Table.Th>
                        <Table.Th>Quantity</Table.Th>
                        <Table.Th>Total</Table.Th>
                        <Table.Th>Status</Table.Th>
                        <Table.Th>Initial</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {row.storeName && row?.storeName.map((store: string, index: number) => {
                        // Get the number of sizes for this store
                        const sizeCount = row.sizeQuantities[index].length;
                        let total = row.sizeQuantities[index].reduce((a: number, b: { quantity: number }) => a + b.quantity, 0);
                        return row.sizeQuantities[index].map((sizeData: any, i: number) => (
                            <Table.Tr key={store + sizeData.size}>
                                {i === 0 ? <Table.Td rowSpan={i === 0 ? sizeCount : 1} >{store}</Table.Td> : null}
                                <Table.Td >{sizeData.size}</Table.Td>
                                <Table.Td style={{ border: `1px solid ${theme.colors.gray[2]}` }}>{sizeData.quantity}</Table.Td>
                                {i === 0 ? <Table.Td rowSpan={i === 0 ? sizeCount : 1} >{i === 0 ? total : null}</Table.Td> : null}
                                {i === 0 ? <Table.Td rowSpan={i === 0 ? sizeCount : 1} >{i === 0 ? row.allocation_status[index] ? 'Allocated' : 'Not Allocated' : null}</Table.Td> : null}
                                {i === 0 ? <Table.Td rowSpan={i === 0 ? sizeCount : 1} >{i === 0 ? row.initial[index] : null}</Table.Td> : null}
                            </Table.Tr>
                        ))
                    })}
                </Table.Tbody>
            </Table>
        );
    };
    const handleApplyFilter = async () => {
        await getData(filter.po_no);
        setFilterModal(false);
    }


    return (
        <div>
            <NavBar />
            <div className={classes.main}>
                <h2>Transfer List</h2>
                <div style={{ width: '100%', marginBottom: '50px' }}>
                    {tableData?.length > 0 && (<DataGrid
                        data={tableData}
                        striped
                        withFixedHeader
                        highlightOnHover
                        // onSearch={(value) => setPo({ ...po, poNo: value.toUpperCase() })}
                        // onSort={handleSortingChange}
                        // onFilter={handleFilterChange}
                        withColumnBorders
                        withColumnFilters
                        withSorting
                        // onRow={(row) => ({
                        //     onClick: () => handleRowClick(row.original),
                        // })}
                        renderSubComponent={(row) => renderExpandedRowDetails(row.original)}
                        getRowCanExpand={() => true}
                        onRow={(row) => {
                            return row.getCanExpand()
                                ? {
                                    onClick: row.getToggleExpandedHandler(),
                                    style: { cursor: 'pointer' },
                                }
                                : {};
                        }}
                        // onExpandedChange={}
                        styles={{
                            table: {
                                border: '1px solid black',
                                radius: '5px',
                            },
                            thead: {
                                border: '1px solid black',
                                backgroundColor: theme.colors.gray[9],
                                color: theme.colors.gray[0],
                            },
                            th: {
                                color: `${theme.colors.gray[0]} !important`,
                                td: { color: `${theme.colors.gray[0]}`, },
                            },
                            tr: {
                                cursor: 'pointer',
                                width: '100%',
                            },
                            // Inline styles with important }, 

                        }}
                        columns={[
                            {
                                accessorKey: 'style_no',
                                header: 'Style No',
                                filterFn: stringFilterFn,
                                // size: 200,
                                cell: highlightFilterValue,
                            },
                            {
                                accessorKey: 'description',
                                header: 'Description',
                                filterFn: stringFilterFn,
                                // size: 70,
                            },
                            {
                                accessorKey: 'color',
                                header: 'Color',
                                filterFn: stringFilterFn,
                                // size: 70,
                            },
                            {
                                accessorKey: 'cost',
                                header: 'Cost',
                                // filterFn: stringFilterFn,
                                size: 70,
                            },
                            {
                                accessorKey: 'msrp',
                                header: 'MSRP',
                                // filterFn: stringFilterFn,
                                size: 90,
                            },
                            {
                                accessorKey: 'supplierName',
                                header: 'Supplier',
                                filterFn: stringFilterFn,
                                // size: 70,
                            },
                            {
                                accessorKey: 'po_no',
                                header: 'PoNo',
                                filterFn: stringFilterFn,
                                // size: 70,
                            },
                            {
                                accessorKey: 'notes',
                                header: 'Notes',
                                filterFn: stringFilterFn,
                                // size: 70,
                            },
                            {
                                accessorKey: 'allocation_first_name',
                                header: 'Lead',
                                filterFn: stringFilterFn,
                                size: 100,
                            },
                        ]}
                    />)}
                </div>
            </div>
            <div className={"footerDiv"}>
                <div>
                    <Button variant='outline' className={classes.bottomBtn} onClick={() => setFilterModal(true)} ><IconFilter /> Filter</Button>
                </div>
                <Group>
                    <div className={classes.paginationGroup}>
                        <Button disabled={pagination.page === 1} className={classes.pagincationIcon} size='xs' onClick={() => handlePagination('decrease')}><IconArrowLeft /></Button>
                        <Text size='lg' fw={500}>{pagination.page}/{pagination.total || 1}</Text>
                        <Button disabled={pagination.page === pagination.total} className={classes.pagincationIcon} size='xs' onClick={() => handlePagination('increase')}><IconArrowRight /></Button>
                    </div>
                </Group>
                <div className={classes.paginationGroup}>
                    <TransferReport po_no={filter.po_no} filter={filter} token={token} />
                    <Select
                        data={['10', '20', '30']}
                        defaultValue={'10'}
                        style={{ width: '70px' }}
                        onChange={(value) => setPagination({ ...pagination, limit: parseInt(value || '10') })}
                    />
                </div>
            </div>
            <Modal
                opened={filterModal}
                onClose={() => setFilterModal(false)}
                title={`Filter Options`}
                centered
                size={'lg'}
            >
                <div>
                    <Grid>
                        <Grid.Col span={6} style={{ gap: '20px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <Table withTableBorder withColumnBorders stickyHeader>
                                    <Table.Tbody>
                                        <Table.Tr>
                                            <Table.Th className={classes.tableHeadModal}>Style No</Table.Th>
                                            <Table.Td ><TextInput
                                                type='text'
                                                variant='unstyled'
                                                value={filter.style_no}
                                                className={classes.infoText}
                                                onChange={(e) => setFilter({ ...filter, style_no: e.target.value.toUpperCase() })}
                                            /></Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Th className={classes.tableHeadModal}>Po No</Table.Th>
                                            <Table.Td ><TextInput
                                                variant='unstyled'
                                                type='text'
                                                value={filter.po_no}
                                                onChange={(e) => setFilter({ ...filter, po_no: e.target.value.toUpperCase() })}
                                            /></Table.Td>
                                        </Table.Tr>
                                    </Table.Tbody>
                                </Table>
                            </div>
                        </Grid.Col>
                        <Grid.Col span={6} >
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <Table withTableBorder withColumnBorders stickyHeader>
                                    <Table.Tbody>
                                        <Table.Tr>
                                            <Table.Th className={classes.tableHeadModal}>Supplier</Table.Th>
                                            <Table.Td ><TextInput
                                                type='text'
                                                variant='unstyled'
                                                value={filter.supplier_name}
                                                className={classes.infoText}
                                                onChange={(e) => setFilter({ ...filter, supplier_name: e.target.value.toUpperCase() })}
                                            /></Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Th className={classes.tableHeadModal}>Store</Table.Th>
                                            <Table.Td ><Select
                                                variant='unstyled'
                                                defaultValue={'Clothing'}
                                                data={STORES}
                                                searchable
                                                onChange={(value) => setFilter({ ...filter, store: value || '' })}
                                            /></Table.Td>
                                        </Table.Tr>
                                    </Table.Tbody>
                                </Table>
                            </div>
                        </Grid.Col>
                    </Grid>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <Button style={{ backgroundColor: theme.colors.gray[8], width: '20vw' }} onClick={handleApplyFilter}>Apply</Button>
                </div>
            </Modal>
        </div>
    )
}

export default TransferList;