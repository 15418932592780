// NavBar.tsx
import { Avatar, Grid, Group, Image, Menu, rem, ScrollArea, Text, UnstyledButton, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useEffect, useRef, useState } from "react";
import classes from "./NavBar.module.css";

import {
    IconLock,
    IconLogout,
    IconMenu2,
    IconSettings,
    IconUser
} from '@tabler/icons-react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import logo from "../assets/logoWhite_noTag.svg";
import logo from "../../assets/DC_CAL_LOGO_03.svg";
import profileLogo from '../../assets/profileWhite.svg';
import { logout } from "../../config/auth/userSlice";
import useCheckAuthToken from "../../Hooks/useCheckAuthToken";
import { UserButton } from "../UserButton/UserButton";
import { LinksGroup } from "./LinksGroup";
import { MENU_ITEMS } from "./MenuItems";
import { NavLinksGroup } from "./NavLinksGroup";




const NavBar: React.FC = () => {
    const isMobile = useMediaQuery("(min-width: 0) and (max-width: 550px)") || false;
    const isTablet = useMediaQuery("(min-width: 551px) and (max-width: 1323px)") || false;
    const isDesktop = useMediaQuery("(min-width: 1324px)") || false;
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [openedGroup, setOpenedGroup] = useState<string | null>(null);
    const sidebarRef = useRef<HTMLDivElement>(null);  // Ref for sidebar
    const user = useSelector((state: any) => state.user.user);
    const isAuthenticated = useCheckAuthToken();
    const theme = useMantineTheme();
    const dispatch = useDispatch();

    // Handle toggle for collapsing links
    const handleToggle = (group: string) => {
        setOpenedGroup((prev) => (prev === group ? null : group)); // Toggle open/close
    };
    const navigate = useNavigate();
    const links = MENU_ITEMS.map((item) => <LinksGroup {...item} key={item.label} />);
    const menuItems = MENU_ITEMS.map((item) => <NavLinksGroup
        key={item.label}
        label={item.label}
        icon={item.icon}
        links={item.links}
        opened={openedGroup === item.label} // Check if this group is the currently opened one
        onToggle={() => handleToggle(item.label)} // Pass the toggle handler
        // link={item.link}
        access={item.access}
    />);
    const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
                setSidebarOpen(false);  // Close sidebar if clicked outside
            }
        };

        // Add the event listener
        document.addEventListener("click", handleClickOutside);

        // Clean up the event listener
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
    const handleLogOut = () => {
        dispatch(logout());
        navigate('/login');
    }

    return (
        <div>
            <div className={classes.navHeader} >
                <Grid columns={12} style={{ width: '100%' }}>
                    <Grid.Col span={2}>
                        {isAuthenticated && (isMobile || isTablet) && (
                            <UnstyledButton onClick={toggleSidebar}>
                                <IconMenu2 size={30} style={{ color: theme.colors.gray[1] }} />
                            </UnstyledButton>)}
                        {!isMobile && !isTablet && (<Group justify="space-between" className={classes.logo}>
                            <Image src={logo} alt="SVP IMS" width={30} height={40} onClick={() => navigate('/')} style={{ cursor: 'pointer' }} />
                            {/* <Image src={logo2} alt="Profile" width={30} height={40} onClick={() => navigate('/profile')} style={{ cursor: 'pointer' }} /> */}
                        </Group>)}
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <Group justify="center" gap={4} style={{ gap: '20px' }}>
                            {isAuthenticated && !isMobile && !isTablet && menuItems}
                            {(isMobile || isTablet) && (<Group justify="center" className={classes.logo}>
                                <Image src={logo} style={{ cursor: 'pointer', marginLeft: '10px' }} alt="SVP IMS" width={35} height={45} onClick={() => navigate('/')} />
                                {/* <Image src={logo2} alt="Profile" width={30} height={40} onClick={() => navigate('/profile')} style={{ cursor: 'pointer' }} /> */}
                            </Group>)}
                        </Group>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        {isDesktop && (
                            <Group justify="space-between" className={classes.logo}>
                                <Menu shadow="md" width={200}>
                                    <Menu.Target>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px', flexDirection: 'row', cursor: 'pointer' }} onClick={() => console.log('Profile')}>
                                            <Avatar
                                                src={profileLogo}
                                                radius="xl"
                                            />
                                            {isAuthenticated && (<div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Text style={{ color: theme.colors.gray[1] }} size="sm" fw={500}>
                                                    {user.first_name} {user.last_name}
                                                </Text>
                                                <Text style={{ color: theme.colors.gray[1] }} size="xs" fw={400}>
                                                    {user.location}
                                                </Text>
                                            </div>)}
                                            {/* <IconChevronRight size={14} stroke={1.5} style={{ color: theme.colors.gray[1] }} /> */}
                                        </div>
                                    </Menu.Target>
                                    {isAuthenticated ? (<Menu.Dropdown>
                                        <Menu.Label>{user.email}</Menu.Label>
                                        <Menu.Item leftSection={<IconSettings style={{ width: rem(14), height: rem(14) }} />}>
                                            Settings
                                        </Menu.Item>
                                        <Menu.Item leftSection={<IconUser style={{ width: rem(14), height: rem(14) }} />} onClick={() => navigate('/profile')}>
                                            Profile
                                        </Menu.Item>
                                        {/* <Menu.Item leftSection={<IconUsers style={{ width: rem(14), height: rem(14) }} />} onClick={() => navigate('/users')}>
                                            Manage Users
                                        </Menu.Item> */}
                                        <Menu.Divider />
                                        <Menu.Item
                                            leftSection={<IconLogout style={{ width: rem(14), height: rem(14) }} />}
                                            onClick={handleLogOut}>
                                            Log out
                                        </Menu.Item>
                                    </Menu.Dropdown>) : (<Menu.Dropdown>
                                        <Menu.Item leftSection={<IconLock style={{ width: rem(14), height: rem(14) }} />} onClick={() => navigate('/login')}>
                                            Login
                                        </Menu.Item>
                                    </Menu.Dropdown>)}
                                </Menu>
                            </Group>)}
                    </Grid.Col>
                </Grid>
            </div>
            {(isMobile || isTablet) && (<nav className={`${classes.navbar} ${sidebarOpen ? classes.open : ''}`}>
                <ScrollArea className={classes.links} >
                    <div className={classes.linksInner}>{links}</div>
                </ScrollArea>
                <div className={classes.footer}>
                    <UserButton />
                </div>
            </nav>)}
            <div className={classes.copyright}>
                <Text style={{ color: theme.colors.gray[1], paddingBottom: '15px', textAlign: 'center' }} size="xs">Copyright © 2023 SVP SPORTS by IT Team</Text>
            </div>
        </div>
    );
};


export default NavBar;