import { Button, Card, Grid, Group, Text, TextInput } from '@mantine/core';
import { IconPrinter } from '@tabler/icons-react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import NavBar from '../../components/NavBar/NavBar';
import { createAllQrPdf, createBarcodePdf } from '../../Utils/qr';
import '../Global.css';
import classes from './Bin.module.css';



const Bin: React.FC = () => {
    const [bin, setBin] = useState<{ location: string, styles: string[] }>();
    const [binData, setBinData] = useState<any[]>([]);
    const token = useSelector((state: any) => state.user.sessionId);
    const initialPagination = { page: 1, limit: 10, total: 1 };
    const [updateData, setUpdateData] = useState<{ binlocation: string, style_no: string }>({ binlocation: '', style_no: '' });
    const [inputTimeout, setInputTimeout] = useState<NodeJS.Timeout | null>(null);
    const [styleInputTimeout, setStyleInputTimeout] = useState<NodeJS.Timeout | null>(null);


    // Create refs to reference the input fields
    const binRef = useRef<HTMLInputElement | null>(null);
    const styleRef = useRef<HTMLInputElement | null>(null);

    const handleBinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdateData({ ...updateData, binlocation: e.target.value });
        if (inputTimeout) {
            clearTimeout(inputTimeout);
        }
        const timeout = setTimeout(() => {
            if (e.target.value && styleRef.current) {
                getBinData({ location: e.target.value });
                styleRef.current.focus();
            }
        }, 150);
        setInputTimeout(timeout);
    };

    const handleStyleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdateData({ ...updateData, style_no: e.target.value });
        if (styleInputTimeout) {
            clearTimeout(styleInputTimeout);
        }
        const timeout = setTimeout(() => {
            if (e.target.value) {
                updateBinLocation(updateData.binlocation, e.target.value);
            }
        }, 150); // Adjust the delay time as per your scanner's speed
        setStyleInputTimeout(timeout);
    };

    const fetchBins = async () => {
        try {
            const response = await fetch('/bin/list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    page: 1,
                    limit: 10,
                    searchTerm: ''
                })
            });
            const { data, message } = await response.json();
            setBinData(data);
            if (response.status !== 200) {
                throw new Error(message);
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchBins();
    }, [])

    const getBinData = async (bin: { location: string }) => {
        try {
            const response = await fetch(`/bin/getBin/${bin?.location}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            const { data, message } = await response.json();
            if (response.status !== 200) {
                throw new Error(message);
            }
            setBin({ location: data.location, styles: data.styles });
        } catch (error) {
            console.log(error);
        }
    }
    const updateBinLocation = async (binlocation: string, style_no: string) => {
        try {
            const response = await fetch('/bin/updateBin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ binlocation, style_no })
            });
            const { data, message } = await response.json();
            if (bin?.styles) {
                //check if style already exists in bin
                if (bin?.styles.includes(data.styles[0].style_no)) {
                    alert(`Style already exists in ${binlocation}.`)
                    return;
                } else {
                    setBin({ ...bin, location: data.styles[0].binlocation, styles: [...bin?.styles, data.styles[0].style_no] });
                }
            }

            if (response.status !== 200) {
                alert(message || 'Something went wrong!');
                throw new Error(message);
            }
            alert(message);
            setUpdateData({ binlocation: '', style_no: '' });

            // get focus back to updatedData.binlocation
            document.getElementById('binlocation')?.focus();
        } catch (error) {
            console.log(error);
        }
    }


    const handlePrint = async (barcode: string) => {
        await createBarcodePdf(barcode);
    }

    const handleAllQR = async () => {
        // print all qr
        await createAllQrPdf(binData);
    }

    return (
        <div>
            <NavBar />
            <div className={'main'}>
                <h2 className={'title'}>Bin</h2>
                <Grid>
                </Grid>
                <Grid style={{ width: '100%' }}>
                    <Grid.Col offset={1} span={10}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '40px', gap: '10px' }}>
                            <TextInput
                                placeholder='Enter bin location'
                                id='binlocation'
                                value={updateData.binlocation}
                                onChange={handleBinChange}
                                style={{ flexGrow: 1 }}
                                ref={binRef}
                            />
                            <TextInput
                                placeholder='Enter style no'
                                value={updateData.style_no}
                                onChange={handleStyleChange}
                                style={{ flexGrow: 1 }}
                                ref={styleRef}
                            />
                            {/* <Button onClick={()=>updateBinLocation(updateData.binlocation, updateData.style_no)}>Update</Button> */}
                        </div>
                        {bin?.location && (<Card className={classes.card} shadow="md" padding="lg" radius="md" >
                            <Group justify="space-between" mt="md" mb="xs">
                                <h2 className={'title'} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '10px', padding: '0px 20px 0px 20px' }}>
                                    <Text fw={500}>Bin Location : </Text>
                                    <Text fw={700} fz={36}>{bin?.location}</Text>
                                </h2>
                            </Group>
                            <Text className={classes.styleList}>
                                <Text fw={600}>Styles:</Text> {bin?.styles?.map((style: string) => <Text key={style} className={classes.styles}>{style}</Text>)}
                            </Text>
                            <Button onClick={() => handlePrint(bin?.location || '')} fullWidth mt="md" radius="md">
                                <IconPrinter />Print
                            </Button>
                        </Card>)}
                    </Grid.Col>
                    <div className={"footerDiv"}>
                        <div></div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', gap: '10px' }}>
                            <TextInput
                                placeholder='Search bin'
                                onChange={(e) => setBin({ location: e.currentTarget.value, styles: bin?.styles || [] })}
                                style={{ flexGrow: 1 }}
                            />
                            <Button onClick={() => bin && getBinData(bin)}>
                                Get Bin Data
                            </Button>
                        </div>
                        <div></div>
                    </div>
                </Grid>
            </div>
        </div>
    )
}

export default Bin;