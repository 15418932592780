import { Button } from '@mantine/core';
import pdfMake from "pdfmake/build/pdfmake";
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import React from 'react';

interface HashPrintProps {
    allocationData: any;
}

const HashPrint: React.FC<HashPrintProps> = ({ allocationData }) => {
    const handlePrintHash = async () => {
        try {
            if (!allocationData) {
                alert('Please select a Po first');
                return
            }
            let pdfData: { store: string, totalQty?: number, storeData: any }[] = [];
            allocationData.forEach((item: any) => {
                item.storeName.forEach((store: any, index: number) => {
                    const existingStore = pdfData.findIndex((itm: any) => itm.store === store);
                    const quantity = item.sizeQuantities[index].reduce((total: number, sizeData: { quantity: number }) => total + sizeData.quantity, 0);
                    if (quantity !== 0) {
                        if (existingStore !== -1) {
                            pdfData[existingStore].storeData.push({
                                style_no: item.style_no,
                                qty: quantity
                            })
                        } else {
                            pdfData.push({
                                store: store,
                                storeData: [{
                                    style_no: item.style_no,
                                    qty: quantity
                                }]
                            })
                        }
                    }
                })
            })
            generatePDF(pdfData);
        } catch (error) {
            console.log(`Error :`, error);
        }
    }

    const generatePDF = (pdfData: any) => {
        let contentData: Content[] = [];
        let storePage: Content[] = [];

        pdfData.forEach((storeData: any, index: number) => {
            storePage = [
                {
                    text: [{ text: `HASH Products for `, fontSize: 30, bold: true }, { text: `${storeData.store}`, bold:true, color: 'red' }],
                    fontSize: 30,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 0, 0, 0]
                },
                {
                    table: {
                        widths: ['*', '*'],
                        headerRows: 1,
                        body: [
                            [
                                { text: 'Style No', bold: true, alignment: 'center', fontSize: 18 },
                                { text: 'Quantity', bold: true, alignment: 'center', fontSize: 18 }
                            ],
                            ...storeData.storeData.map((item: any) => {
                                // Ensure both 'style_no' and 'qty' are valid
                                if (!item.style_no || !item.qty) {
                                    console.error('Invalid data found in row:', item);  // Log the invalid item
                                    return ['Invalid', 'Invalid'];  // Replace with placeholders
                                }
                                return [{ text: `${item.style_no}`, alignment: 'center', fontSize: 14 }, { text: `${item.qty}`, alignment: 'center', fontSize: 14 }];  // Return valid row
                            }),
                        ]
                    },
                    pageBreak: index === pdfData.length - 1 ? undefined : 'after',
                },
            ];
            contentData.push(storePage);
        });


        const docDefinition: TDocumentDefinitions = {
            content: contentData,
            pageSize: 'LETTER',
            pageOrientation: 'portrait',
        };

        pdfMake.createPdf(docDefinition).open();
    };

    return (
        <Button className={'btn'} onClick={handlePrintHash}>Print Hash</Button>
    )
}

export default HashPrint;