export const getPono = async (token: string, searchTerm?: string, pagination?: { limit?: number, page?: number }) => {
    try {
        const response = await fetch('/po/getPoNoList', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ searchTerm, limit: pagination?.limit || 5, page: pagination?.page || 1 }),
        });
        if (response.status === 204) {
            return { data: null, total: 0 };
        }
        const data = await response.json();
        return { data: data.poNoWithStatus, total: data.total };
    } catch (error) {
        console.log('Error fetching PONO:', error);
    }
}