export function replaceSpacesWithUnderscore(inputString: string): string {
    return inputString.includes(' ') ? inputString.replace(/ /g, '_') : inputString;
}

export const FEATURE_FLAGS: any = {
    // Add all the feature flags here
    IMAGE_UPLOAD: true    // before making it true, make sure to add image(text) coulmn in style table in db
}

export async function getImageBase64(imagePath: string) {
    // Check if the incoming imagePath is already in base64 format
    if (imagePath.startsWith('data:image/')) {
        // If it's already base64, just return it
        return imagePath;
    }

    // Otherwise, treat imagePath as a URL and fetch it to convert to base64
    try {
        const response = await fetch(imagePath); // Fetch the image as a Blob
        const blob = await response.blob();

        // Convert Blob to base64 string
        const reader = new FileReader();

        return new Promise((resolve, reject) => {
            reader.onloadend = () => {
                resolve(reader.result as string);  // Return the base64 string
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(blob);  // Read the blob as a base64 string
        });
    } catch (error) {
        console.error('Error fetching image:', error);
        return '';
    }
}

export function getShippingDetails(location: string) {
    switch (location) {
        case 'DC-Caledonia':
            return {
                company: 'DCCAL',
                name:'SVP SPORTS DCCAL',
                address: '1100 Caledonia Rd,',
                city: 'Toronto, ON M6A 2W5',
                email: 'gash@svpsports.ca',
                contact:[
                    {name:'Gash',phone:'647-894-9856',email:'gash@svpsports.ca'},
                    {name:'Bhavin',phone:'437-437-1309',email:''},
                ],
                note:'Back at dock no 4 only CONTACT: Gash(647-894-9856) OR Bhavin(437-437-1309)'
            }
        case 'DC':  
            return {
                company: 'DC',
                name:'SVP SPORTS DC',
                address: '400 Harry Walker Pkwy S,',
                city: 'Newmarket, ON L3Y 9C4',
                email: 'justin@svpsports.ca',
                contact:[
                    {name:'Justin',phone:'647-894-9856',email:'justin@svpsports.ca'},
                ],
                

            }
        case 'OPM-Aurora':
            return {
                company: 'OAUR',
                name:'SVP SPORTS OPM-AUR',
                address: '100 Vandorf Sideroad,',
                city: 'Aurora, ON L4G 3G9',
                email: 'justin@svpsports.ca',
                contact:[
                    {name:'Glen',phone:'',email:''},
                ]
            }
        case 'SVP-Queensway':
            return {
                company: 'SVPQW',
                name:'SVP SPORTS SVP-QW',
                address: '1255 The Queensway',
                city: 'Etobicoke ON M8Z 1S2',
                email: 'justin@svpsports.ca',
                contact:[
                    {name:'Glen',phone:'',email:''},
                ],
                note:'DO NOT Back at dock no 3'
            }
        case 'SVP-Kingston':
            return {
                company: 'SVPKG',
                name:'SVP SPORTS SVP-KINGSTON',
                address: '636 Gardiners Rd., Unit #12,',
                city: 'Kingston, ON, K7M 3X9',
                email: 'justin@svpsports.ca',
                contact:[
                    {name:'Glen',phone:'',email:''},
                ]
            }
        default:
            return {
                company: 'DC-Caledonia',
                name:'SVP SPORTS DC-CAL',
                address: '1100 Caledonia Rd, ON M6A 2W5 ',
                city: 'Toronto, ON M6A 2W5',
                email: 'gash@svpsports.ca',
            }
    }
}

// export const IMAGE_PATH = `http://192.168.0.135:3000/public/styleImages/`;