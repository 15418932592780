import { Grid, Image, Input, Select, Switch, TextInput, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPono } from '../../api/poList';
import { getAllocationList } from '../../api/styleList';
import NavBar from '../../components/NavBar/NavBar';
import HashPrint from '../../components/Print/HashPrint';
import { useDebounce } from '../../Hooks/useDebounce';
import { AllocationData } from '../../interface/Allocation';
import { PoNoWithStatus } from '../../interface/AllocationInvoice';
import '../Global.css';
import classes from './PrintLabel.module.css';
import PrintStoreLabels from '../../components/Print/PrintStorLabels';
import { getImageBase64 } from '../../Utils/utils';

const PrintLabel = () => {
    const [poNoList, setPoNoList] = useState<any[]>([]);
    const [styleList, setStyleList] = useState<any[]>([]);
    const [allocation, setAllocation] = useState<AllocationData>();
    const [allocationData, setAllocationData] = useState<any[]>([]);
    const [po, setPo] = useState<{ poNo: string, poId: string }>({ poNo: '', poId: '' });
    const poSearch = useDebounce(po.poNo, 700);
    const token = useSelector((state: any) => state.user.sessionId);
    const theme = useMantineTheme();
    const isMobile = useMediaQuery("(min-width: 0) and (max-width: 550px)") || false;
    const isTablet = useMediaQuery("(min-width: 551px) and (max-width: 1198px)") || false;
    const isDesktop = useMediaQuery("(min-width: 1199px)") || false;
    const [manual, setManual] = useState<boolean>(false);
    const [single, setSingle] = useState<boolean>(false);
    const [imageData, setImageData] = useState<string>('');
    const [orientation, setOrientation] = useState<'portrait' | 'landscape'>('portrait');
    const [manualsizeQty, setManualSizeQty] = useState<string>('');
    const [itemType, setItemType] = useState<'clothing' | 'footwear'>('clothing');
    const [manualOverstock, setManualOverstock] = useState<number>();
    const [file, setFile] = useState<File | undefined>(undefined);

    const getPo = async () => {
        const poList = await getPono(token, po?.poNo);
        if (poList) {
            setPoNoList(poList?.data?.map((item: PoNoWithStatus) => {
                return {
                    label: item.poNo,    // Use `item.poNo` for the label
                    value: item.poId!.toString(),  // Convert `poId` to string for the value
                };
            }))
        }
    }

    useEffect(() => {
        getPo();
    }, [poSearch]);


    const handleStyleChange = async (value: string | null) => {
        if (value) {
            setAllocation(allocationData.find((item: any) => item.style_id === parseInt(value)))
            let image = await getImageBase64(`/styleImages/${allocationData.find((item: any) => item.style_id === parseInt(value)).image}`)
            setImageData(image as string);
        }
    }

    const handlePoNoChange = async (value: string | null) => {
        if (value) {
            setPo({ poId: value?.toString() || '', poNo: poNoList.find((item: any) => item.value === value).label });
            const allocations = await getAllocationList(token, parseInt(value));
            setAllocationData(allocations);
            if (allocations) {
                setStyleList(allocations.map((item: any) => {
                    return {
                        label: item.style_no,
                        value: item.style_id.toString()
                    }
                }))
            }
        }
    }
    const handleImageUrlChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const url = e.target.value;
        const response = await fetch(url);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onload = () => {
            setImageData(reader.result as string);
            setFile(new File([blob], 'image.jpg', { type: blob.type }));
        };
        reader.readAsDataURL(blob);
    };


    return (
        <div>
            <NavBar />
            <div className={'main'}>
                <h2 className={'title'}>Print Label</h2>
                <div style={{ width: '100%', marginBottom: '50px' }}>
                    <Grid>
                        <Grid.Col offset={isDesktop ? 2 : undefined} span={isDesktop ? 3 : isTablet ? 5 : 12}>
                            <div className={classes.gridRowDiv} >
                                {/* <Text className={classes.textWithInput}>Po No:</Text> */}
                                <Select
                                    data={poNoList}
                                    label='Po No'
                                    placeholder="Select Po No"
                                    style={{ flexGrow: 1 }}
                                    className={classes.selectDiv}
                                    searchable
                                    clearable
                                    disabled={manual}
                                    onChange={handlePoNoChange}
                                    onSearchChange={(e) => setPo({ ...po, poNo: e.toUpperCase() })}
                                    onClear={() => {
                                        setPo({ poId: '', poNo: '' })
                                        setAllocation(undefined);
                                    }}
                                />
                            </div>
                        </Grid.Col>
                        <Grid.Col span={isDesktop ? 3 : isTablet ? 5 : 12}>
                            <div className={classes.gridRowDiv} >
                                {/* <Text className={classes.textWithInput}>Style No:</Text> */}
                                <Select
                                    data={styleList}
                                    label='Style No'
                                    placeholder="Select Style No"
                                    style={{ flexGrow: 1 }}
                                    className={classes.selectDiv}
                                    searchable
                                    clearable
                                    disabled={manual}
                                    onChange={handleStyleChange}
                                    onClear={() => {
                                        setAllocation(undefined);
                                    }}
                                />
                            </div>
                        </Grid.Col>
                        <Grid.Col span={isMobile ? 12 : 2}>
                            {/* {allocation && ( */}
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                                <Switch
                                    label='Manual'
                                    checked={manual}
                                    color={theme.colors.gray[9]}
                                    style={{ marginTop: '33px' }}
                                    onChange={() => setManual(!manual)}
                                />
                            </div>
                        </Grid.Col>
                        <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '15px' }}>
                            <Input
                                type='file'
                                accept="image/*"
                                onChange={(e) => {
                                    if (e.target.files) {
                                        const file = e.target.files[0];
                                        const reader = new FileReader();
                                        reader.onload = () => {
                                            if (reader.result) {
                                                setImageData(reader.result.toString());
                                                setFile(file);
                                            }
                                        }
                                        reader.readAsDataURL(file);
                                    }
                                }}
                            />
                            <TextInput
                                placeholder="Enter image URL"
                                onChange={handleImageUrlChange}
                            />
                            <Select
                                data={['portrait', 'landscape']}
                                defaultValue={'portrait'}
                                value={orientation}
                                disabled={single}
                                onChange={(e) => setOrientation(e as 'portrait' | 'landscape')}
                                placeholder='Select orientation'
                            />
                            <Select
                                data={[{ label: 'Clothing', value: 'clothing' }, { label: 'Footwear/Accessories', value: 'footwear' }]}
                                defaultValue={'clothing'}
                                onChange={(e) => setItemType(e as 'clothing' | 'footwear')}
                                placeholder='Select orientation'
                            />
                            {/* <Button >Print Label</Button> */}
                            {/* {(allocation?.sizeQuantities && allocation?.overstockPerSize && allocation.totalAllocationPerSize) && (<PrintStoreLabels props={{ */}
                            {(<PrintStoreLabels props={{
                                allocationData: {
                                    storeName: allocation?.storeName || [],
                                    sizeQuantities: allocation?.sizeQuantities || [],
                                }, styleData: {
                                    color: allocation?.color || '',
                                    cost: allocation?.cost || 0,
                                    msrp: allocation?.msrp || 0,
                                    description: allocation?.description || '',
                                    supplier_name: allocation?.supplierName || '',
                                    style_no: allocation?.style_no || '',
                                    binlocation: allocation?.binlocation || ''
                                }, orientation, manualsizeQty, imageData,
                                storeTotals: allocation?.sizeQuantities?.map((item: any) => {
                                    return item.reduce((a: number, b: { size: string, quantity: number }) => a + b.quantity, 0);
                                }) || [],
                                overstockTotal: allocation?.overstockPerSize?.reduce((a: number, b: number) => a + b, 0) || 0,
                                styleNo: allocation?.style_no || '',
                                manualOverstock,
                                itemType,
                                totalAllocation: allocation?.totalAllocationPerSize?.reduce((a: number, b: number) => a + b, 0) || 0,
                                file
                            }} />)}
                            {!manual && (<Switch
                                label='Single'
                                checked={single}
                                color={theme.colors.gray[9]}
                                onChange={() => {
                                    setOrientation(single ? orientation : 'portrait');
                                    setSingle(!single);
                                }}
                            />)}
                            <div>
                                {!manual && allocation && allocation.sizeQuantities && allocation.overstockPerSize && allocation.totalAllocationPerSize && (<PrintStoreLabels props={{
                                    allocationData: {
                                        storeName: allocation.storeName || [],
                                        sizeQuantities: allocation.sizeQuantities,
                                    }, styleData: {
                                        color: allocation?.color || '',
                                        cost: allocation?.cost || 0,
                                        msrp: allocation?.msrp || 0,
                                        description: allocation?.description || '',
                                        supplier_name: allocation?.supplierName || '',
                                        style_no: allocation?.style_no || '',
                                        binlocation: allocation?.binlocation || ''
                                    }, orientation, manualsizeQty, imageData,
                                    storeTotals: allocation?.sizeQuantities.map((item: any) => {
                                        return item.reduce((a: number, b: { size: string, quantity: number }) => a + b.quantity, 0);
                                    }),
                                    overstockTotal: allocation?.overstockPerSize.reduce((a: number, b: number) => a + b, 0),
                                    styleNo: allocation.style_no || '',
                                    itemType,
                                    manualOverstock,
                                    single,
                                    totalAllocation: allocation.totalAllocationPerSize.reduce((a: number, b: number) => a + b, 0),
                                    file,
                                    btnLabel: 'Store Label'
                                }} />)}
                            </div>
                        </div>
                    </Grid>
                    {allocation && (<><Grid style={{ marginTop: '30px' }} >

                        <Grid.Col className={classes.storeSection} span={isMobile ? 12 : isTablet ? 3 : 3}>

                            <TextInput
                                label='Supplier Name'
                                placeholder='Po No'
                                value={allocation.supplierName || ''}
                                style={{ flexGrow: 1 }}
                                onChange={(e) => setAllocation({ ...allocation, supplier_name: e.target.value.toUpperCase() })}
                            />
                            <TextInput
                                label='Color'
                                placeholder='Color'
                                value={allocation.color || ''}
                                style={{ flexGrow: 1 }}
                                onChange={(e) => setAllocation({ ...allocation, color: e.target.value.toUpperCase() })}
                            />
                            <TextInput
                                label='Cost'
                                placeholder='cost'
                                value={allocation.cost}
                                style={{ flexGrow: 1 }}
                                onChange={(e) => {
                                    const parsedValue = parseFloat(e.target.value);
                                    setAllocation({ ...allocation, cost: isNaN(parsedValue) ? 0 : parsedValue });
                                }} />
                            {(manual && <TextInput
                                type='number'
                                className='text-input'
                                label="STEELES + OVERSTOCK"
                                value={manualOverstock}
                                ta={"center"}
                                onChange={(e) => setManualOverstock(parseInt(e.target.value))}
                            />)}
                        </Grid.Col>
                        <Grid.Col className={classes.storeSection} span={isMobile ? 12 : isTablet ? 3 : 3}>
                            <TextInput
                                label='Style No'
                                placeholder='Style No'
                                value={allocation.style_no}
                                style={{ flexGrow: 1 }}
                                onChange={(e) => setAllocation({ ...allocation, style_no: e.target.value.toUpperCase() })}
                            />
                            <TextInput
                                label='Description'
                                placeholder='description'
                                value={allocation.description}
                                style={{ flexGrow: 1 }}
                                onChange={(e) => setAllocation({ ...allocation, description: e.target.value.toUpperCase() })}
                            />
                            <TextInput
                                type='number'
                                label='MSRP'
                                placeholder='msrp'
                                value={allocation.msrp}
                                style={{ flexGrow: 1 }}
                                onChange={(e) => {
                                    const parsedValue = parseFloat(e.target.value);
                                    setAllocation({ ...allocation, msrp: isNaN(parsedValue) ? 0 : parsedValue });
                                }} />
                            <TextInput
                                label='Size/Qty'
                                placeholder='Size/Qty'
                                // value={allocation.style_no}
                                style={{ flexGrow: 1 }}
                                onChange={(e) => setManualSizeQty(e.target.value.toUpperCase())}
                            />
                        </Grid.Col>
                        <Grid.Col className={classes.storeSection} span={isMobile ? 12 : isTablet ? 6 : 6}>
                            <div style={{ height: '45vh', overflow: 'auto', scrollbarWidth: 'none' }}>
                                <Image
                                    src={imageData || `/styleImages/${allocation?.image}`}
                                    fit='contain'
                                />
                            </div>
                        </Grid.Col>
                    </Grid></>)}
                </div>
            </div>
            <div className={"footerDiv"}>
                <div></div>
                <div></div>
                {allocationData.length > 0 && (<HashPrint allocationData={allocationData} />)}
            </div>
        </div>
    )
}

export default PrintLabel;