

export const getAllocationList = async ( token: string,poId: number) => {

    try {
        const response = await fetch(`/allocation/getPoNo/${poId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            // body: JSON.stringify({ poId}),
        });
        if (response.status === 204) {
            return null;
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.log('Error fetching PONO:', error);
    }
}