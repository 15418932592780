import { Button, useMantineTheme } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound: React.FC = () => {
    const navigate = useNavigate();
    const theme = useMantineTheme();
    const handleHomePage = () => {
        navigate('/');
    }
    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>Page not found</h1>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <p style={{ textAlign: 'center' }}>The page you are looking for does not exist.</p>
                <Button style={{ backgroundColor: theme.colors.gray[9] }} onClick={handleHomePage}>Go to Home Page</Button>
            </div>
        </div>
    );
};

export default NotFound;