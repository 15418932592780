import { jwtDecode } from 'jwt-decode';
import { useSelector } from 'react-redux';


// Function to check if the token is expired
function useCheckAuthToken() {
    const token = useSelector((state: any) => state.user.sessionId);

    // Function to check if the token is expired
    const isTokenExpired = (token: string): boolean => {
        try {
            const decoded: { exp: number } = jwtDecode(token);
            const currentTime = Date.now() / 1000; // Current time in seconds
            return decoded.exp < currentTime; // Compare expiration time with current time
        } catch (error) {
            // If decoding fails, the token might be invalid
            return true;
        }
    };

    if (!token || isTokenExpired(token)) {
        return false;
    } else {
        return true;
    }
}

export default useCheckAuthToken;