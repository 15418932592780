import { Button, Grid, Group, Modal, Select, Table, Text, TextInput, useMantineTheme } from '@mantine/core';
import { IconArrowLeft, IconArrowRight, IconFilter } from '@tabler/icons-react';
import { DataGrid, highlightFilterValue, stringFilterFn } from 'mantine-data-grid';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';
import PoReport from '../../components/Reports/PoReport';
import { PoNoWithStatus } from '../../interface/AllocationInvoice';
import '../Global.css';
import classes from './PoWorksheet.module.css';

const PoWorksheet: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [rowData, setRowData] = useState<PoNoWithStatus>();
    const token = useSelector((state: any) => state.user.sessionId);
    const [tableData, setTableData] = useState<any[]>([]);
    const [filterModal, setFilterModal] = useState<boolean>(false);
    const [filter, setFilter] = useState<{ style_no: string, po_no: string, supplier_name: string, description: string }>({
        style_no: '',
        po_no: '',
        supplier_name: '',
        description: ''
    });
    const [pagination, setPagination] = useState<{ page: number, limit: number, total?: number }>({ page: 1, limit: 10, total: 1 });
    const theme = useMantineTheme();

    useEffect(() => {
        const row = location.state as PoNoWithStatus;
        if (row === undefined || row === null) {
            // navigate('/poList', { state: 'transferList' });
            // return;
        }
        setRowData(row);
        setFilter({ ...filter, po_no: filter.po_no && filter.po_no !== '' ? filter.po_no : row.poNo || '' })
        getData(filter.po_no && filter.po_no !== '' ? filter.po_no : row.poNo || '');
    }, [pagination.limit, pagination.page]);

    const getData = async (po_no?: string) => {
        try {
            const response = await fetch('/allocation/transferList', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    po_id: rowData?.poId,
                    ...filter,
                    po_no: po_no ? po_no : filter.po_no,
                    ...pagination
                })
            });
            const { data, total } = await response.json();
            if (response.status === 200) {
                await setTableData(data.map((item: any, index: number) => {
                    let totalRcvAlloc = item.receivedQty.reduce((a: number, b: { size: string, quantity: number }) => a + b.quantity, 0);
                    let disc = item.receivedQty.map((element: { size: string, quantity: number }, index: number) => {
                        return element.quantity - item.style_receivedQty[index].quantity;
                    });
                    let total_discrepancy = disc.every((value: number) => value === 0) ? null : disc.reduce((a: number, b: number) => a + b, 0);
                    return {
                        style_no: item.style_no,
                        description: item.description,
                        color: item.color,
                        cost: item.cost,
                        msrp: item.msrp,
                        supplierName: item.supplierName,
                        po_no: item.po_no,
                        total: totalRcvAlloc,
                        total_discrepancy: total_discrepancy,
                        notes: item.notes,
                        temp: item.style_receivedQty,
                        totalAllocationPerSize: item.totalAllocationPerSize,
                        overstockPerSize: item.overstockPerSize,
                        expanded: item.style_receivedQty.map((element: { size: string, quantity: number }, index: number) => {
                            return {
                                size: element.size,
                                rcvQty: element.quantity,
                                alloc: item.totalAllocationPerSize[index],
                                overstock: item.overstockPerSize[index],
                                discrepancy: disc[index]
                            }
                        })
                    }
                }));
                await setPagination({ ...pagination, total: total });
                return;
            } else {
                alert('Could not find the data. Please check the filter values again.');
                setTableData([]);
                await setPagination({ ...pagination, page: 1, total: 1 });
                return;
            }

        } catch (error) {
            console.log('Error fetcing Po workesheet data.')
        }
    }
    const handlePagination = (operation: string) => {
        if (operation === 'increase') {
            setPagination({ ...pagination, page: pagination.page === pagination.total ? pagination.page : pagination.page + 1 })
        } else {
            setPagination({ ...pagination, page: pagination.page === 1 ? pagination.page : pagination.page - 1 })
        }
    }
    // Function to render the expanded row details
    const renderExpandedRowDetails = (row: any) => {
        return (
            <Table withColumnBorders withTableBorder striped stickyHeader style={{ width: '96vw', borderCollapse: 'collapse' }}>
                <Table.Thead className={classes.tableHead}>
                    <Table.Tr>
                        <Table.Th>Size</Table.Th>
                        {row.expanded.map((item: any) => {
                            return <Table.Th>{item.size}</Table.Th>
                        })}
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    <Table.Tr>
                        <Table.Th>RCV QTY</Table.Th>
                        {row.expanded.map((item: any) => {
                            return <Table.Td>{item.rcvQty}</Table.Td>
                        })}
                    </Table.Tr>
                    <Table.Tr>
                        <Table.Th>Total Allocation</Table.Th>
                        {row.expanded.map((item: any) => {
                            return <Table.Td>{item.alloc}</Table.Td>
                        })}
                    </Table.Tr>
                    <Table.Tr>
                        <Table.Th>Overstock</Table.Th>
                        {row.expanded.map((item: any) => {
                            return <Table.Td>{item.overstock}</Table.Td>
                        })}
                    </Table.Tr>
                    <Table.Tr>
                        <Table.Th>Discrepancy</Table.Th>
                        {row.expanded.map((item: any) => {
                            return <Table.Td style={{
                                backgroundColor: item.discrepancy > 0 ? '#1B5E20' : item.discrepancy < 0 ? '#B71C1C' : undefined,
                                color: item.discrepancy !== 0 ? theme.colors.gray[0] : undefined,
                                fontWeight: item.discrepancy !== 0 ? 600 : undefined,
                            }}>{item.discrepancy}</Table.Td>
                        })}
                    </Table.Tr>
                </Table.Tbody>
            </Table>
        );
    };
    const handleApplyFilter = async () => {
        await getData(filter.po_no);
        setFilterModal(false);
        setPagination({ ...pagination, page: 1 });
    }


    return (
        <div>
            <NavBar />
            <div className={classes.main}>
                <h2>PO Worksheet</h2>
                <div style={{ width: '98vw', overflow: 'hidden', marginBottom: '50px' }}>
                    {tableData?.length > 0 && (<DataGrid
                        data={tableData}
                        striped
                        withFixedHeader
                        highlightOnHover
                        withGlobalFilter
                        withColumnBorders
                        withColumnFilters
                        withSorting
                        renderSubComponent={(row) => renderExpandedRowDetails(row.original)}
                        getRowCanExpand={() => true}
                        onRow={(row) => {
                            return row.getCanExpand()
                                ? {
                                    onClick: row.getToggleExpandedHandler(),
                                    style: { cursor: 'pointer' },
                                }
                                : {};
                        }}
                        styles={{
                            table: {
                                border: '1px solid black',
                                radius: '5px',
                            },
                            thead: {
                                border: '1px solid black',
                                backgroundColor: theme.colors.gray[9],
                                color: theme.colors.gray[0],
                            },
                            th: {
                                color: `${theme.colors.gray[0]} !important`,
                                td: { color: `${theme.colors.gray[0]}`, },
                            },
                            tr: {
                                cursor: 'pointer',
                                width: '100%',
                            },
                            // Inline styles with important }, 

                        }}
                        columns={[
                            {
                                accessorKey: 'style_no',
                                header: 'Style No',
                                filterFn: stringFilterFn,
                                // size: 200,
                                cell: highlightFilterValue,
                            },
                            {
                                accessorKey: 'description',
                                header: 'Description',
                                filterFn: stringFilterFn,
                                // size: 70,
                            },
                            {
                                accessorKey: 'color',
                                header: 'Color',
                                filterFn: stringFilterFn,
                                // size: 70,
                            },
                            {
                                accessorKey: 'cost',
                                header: 'Cost',
                                // filterFn: stringFilterFn,
                                size: 70,
                            },
                            {
                                accessorKey: 'msrp',
                                header: 'MSRP',
                                // filterFn: stringFilterFn,
                                size: 90,
                            },
                            {
                                accessorKey: 'supplierName',
                                header: 'Supplier',
                                filterFn: stringFilterFn,
                                size: 120,
                            },
                            {
                                accessorKey: 'po_no',
                                header: 'PoNo',
                                filterFn: stringFilterFn,
                                // size: 70,
                            },
                            {
                                accessorKey: 'total',
                                header: 'Total',
                                size: 70,
                            },
                            {
                                accessorKey: 'total_discrepancy',
                                header: 'Total Discrepancy',
                                filterFn: stringFilterFn,
                                cell: ({ getValue }) => {
                                    const value = getValue() as number | string;
                                    return (
                                        <div style={{
                                            color: value !== null ? theme.colors.gray[8] : undefined,
                                            width: '120px',
                                            height: '20px',
                                            textAlign: 'center',
                                            backgroundColor: value !== null ? theme.colors.gray[1] : undefined,
                                            fontWeight: value !== null ? 600 : undefined,
                                        }}>
                                            {value}
                                        </div>
                                    );
                                },
                            },
                            {
                                accessorKey: 'notes',
                                header: 'Notes',
                                filterFn: stringFilterFn,
                                // size: 70,
                            },
                        ]}
                    />)}
                </div>
            </div>
            <div className={"footerDiv"}>
                <div className={classes.paginationGroup}>
                    <Button variant='outline' className={classes.bottomBtn} onClick={() => setFilterModal(true)} ><IconFilter /> Filter</Button>
                </div>
                <Group>
                    <div className={classes.paginationGroup}>
                        <Button disabled={pagination.page === 1} className={classes.pagincationIcon} size='xs' onClick={() => handlePagination('decrease')}><IconArrowLeft /></Button>
                        <Text size='lg' fw={500}>{pagination.page}/{pagination.total || 1}</Text>
                        <Button disabled={pagination.page === pagination.total} className={classes.pagincationIcon} size='xs' onClick={() => handlePagination('increase')}><IconArrowRight /></Button>
                    </div>
                </Group>
                <div className={classes.paginationGroup}>
                    <PoReport po_no={tableData[0]?.po_no} filter={filter} token={token} />
                    <Select
                        data={['10', '20', '30']}
                        defaultValue={'10'}
                        style={{ width: '70px'}}
                        // variant='outlined'
                        onChange={(value) => setPagination({ ...pagination, limit: parseInt(value || '10') })}
                    />
                </div>
            </div>
            <Modal
                opened={filterModal}
                onClose={() => setFilterModal(false)}
                title={`Filter Options`}
                centered
                size={'lg'}
            >
                <div>
                    <Grid>
                        <Grid.Col span={6} style={{ gap: '20px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <Table withTableBorder withColumnBorders stickyHeader>
                                    <Table.Tbody>
                                        <Table.Tr>
                                            <Table.Th className={classes.tableHeadModal}>Style No</Table.Th>
                                            <Table.Td ><TextInput
                                                type='text'
                                                variant='unstyled'
                                                value={filter.style_no}
                                                className={classes.infoText}
                                                onChange={(e) => setFilter({ ...filter, style_no: e.target.value.toUpperCase() })}
                                            /></Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Th className={classes.tableHeadModal}>Po No</Table.Th>
                                            <Table.Td ><TextInput
                                                variant='unstyled'
                                                type='text'
                                                value={filter.po_no}
                                                onChange={(e) => setFilter({ ...filter, po_no: e.target.value.toUpperCase() })}
                                            /></Table.Td>
                                        </Table.Tr>
                                    </Table.Tbody>
                                </Table>
                            </div>
                        </Grid.Col>
                        <Grid.Col span={6} >
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <Table withTableBorder withColumnBorders stickyHeader>
                                    <Table.Tbody>
                                        <Table.Tr>
                                            <Table.Th className={classes.tableHeadModal}>Supplier</Table.Th>
                                            <Table.Td ><TextInput
                                                type='text'
                                                variant='unstyled'
                                                value={filter.supplier_name}
                                                className={classes.infoText}
                                                onChange={(e) => setFilter({ ...filter, supplier_name: e.target.value.toUpperCase() })}
                                            /></Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Th className={classes.tableHeadModal}>Description</Table.Th>
                                            <Table.Td ><TextInput
                                                type='text'
                                                variant='unstyled'
                                                value={filter.description}
                                                className={classes.infoText}
                                                onChange={(e) => setFilter({ ...filter, description: e.target.value.toUpperCase() })}
                                            /></Table.Td>
                                        </Table.Tr>
                                    </Table.Tbody>
                                </Table>
                            </div>
                        </Grid.Col>
                    </Grid>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <Button style={{ backgroundColor: theme.colors.gray[8], width: '20vw' }} onClick={handleApplyFilter}>Apply</Button>
                </div>
            </Modal>
        </div>
    )
}

export default PoWorksheet;