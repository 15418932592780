import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import RegistrationForm from "../../components/RegistrationForm/RegistrationForm";
import '../Global.css';

const Register: React.FC = () => {
  
    return (
        <div>
            <NavBar />
            <RegistrationForm/>
        </div>
    );
}

export default Register;