import { Box, Button, Grid, Image, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import userImage from '../assets/Avatar.svg';
import NavBar from '../components/NavBar/NavBar';

const ProfilePage: React.FC = () => {
    const theme = useMantineTheme();
    const user = useSelector((state: any) => state.user.user);
    const [profile, setProfile] = useState({ ...user, password: '' });
    const isMobile = useMediaQuery('(max-width: 550px)');
    //check if the browser is in dark mode or light mode
    const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    return (
        <div>
            <NavBar />
            <div style={{ marginTop: '60px' }}></div>
            <h1 style={{ marginTop: '20px', textAlign: 'center' }}>Profile Page</h1>
            <Box style={{ border: `2px solid ${theme.colors.gray[4]}`, borderStyle: 'double', padding: '20px', margin: isMobile ? '10px' : '140px', borderRadius: '30px' }}>
                <Grid columns={12}>
                    <Grid.Col span={{ sm: 12, md: 4 }} >
                        <Image src={userImage} alt="User Profile" width={200} height={350} fit="contain" />
                    </Grid.Col>
                    <Grid.Col span={{ sm: 12, md: 8 }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '30px', backgroundColor: theme.colors.gray[0], borderRadius: '20px', padding: '20px' }}>
                        <Grid columns={12} style={{ width: '100%' }}>
                            <Grid.Col span={{ sm: 12, md: 6 }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '20px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '10px', width: '100%' }}>
                                    <Text style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 17 }}>First Name</Text>
                                    <TextInput
                                        style={{ flexGrow: 1 }}
                                        value={profile.first_name}
                                        size='md'
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '10px', width: '100%' }}>
                                    <Text style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 17 }}>Last Name</Text>
                                    <TextInput
                                        style={{ flexGrow: 1 }}
                                        value={profile.last_name}
                                        size='md'
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '10px', width: '100%' }}>
                                    <Text style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 17 }}>Email</Text>
                                    <TextInput
                                        style={{ flexGrow: 1 }}
                                        value={profile.email}
                                        size='md'
                                    />
                                </div>
                            </Grid.Col>
                            <Grid.Col span={{ sm: 12, md: 6 }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '20px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '10px', width: '100%' }}>
                                    <Text style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 17 }}>Role</Text>
                                    <TextInput
                                        style={{ flexGrow: 1 }}
                                        value={profile.scope}
                                        size='md'
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '10px', width: '100%' }}>
                                    <Text style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 17 }}>Location</Text>
                                    <TextInput
                                        style={{ flexGrow: 1 }}
                                        value={profile.location}
                                        size='md'
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '10px', width: '100%' }}>
                                    <Text style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 17 }}>Password</Text>
                                    <TextInput
                                        style={{ flexGrow: 1 }}
                                        value={profile.password}
                                        size='md'
                                    />
                                </div>
                            </Grid.Col>
                        </Grid>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '10px', width: '100%' }}>
                            <Button 
                            style={{ backgroundColor: theme.colors.gray[8], color: theme.colors.gray[0] }}
                            onClick={()=> alert('Profile updated successfully.')}
                            >Update</Button>
                        </div>
                    </Grid.Col>
                </Grid>
            </Box>
        </div>
    );
};

export default ProfilePage;