import { jwtDecode } from 'jwt-decode';
import { IUserSession } from '../interface/User';

export function getUserInfo(token:string){
    try{
        const decoded: IUserSession = jwtDecode(token);
        if(decoded){
            return {
                email:decoded.email,
                first_name:decoded.first_name,
                last_name:decoded.last_name,
                location:decoded.location,
                scope:decoded.scope
            }
        }
        return null;
    } catch (error){
        console.log('Error extracting user data from token.');
    }
}