import { Button } from '@mantine/core';
import axios from 'axios';
import JsBarcode from 'jsbarcode';
import pdfMake from "pdfmake/build/pdfmake";
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface SizeQuantity {
    size: string;
    quantity: number;
}
interface AllocationData {
    storeName: string[];
    sizeQuantities: SizeQuantity[][];
}
interface StyleData {
    style_no: string;
    color: string;
    cost: number;
    msrp: number;
    description: string;
    binlocation: string;
    supplier_name: string;


}
interface PrintStoreLabelsProps {
    props: {
        allocationData: AllocationData;
        styleData: StyleData;
        orientation: 'portrait' | 'landscape';
        manualsizeQty?: string;
        imageData: string;
        storeTotals: number[];
        overstockTotal: number;
        styleNo: string;
        itemType: 'clothing' | 'footwear';
        btnLabel?: string;
        manualOverstock?: number;
        // manual?: boolean;
        single?: boolean;
        totalAllocation: number;
        file?: File;
    };
}
const PrintStoreLabels: React.FC<PrintStoreLabelsProps> = ({ props }) => {
    const { allocationData, styleData, orientation, manualsizeQty, imageData, storeTotals, overstockTotal, styleNo, itemType, btnLabel, totalAllocation, single, manualOverstock, file } = props;
    const [buttonLabel, setButtonLabel] = useState<string>('Print Labels');
    const token = useSelector((state: any) => state.user.sessionId);
    useEffect(() => {
        if (btnLabel) {
            setButtonLabel(btnLabel);
        }
    }, [btnLabel]);
    const generateCode = (): string => {
        // Assuming allocationData.storeName and storeTotals are available in the context
        let targetStoreIndex = allocationData.storeName.findIndex((store: string) => store.toUpperCase() === 'STEELES');
        let code = 0;
        if (targetStoreIndex === -1 || storeTotals[targetStoreIndex] === 0) { // If "Steeles" not found
            code = overstockTotal + totalAllocation;
        } else {
            let storeTotal = 0;
            if (targetStoreIndex !== -1) {

                // Use the total of the found store
                storeTotal = storeTotals[targetStoreIndex];
            } else if (allocationData.storeName.length === 1) {
                // If there is only one store, use its total regardless of the name
                storeTotal = storeTotals[0];
            } else {
                // If all stores have zero quantity, use overstock
                const allStoresZero = storeTotals.every(total => total === 0);
                if (allStoresZero) {
                    storeTotal = overstockTotal;
                } else {
                    // Find the first non-zero total if exists
                    storeTotal = storeTotals.find(total => total > 0) || 0;
                }
            }
            code = overstockTotal + storeTotal;
        }

        // Proceed with existing logic to calculate the code
        const date = new Date();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        let cost: string = '';
        const costValue = parseFloat(styleData.cost.toString());
        if (isNaN(costValue)) {
            return 'Cost is not a number';
        } else {
            if (costValue < 1) {
                // For costs less than $1
                cost = Math.round(costValue * 100).toString().padStart(4, '0');
            } else if (!Number.isInteger(costValue)) {
                // For decimal costs
                const cent = Math.round(costValue * 100);
                cost = cent.toString().padStart(4, '0');
            } else {
                // For integer costs
                cost = ((costValue * 100) / 100).toString().padStart(4, '0');
            }
        }

        const formattedCode = `${code.toString().padStart(3, '0')} - ${month.toString().padStart(2, '0')}${year.toString().slice(-2)}${cost.padStart(4, '0')}`;
        return formattedCode;
    };

    const generateBarcode = (data: string) => {
        const canvas = document.createElement('canvas'); // Create a canvas element
        JsBarcode(canvas, data); // Generate the barcode
        return canvas.toDataURL(); // Convert the canvas to a data URL
    };
    const generatePDF = async () => {

        const portraitMode = orientation === 'portrait';

        let contentData: Content[] = [];
        let contentData1: Content[] = [];
        let isDescriptionLong = styleData.description.length > 30 ? true : false;
        try {
            contentData = [
                {
                    columns: [
                        {
                            text: `${styleData.supplier_name}`,
                            fontSize: portraitMode ? 14 : 20,
                            alignment: 'left',
                            margin: [-15, -30, 0, 10]
                        },
                        {
                            text: `${manualsizeQty || `` || ``}`,
                            fontSize: portraitMode ? 14 : 20,
                            alignment: 'right',
                            margin: [-15, -30, 0, 10]
                        }
                    ]
                },
                {
                    columns: [
                        {

                            text:
                                `${styleData.description.slice(0, 25)}`,
                            fontSize: portraitMode ? 22 : 30,
                            alignment: 'center',
                            margin: [1, 3, 3, 3]

                        },
                    ],
                },
                (isDescriptionLong ? [{

                    text:
                        `${styleData.description.slice(25)}`,
                    fontSize: portraitMode ? 22 : 30,
                    alignment: 'center',
                    margin: [1, -10, 3, 0]

                }] : []),
                {
                    columns: [
                        {
                            text:
                                `#${styleData.style_no}`,
                            fontSize: portraitMode ? 35 : 65,
                            alignment: 'center',
                            bold: true,
                            margin: [1, 3, 3, 3],
                        },
                    ],
                },
                {
                    columns: [
                        {
                            text: `${styleData.color}`,
                            width: '*',
                            fontSize: portraitMode ? 16 : 20,
                            alignment: 'center',
                            margin: [3, 3, 3, 3]
                        },
                        {
                            text: `R - ${(styleData.msrp as number)}`,
                            width: '*',
                            fontSize: portraitMode ? 16 : 20,
                            alignment: 'center',
                            margin: [3, 3, 3, 3]
                        },
                    ],
                },
                {
                    columns: [
                        {
                            text: manualOverstock ? `${manualOverstock} - ${generateCode().toString().split('-')[1]}` : `${generateCode()}`,
                            fontSize: portraitMode ? 16 : 20,
                            alignment: 'center',
                            margin: [0, 3, 3, 3]

                        },
                    ],
                }, // Add quantity data
                {


                    image: imageData,
                    width: portraitMode ? 300 : 480,
                    height: portraitMode ? 150 : 280,
                    margin: [0, 15, 0, -20],
                    alignment: 'center'


                },
                {
                    image: generateBarcode(styleNo),
                    width: portraitMode ? 100 : 150,
                    height: portraitMode ? 30 : 45,
                    margin: portraitMode ? [0, -70, -15, 0] : [0, -70, -20, 0],
                    alignment: 'right'
                }
                // Add overstock data
            ];

            contentData1 = [
                {
                    columns: [
                        {
                            text: `${styleData.supplier_name}`,
                            fontSize: portraitMode ? 14 : 20,
                            alignment: 'left',
                            margin: [-15, 50, 0, 10],
                        },
                        {
                            text: `${manualsizeQty || ``}`,
                            fontSize: portraitMode ? 14 : 20,
                            alignment: 'right',
                            margin: [-15, 50, 0, 10]
                        }
                    ]
                },
                {
                    columns: [
                        {

                            text:
                                `${styleData.description.slice(0, 25)}`,
                            fontSize: portraitMode ? 22 : 30,
                            alignment: 'center',
                            margin: [1, 3, 3, 3]

                        },
                    ],
                },
                (isDescriptionLong ? [{

                    text:
                        `${styleData.description.slice(25)}`,
                    fontSize: portraitMode ? 22 : 30,
                    alignment: 'center',
                    margin: [1, -10, 3, 0]

                }] : []),
                {
                    columns: [
                        {
                            text:
                                `#${styleData.style_no}`,
                            fontSize: portraitMode ? 35 : 65,
                            alignment: 'center',
                            bold: true,
                            margin: [3, 3, 3, 3]

                        },
                    ],
                },
                {
                    columns: [
                        {
                            text: `${styleData.color}`,
                            width: '*',
                            fontSize: portraitMode ? 16 : 20,
                            alignment: 'center',
                            margin: [3, 3, 3, 3]
                        },
                        {
                            text: `R - ${(styleData.msrp as number)}`,
                            width: '*',
                            fontSize: portraitMode ? 16 : 20,
                            alignment: 'center',
                            margin: [3, 3, 3, 3]
                        }
                    ],
                },
                {
                    columns: [
                        {
                            text:
                                `${generateCode()}`,
                            fontSize: portraitMode ? 16 : 20,
                            alignment: 'center',
                            margin: [3, 3, 3, 3]

                        },
                    ],
                }, // Add quantity data

                {

                    image: imageData,
                    width: portraitMode ? 300 : 450,
                    height: portraitMode ? 140 : 300,
                    margin: [0, 25, 0, -20],
                    alignment: 'center'

                },
                {
                    image: generateBarcode(styleNo),
                    width: portraitMode ? 100 : 170,
                    height: portraitMode ? 30 : 50,
                    margin: portraitMode ? [0, -70, -15, 0] : [0, -35, -15, 0],
                    alignment: 'right',
                }
                // Add overstock data
            ];

            let content: Content[];

            if (portraitMode) {
                // Add a page break and duplicate the content
                contentData.push({ text: '', marginTop: 40 });
                content = [...contentData, ...contentData1];
            } else {
                content = contentData;
            }

            const docDefinition: TDocumentDefinitions = {
                content: content,
                pageSize: 'LETTER',
                pageOrientation: orientation,
            };

            pdfMake.createPdf(docDefinition).open();
        } catch (error) {
            console.log('Something went wrong with the pdf try new image or check the data.', error);
        }
    };

    const storeLabelPDF = async () => {
        const portraitMode = orientation === 'portrait';
        const iType = itemType === 'clothing'
        let contentData: Content[] = [];
        let content: Content[];
        let currentContentData: Content[] = [];
        let currentContentData1: Content[] = [];
        let lastPage = (portraitMode && single) ? Math.ceil(allocationData.storeName.length / 2) : allocationData.storeName.length;
        let isDescriptionLong = styleData.description.length > 25 ? true : false;
        try {


            allocationData.storeName.forEach((store: string, index: number) => {
                // if (storeTotals[index] !== 0) {
                let sizeQuantities = allocationData.sizeQuantities[index].filter((item: any) => item.quantity > 0);
                currentContentData = [
                    {
                        columns: [
                            {
                                text: `${styleData.supplier_name}`,
                                fontSize: portraitMode ? 14 : 20,
                                alignment: 'left',
                                margin: portraitMode ? [-15, -32, 0, 10] : [-15, -32, 0, 10]
                            },
                            {
                                text: `${store}`,
                                fontSize: portraitMode ? 18 : 30,
                                alignment: 'center',
                                bold: true,
                                color: 'red',
                                margin: portraitMode ? [0, -32, 0, 10] : [0, -32, 0, 10]
                            },
                            {
                                text: `${manualsizeQty || ``}`,
                                fontSize: portraitMode ? 14 : 20,
                                alignment: 'right',
                                margin: portraitMode ? [0, -32, 0, 10] : [0, -32, 0, 10]
                            }
                        ],
                        pageBreak: index === 0 ? undefined : single ? undefined : portraitMode ? isDescriptionLong ? undefined : 'before' : isDescriptionLong ? undefined : 'before'
                    },
                    {
                        columns: [
                            {

                                text:
                                    `${styleData.description.slice(0, 25)}`,
                                fontSize: portraitMode ? 22 : 30,
                                alignment: 'center',
                                margin: [1, 3, 3, 3]

                            },
                        ],
                    },
                    (isDescriptionLong ? [{

                        text:
                            `${styleData.description.slice(25)}`,
                        fontSize: portraitMode ? 22 : 30,
                        alignment: 'center',
                        margin: [1, -10, 3, 0]

                    }] : []),
                    {
                        columns: [
                            {
                                text:
                                    `#${styleData.style_no}`,
                                fontSize: portraitMode ? 30 : 65,
                                alignment: 'center',
                                bold: true,
                                margin: portraitMode ? [1, -5, 3, 3] : isDescriptionLong ? [1, -10, 0, 0] : [1, -5, 0, 0],

                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                text: `${styleData.color}`,
                                width: '*',
                                fontSize: portraitMode ? 16 : 20,
                                alignment: 'center',
                                margin: portraitMode ? [3, 0, 3, 3] : isDescriptionLong ? [3, 0, 3, 0] : [3, 0, 3, 3]
                            },
                            {
                                text: `R - ${(styleData.msrp as number)}`,
                                width: '*',
                                fontSize: portraitMode ? 16 : 20,
                                alignment: 'center',
                                margin: portraitMode ? [3, 0, 3, 3] : isDescriptionLong ? [3, 0, 3, 0] : [3, 0, 3, 3]
                            },
                        ],
                    },
                    {
                        columns: [
                            {

                                table: {

                                    widths: ['auto', 'auto'], // Set the width of each column
                                    body: [
                                        [
                                            {
                                                border: overstockTotal < 1 && (store === 'STEELES' || store === 'WEB') ? [true, true, true, true] : [false, false, false, false],
                                                text: generateCode().toString().split('-')[0],
                                                bold: true,
                                                fontSize: portraitMode ? 16 : 20,
                                                margin: portraitMode ? isDescriptionLong ? [0, -10, 0, 0] : undefined : undefined,
                                            },
                                            {
                                                text: `- ${generateCode().toString().split('-')[1]}`,
                                                fontSize: portraitMode ? 16 : 20,
                                                margin: portraitMode ? isDescriptionLong ? [0, -10, 0, 0] : undefined : undefined,
                                            }

                                        ]
                                    ]
                                },
                                layout: {
                                    defaultBorder: false,
                                },
                                alignment: 'center',
                                margin: [220, 0, 3, 3],
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                columns: [{
                                    table: {
                                        heights: portraitMode ? 3 : 15,
                                        body: [
                                            [{ text: 'QTY', bold: true }, { text: 'SIZE', bold: true }],
                                            ...sizeQuantities.slice(0, 9).map((item: any) => {
                                                return [item.quantity, { text: item.size, bold: true }];
                                            })
                                        ]
                                    },
                                },
                                ...(sizeQuantities.length > 9 ? [{
                                    table: {
                                        heights: portraitMode ? 3 : 15,
                                        body: [
                                            [{ text: 'QTY', bold: true }, { text: 'SIZE', bold: true }],
                                            ...sizeQuantities.slice(9).map((item: any) => {
                                                return [item.quantity, { text: item.size, bold: true }];
                                            })
                                        ]
                                    },
                                },] : []),
                                ]
                            },
                            {
                                image: imageData,
                                width: portraitMode ? 250 : 430,
                                height: portraitMode ? 190 : 280,
                                margin: portraitMode ? [0, -5, 0, 50] : [-15, -5, 0, 50],
                                alignment: 'center'
                            },
                            [
                                store === 'STEELES' ? {
                                    text: 'SAMPLE \n PULLED',
                                    fontSize: portraitMode ? 20 : 30,
                                    alignment: 'center',
                                    bold: true,
                                    color: 'red',
                                    margin: portraitMode ? [-10, 0, -15, 0] : [0, 0, -20, 0],
                                } : '',
                                {
                                    image: generateBarcode(styleNo),
                                    width: portraitMode ? 80 : 150,
                                    height: portraitMode ? 25 : 45,
                                    margin: portraitMode ? [-10, 100, -15, 0] : [0, 150, -20, 0],
                                    alignment: 'center'
                                }
                            ]
                        ],
                        pageBreak: portraitMode ? single ? (((index + 1) % 2 === 0) && index !== allocationData.storeName.length - 1 ? 'after' : undefined) : undefined
                            : (lastPage - 1 === index) ? undefined : isDescriptionLong ? 'after' : undefined
                    },
                ];
                currentContentData1 = [
                    {
                        columns: [
                            {
                                text: `${styleData.supplier_name}`,
                                fontSize: portraitMode ? 14 : 20,
                                alignment: 'left',
                                margin: portraitMode ? [-15, -32, 0, 10] : [-15, -32, 0, 10]
                            },
                            {
                                text: `${store}`,
                                fontSize: portraitMode ? 18 : 30,
                                alignment: 'center',
                                bold: true,
                                color: 'red',
                                margin: portraitMode ? [0, -32, 0, 10] : [0, -32, 0, 10]
                            },
                            {
                                text: `${manualsizeQty || ``}`,
                                fontSize: portraitMode ? 14 : 20,
                                alignment: 'right',
                                margin: portraitMode ? [0, -32, 0, 10] : [0, -32, 0, 10]
                            }
                        ],
                    },
                    {
                        columns: [
                            {

                                text:
                                    `${styleData.description.slice(0, 25)}`,
                                fontSize: portraitMode ? 22 : 30,
                                alignment: 'center',
                                margin: [1, 3, 3, 3]

                            },
                        ],
                    },
                    (isDescriptionLong ? [{

                        text:
                            `${styleData.description.slice(25)}`,
                        fontSize: portraitMode ? 22 : 30,
                        alignment: 'center',
                        margin: [1, -10, 3, 0]

                    }] : []),
                    {
                        columns: [
                            {
                                text:
                                    `#${styleData.style_no}`,
                                fontSize: portraitMode ? 30 : 65,
                                alignment: 'center',
                                bold: true,
                                margin: portraitMode ? [1, -5, 3, 3] : isDescriptionLong ? [1, -10, 0, 0] : [1, -5, 0, 0],

                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                text: `${styleData.color}`,
                                width: '*',
                                fontSize: portraitMode ? 16 : 20,
                                alignment: 'center',
                                margin: portraitMode ? [3, 0, 3, 3] : isDescriptionLong ? [3, 0, 3, 0] : [3, 0, 3, 3]
                            },
                            {
                                text: `R - ${(styleData.msrp as number)}`,
                                width: '*',
                                fontSize: portraitMode ? 16 : 20,
                                alignment: 'center',
                                margin: portraitMode ? [3, 0, 3, 3] : isDescriptionLong ? [3, 0, 3, 0] : [3, 0, 3, 3]
                            },
                        ],
                    },
                    {

                        columns: [
                            {

                                table: {
                                    widths: ['auto', 'auto'], // Set the width of each column
                                    body: [
                                        [
                                            {
                                                border: overstockTotal < 1 && (store === 'STEELES' || store === 'WEB') ? [true, true, true, true] : [false, false, false, false], text: generateCode().toString().split('-')[0],
                                                bold: true,
                                                fontSize: portraitMode ? 16 : 20,
                                                margin: portraitMode ? isDescriptionLong ? [0, -10, 0, 0] : undefined : undefined,
                                            },
                                            {
                                                text: `- ${generateCode().toString().split('-')[1]}`,
                                                fontSize: portraitMode ? 16 : 20,
                                                margin: portraitMode ? isDescriptionLong ? [0, -10, 0, 0] : undefined : undefined,
                                            }

                                        ]
                                    ]
                                },
                                layout: {
                                    defaultBorder: false,
                                },
                                alignment: 'center',
                                margin: [220, 0, 3, 3],
                            }
                        ]
                    }, // Add quantity data //////////////////////////////
                    {
                        columns: [
                            {
                                columns: [{
                                    table: {
                                        heights: portraitMode ? 3 : 15,
                                        body: [
                                            [{ text: 'QTY', bold: true }, { text: 'SIZE', bold: true }],
                                            ...sizeQuantities.slice(0, 9).map((item: any) => {
                                                return [item.quantity, { text: item.size, bold: true }];
                                            })
                                        ]
                                    },
                                },
                                ...(sizeQuantities.filter((item: any) => item.quantity > 0).length > 9 ? [{
                                    table: {
                                        heights: portraitMode ? 3 : 15,
                                        body: [
                                            [{ text: 'QTY', bold: true }, { text: 'SIZE', bold: true }],
                                            ...sizeQuantities.slice(9).map((item: any) => {
                                                return [item.quantity, { text: item.size, bold: true }];
                                            })
                                        ]
                                    },
                                },] : []),
                                ]
                            },
                            {
                                image: imageData,
                                width: portraitMode ? 250 : 450,
                                height: portraitMode ? 190 : 300,
                                margin: portraitMode ? isDescriptionLong ? [0, -5, 0, 50] : [0, -5, 0, 50] : [-15, -5, 0, 50],
                                alignment: 'center'
                            },
                            [
                                store === 'STEELES' ? {
                                    text: 'SAMPLE \n PULLED',
                                    fontSize: portraitMode ? 20 : 30,
                                    alignment: 'center',
                                    bold: true,
                                    color: 'red',
                                    margin: portraitMode ? [-10, 0, -15, 0] : [0, 0, -20, 0],
                                } : '',
                                {
                                    image: generateBarcode(styleNo),
                                    width: portraitMode ? 80 : 150,
                                    height: portraitMode ? 25 : 45,
                                    margin: portraitMode ? [-10, 100, -15, 0] : [0, 150, -20, 0],
                                    alignment: 'center'
                                }
                            ]
                        ],
                        pageBreak: isDescriptionLong ? (index === lastPage - 1) ? undefined : 'after' : undefined,
                    },


                    // Add overstock data
                ];
                if (orientation === 'portrait') {
                    if (single) {
                        contentData = [...contentData, ...currentContentData];
                        if ((index + 1) % 2 === 0 && index !== allocationData.storeName.length - 1) {
                            // contentData.push({ text: '', pageBreak: 'after' });
                        }

                    } else {
                        contentData = [...contentData, ...currentContentData, ...currentContentData1];
                    }
                } else {
                    contentData = [...contentData, ...currentContentData];
                }
            })
            content = contentData;

            const storeLabelDocDefinition: TDocumentDefinitions = {
                content: content,
                pageSize: 'LETTER',
                pageOrientation: orientation,
            }
            pdfMake.createPdf(storeLabelDocDefinition).open();
        } catch (error) {
            console.log('Something went wrong with the pdf try new image or check the data.', error);
        }
    }

    const handleUpload = async (style_no: string, file?: File) => {
        if (!file) {
            alert('Please select a file');
            return;
        }
        const formData = new FormData();
        formData.append('files', file);
        formData.append('style_no', style_no);

        try {
            const response = await axios.post('/style/uploadImage', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                },
            });
            const data = await response.data;
            if (response.status !== 200) {
                alert('Error uploading file');
                return;
            }
            alert('Item created successfully.');
        } catch (error) {
            console.log(error);
            alert('Something went wrong while creating item. Please try again.');
        }
    }
    const handlePDFDownload = () => {
        if (!imageData || imageData === '') {
            alert('Please upload image');
            return;
        } else {
            handleUpload(styleNo, file)
        }
        if (buttonLabel === 'Store Label') {
            storeLabelPDF();
            return;
        } else {
            generatePDF();
            return;
        }
        if (itemType === 'clothing') {
            storeLabelPDF();
        }


    };
    return (
        <div>
            <Button onClick={(e) => { e.preventDefault(); handlePDFDownload() }}>{buttonLabel}</Button>
            {/* <Button onClick={downloadPDF}>Print Portrait</Button> */}
        </div>
    )
};

export default PrintStoreLabels;