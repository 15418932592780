import { Button, Group, Select, Text, useMantineTheme } from '@mantine/core';
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import { DataGrid, DataGridFiltersState, DataGridSortingState, highlightFilterValue, stringFilterFn } from 'mantine-data-grid';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPono } from '../../api/poList';
import { useDebounce } from '../../Hooks/useDebounce';
import { PoNoWithStatus } from '../../interface/AllocationInvoice';
import '../../pages/Global.css';
import classes from './PurchaseOrders.module.css';



const PurchaseOrders: React.FC = () => {
    const location = useLocation();
    const receivedData = location?.state?.status === 'transferList' ? 'Transfer List' : undefined;
    const token = useSelector((state: any) => state.user.sessionId);
    const [po, setPo] = useState<{ poId: number, poNo: string }>({ poId: 0, poNo: '' });
    const [poNoList, setPoNoList] = useState<any[]>([]);
    const poSearch = useDebounce(po.poNo, 700);
    const [pagination, setPagination] = useState<{ page: number, limit: number, totalPages?: number }>({ page: 1, limit: 10, totalPages: 1 });
    const [sorting, setSorting] = useState<{ column: string, desc: boolean }>()
    const theme = useMantineTheme();
    const navigate = useNavigate();



    const getPo = async () => {
        const poList = await getPono(token, po?.poNo, pagination);
        if (poList) {
            setPoNoList(poList.data);
            setPagination({ ...pagination, totalPages: poList.total })
        }
    }

    const handleSortingChange = async (sortingState: DataGridSortingState) => {
        if (sortingState[0]) {
            const { id, desc } = sortingState[0];
            setSorting({ column: id, desc: desc });
        }
    }
    const handleFilterChange = async (filterState: DataGridFiltersState) => {
    }
    const handleRowClick = (row: PoNoWithStatus) => {
        if (row && location?.state?.status) {
            navigate(`/${location.state.status}`, { state: row })
        }
    }
    const handlePagination = (operation: string) => {
        if (operation === 'increase') {
            setPagination({ ...pagination, page: pagination.page === pagination.totalPages ? pagination.page : pagination.page + 1 })
        } else {
            setPagination({ ...pagination, page: pagination.page === 1 ? pagination.page : pagination.page - 1 })
        }
    }

    useEffect(() => {
        getPo();
    }, [poSearch, pagination.limit, pagination.page, sorting?.column, sorting?.desc]);
    // const handlePagination = (paginationState: { pageIndex: number, pageSize: number }) => {
    //     setPagination({ page: paginationState.pageIndex, limit: paginationState.pageSize });
    // }
    return (
        <div>
            <div className={classes.main}>
                <h2 className={"title"}>{receivedData || 'PO List'}</h2>
                <div style={{ width: '100%', marginBottom: '50px' }}>
                    {poNoList && (<DataGrid
                        data={poNoList}
                        striped
                        withFixedHeader
                        highlightOnHover
                        withGlobalFilter
                        onSearch={(value) => {
                            setPo({ ...po, poNo: value.toUpperCase() })
                            setPagination({ ...pagination, page: 1 })
                        }}
                        onSort={handleSortingChange}
                        onFilter={handleFilterChange}
                        withColumnBorders
                        withColumnFilters
                        verticalSpacing={14}
                        withSorting
                        // onClick={(row) => ({
                        //     onClick: () => handleRowClick(row),
                        // })}
                        onRow={(row) => ({
                            onClick: () => handleRowClick(row.original),
                        })}
                        styles={{
                            table: {
                                border: '1px solid black',
                                radius: '5px',
                            },
                            thead: {
                                border: '1px solid black',
                                backgroundColor: theme.colors.gray[9],
                                color: theme.colors.gray[0],
                            },
                            th: {
                                color: `${theme.colors.gray[0]} !important`,
                                td: { color: `${theme.colors.gray[0]} !important`, },
                            },
                            tr: {
                                cursor: 'pointer'
                            }
                            // Inline styles with important }, 

                        }}
                        columns={[
                            {
                                accessorKey: 'poNo',
                                header: 'Po No',
                                filterFn: stringFilterFn,
                                size: 200,
                                cell: highlightFilterValue,
                            },
                            {
                                accessorKey: 'status',
                                header: 'Status',
                                filterFn: stringFilterFn,
                                size: 70,
                            },
                            {
                                accessorKey: 'maxDate',
                                header: 'Last Updated Date',
                                filterFn: stringFilterFn,
                                size: 70,
                            }
                        ]}
                    />)}
                </div>
            </div>
            <div className={'footerDiv'}>
                <div></div>
                <Group>
                    <div className={classes.paginationGroup}>
                        <Button disabled={pagination.page === 1} className={classes.pagincationIcon} size='xs' onClick={() => handlePagination('decrease')}><IconArrowLeft /></Button>
                        <Text size='lg' fw={500}>{pagination.page}/{pagination.totalPages || 1}</Text>
                        <Button disabled={pagination.page === pagination.totalPages} className={classes.pagincationIcon} size='xs' onClick={() => handlePagination('increase')}><IconArrowRight /></Button>
                    </div>
                </Group>
                <div>
                    <Select
                        data={['10', '20', '30']}
                        defaultValue={'10'}
                        style={{ width: '70px', border: `1px solid ${theme.colors.gray[9]}`, borderRadius: '10px' }}
                        variant='outlined'
                        onChange={(value) => setPagination({ ...pagination, limit: parseInt(value || '10') })}
                    />
                </div>
            </div>
        </div>
    )
}

export default PurchaseOrders;