import JsBarcode from "jsbarcode";
import pdfMake from 'pdfmake/build/pdfmake';
import { Content, TDocumentDefinitions } from "pdfmake/interfaces";
import QRCode from "qrcode";


const convertImageToBase64 = async (url: string): Promise<string> => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

const generateBarcode = (data: string) => {
    const canvas = document.createElement('canvas'); // Create a canvas element
    JsBarcode(canvas, data, {
        format: 'CODE128',
        displayValue: false,
        width: 30,
        lineColor: 'black',
        background: 'red'
    }); // Generate the barcode
    return canvas.toDataURL(); // Convert the canvas to a data URL
};


export const createAllQrPdf = async (qrData: { binlocation: string }[]) => {

    const imageDataUrl = await convertImageToBase64('./pdfLogo.png')
    const pdfContent: any[] = await Promise.all(qrData.map(async ({ binlocation }, index: number) => {
        return [
            {
                columns: [
                    {
                        image: imageDataUrl,
                        // width: 150,
                        alignment: 'left',
                        margin: [0, 0, 0, 0],
                        fit: [80, 80], // Add this property to specify the image size
                        style: 'qrCode'
                    },
                    {
                        text: binlocation,
                        style: 'body',
                        fontSize: 50,
                        alignment: 'left',
                        margin: [-25, 0, 0, 0]
                    },
                ]
            },
            {
                image: generateBarcode(binlocation),
                width: 150,
                margin: [0, 0, 0, 20],
                fit: [450, 450], // Add this property to specify the image size
                style: 'qrCode'
            },
            {
                text: '',
                pageBreak: true
            }
        ];
    }));

    // Flatten the array of arrays into a single array
    const flattenedContent = pdfContent.flat();

    const docDefinition: TDocumentDefinitions = {
        content: flattenedContent,
        pageOrientation: 'landscape',
        styles: {
            header: {
                fontSize: 30,
                alignment: 'center',
                bold: true,
                margin: [0, 50, 0, 20]
            },
            qrCode: {
                alignment: 'center'
            },
            body: {
                fontSize: 50,
                alignment: 'center'
            }
        }
    };
    pdfMake.createPdf(docDefinition).open();
}
export const createBarcodePdf = async (barcode: string) => {
    const imagePath = './pdfLogoWhite.png';
    const imageDataUrl = await convertImageToBase64(imagePath);

    try {
        const docDefinition: TDocumentDefinitions = {
            background: function(currentPage, pageSize) {
                return {
                  canvas: [
                    {
                      type: 'rect',
                      x: 0,
                      y: 0,
                      w: pageSize.width,
                      h: pageSize.height,
                      color: 'red',
                    },
                  ],
                };
              },
            content: [
                {
                    columns: [
                        {
                            image: imageDataUrl,
                            alignment: 'left',
                            margin: [0, -16, 0, 0],
                            fit: [80, 80], // Add this property to specify the image size
                            style: 'redBackground'
                        },
                    ]
                },
                {
                    text: barcode,
                    style: 'body',
                    fontSize: 460,
                    color: 'white',
                    alignment: 'center',
                    margin: [0, -60, 0, 0],
                },
                {
                    image: generateBarcode(barcode),
                    width: 420,
                    height: 60,
                    margin: [0, -80, 0, 20],
                    alignment: 'center',
                    // fit: [400, 50], // Add this property to specify the image size
                }
            ],
            styles: {
                header: {
                    fontSize: 30,
                    alignment: 'center',
                    bold: true,
                    margin: [0, 50, 0, 20],
                    background: 'red',
                    fillColor: 'red'
                },
                qrCode: {
                    alignment: 'center',
                    background: 'red',
                    fillColor: 'red'
                },
                body: {
                    // fontSize: 50,
                    alignment: 'center',
                    background: 'red',
                    fillColor: 'red',
                    bold: true
                },
                redBackground: {
                    fillColor: 'red',
                },
            },
            pageOrientation: 'landscape',
        };
        pdfMake.createPdf(docDefinition).open();
    } catch (error) {
        console.log('Error Creating PDF:', error);
    }
}