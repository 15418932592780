import { Button, Checkbox, FileInput, Grid, Image, Input, Modal, Select, Table, Text, TextInput, useMantineTheme } from '@mantine/core';
import { IconDeviceFloppy, IconPrinter, IconReceipt } from '@tabler/icons-react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPono } from '../../api/poList';
import { saveFloorData } from '../../api/saveFloorData';
import { getAllocationList } from '../../api/styleList';
import NavBar from '../../components/NavBar/NavBar';
import PoTable from '../../components/PoTable/PoTable';
import { useDebounce } from '../../Hooks/useDebounce';
import { PoNoWithStatus } from '../../interface/AllocationInvoice';
import { allocationPaperPDF } from '../../PDFs/allocationPaper';
import { sizeAllocationPDF } from '../../PDFs/sizeAllocation';
import '../Global.css';
import PrintStoreLabels from '../../components/Print/PrintStorLabels';
import classes from './FloorUser.module.css';
import axios from 'axios';
import { FEATURE_FLAGS, getImageBase64 } from '../../Utils/utils';

const FloorUser: React.FC = () => {
    const [allocation, setAllocation] = useState<any>();
    const [allocationData, setAllocationData] = useState<any[]>([]);
    const [poNoList, setPoNoList] = useState<any[]>([]);
    const [styleList, setStyleList] = useState<any[]>([]);
    const [po, setPo] = useState<{ poNo: string, poId: string }>({ poNo: '', poId: '' });
    const poSearch = useDebounce(po.poNo, 700);
    const token = useSelector((state: any) => state.user.sessionId);
    const [poTable, setPoTable] = useState<any[]>();
    const theme = useMantineTheme();
    const [printModal, setPrintModal] = useState<boolean>(false);
    const [single, setSingle] = useState(false);
    const [itemType, setItemType] = useState<'clothing' | 'footwear'>('clothing');
    const [orientation, setOrientation] = useState<'portrait' | 'landscape'>('portrait');
    const [imageData, setImageData] = useState<string>('');
    const [file, setFile] = useState<File | undefined>(undefined);
    const [viewModalOpened, setViewModalOpened] = useState<boolean>(false);
    const [userList, setUserList] = useState<string[]>();

    const getPo = async () => {
        const poList = await getPono(token, po?.poNo);
        if (poList) {
            setPoNoList(poList?.data?.map((item: PoNoWithStatus) => {
                return {
                    label: item.poNo,    // Use `item.poNo` for the label
                    value: item.poId!.toString(),  // Convert `poId` to string for the value
                };
            }))
        }
    }

    useEffect(() => {
        getPo();
        getUserNames('');
    }, [poSearch]);

    const handlePoNoChange = async (value: string | null) => {
        if (value) {
            setPo({ poId: value?.toString() || '', poNo: poNoList.find((item: any) => item.value === value).label });
            const allocations = await getAllocationList(token, parseInt(value));
            setAllocationData(allocations);
            setPoTableData(allocations);
            if (allocations) {
                setStyleList(allocations.map((item: any) => {
                    return {
                        label: item.style_no,
                        value: item.style_id.toString()
                    }
                }))
            }
        }
    }
    const handleStyleChange = async (value: string | null) => {
        if (value) {
            setAllocation(allocationData.find((item: any) => item.style_id === parseInt(value)));
            let image = await getImageBase64(`http://localhost:3000/styleImages/${allocationData.find((item: any) => item.style_id === parseInt(value)).image}`)
            setImageData(image as string);
        }
    }

    const setPoTableData = async (data: any) => {
        setPoTable(data?.map((item: any) => {
            return {
                styleNo: item.style_no,
                status: item.allocation_status.some((value: boolean) => value === false) ? 'Pending' : 'Done'
            }
        }));
    }

    const handleInitialChange = async (e: string, storeIndex: number) => {
        let newInitial = allocation.initial;
        newInitial[storeIndex] = e;
        setAllocation({
            ...allocation,
            initial: newInitial
        });
    }
    const handleQuantityChange = async (e: ChangeEvent<HTMLInputElement>, storeIndex: number, index: number) => {
        const value = e.target.value;
        if (value) {
            let newSizeQuantities = allocation.sizeQuantities;
            let newOverstockPerSize = allocation.overstockPerSize;
            let newTotalAllocationPerSize = allocation.totalAllocationPerSize;
            newSizeQuantities[storeIndex][index].quantity = parseInt(value);
            newTotalAllocationPerSize[index] = parseInt(newSizeQuantities.map((item: any) => item[index].quantity).reduce((a: number, b: number) => a + b, 0));
            newOverstockPerSize[index] = allocation.receivedQty[index].quantity - newTotalAllocationPerSize[index];
            setAllocation({
                ...allocation,
                sizeQuantity: newSizeQuantities,
                totalAllocationPerSize: newTotalAllocationPerSize,
                overstockPerSize: newOverstockPerSize,
            });
        }
    }

    const handleReceivedQtyChange = async (e: ChangeEvent<HTMLInputElement>, index: number) => {
        let newReceivedQty = allocation.receivedQty;
        let newOverstockPerSize = allocation.overstockPerSize;
        newOverstockPerSize[index] = allocation.receivedQty[index].quantity - allocation.totalAllocationPerSize[index];
        newReceivedQty[index].quantity = parseInt(e.target.value);
        setAllocation({
            ...allocation,
            receivedQty: newReceivedQty,
            total: newReceivedQty.reduce((a: number, b: { quantity: number }) => a + b.quantity, 0)
        })
    }

    const handleSave = async () => {
        try {
            const saveData = await saveFloorData(token, allocation);
            if (!saveData) {
                alert('Could not save the data')
                return;
            }
            alert('Data saved successfully.');
            if (saveData.data) {
                let newStatus = saveData.data.initial.some((value: string) => value === 'SVP') ? 'Pending' : 'Done';
                await setPoTable(poTable?.map((item) => item.styleNo === saveData.data.style_no
                    ? { ...item, status: newStatus }
                    : item));
            }
        } catch (error) {
            alert('Error saving data.')
            console.log('Error saving data');
        }
    }
    const getUserNames = async (searchTerm: string) => {
        try {
            const response = await fetch(`/auth/usersList`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ searchTerm })
            })
            const { data, message } = await response.json();
            if (response.status === 200) {
                setUserList([...data, 'SVP']);
            }
        } catch (error) {
            console.log('Error getting stats:', error);
            return;
        }
    }
    const downloadAllocationPaper = async () => {
        let sizes = allocation.sizeQuantities[0].map((item: { size: string }) => item.size);
        let tableData = allocation.storeName.map((store: string, index: number) => {
            return {
                storeName: store,
                sizeQuantities: allocation.sizeQuantities[index]
            }
        });
        let totalProductionQuantities = allocation.receivedQty.map((item: { quantity: number }) => item.quantity);
        let totalProductionSum = totalProductionQuantities.reduce((a: number, b: number) => a + b, 0);
        let alloc = {
            binLocation: allocation.binlocation,
            description: allocation.description,
            color: allocation.color,
            cost: allocation.cost,
            msrp: allocation.msrp,
            notes: allocation.note,
            poId: allocation.po_id,
            poNo: allocation.po_no,
            styleNo: allocation.style_no,
            supplierName: allocation.supplierName,
        }
        await allocationPaperPDF(
            sizes,
            tableData,
            totalProductionQuantities,
            totalProductionSum,
            alloc,
            allocation.po_no
        );
        await sizeAllocationPDF(sizes, 6, tableData, allocation.style_no);
    }
    const handleFileChange = (files: File | null) => {
        if (files) {
            setFile(files);
        }
    };
    const handleImageUrlChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const url = e.target.value;
        const response = await fetch(url);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onload = () => {
            setImageData(reader.result as string);
            setFile(new File([blob], 'image.jpg', { type: blob.type }));
        };
        reader.readAsDataURL(blob);
    };
    // const handleUpload = async () => {
    //     if (!file) {
    //         alert('Please select a file');
    //         return;
    //     }
    //     const formData = new FormData();
    //     formData.append('files', file);
    //     formData.append('style_no', allocation.style_no);

    //     try {
    //         const response = await axios.post('/style/uploadImage', formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //                 'Authorization': `Bearer ${token}`
    //             },
    //         });
    //         const data = await response.data;
    //         if (response.status !== 200) {
    //             alert('Error uploading file');
    //             return;
    //         }
    //         alert('Item created successfully.');
    //         return;
    //     } catch (error) {
    //         console.log(error);
    //         alert('Something went wrong while creating item. Please try again.');
    //     }
    // }

    const ViewModal = () => {
        return (
            <Modal
                opened={viewModalOpened}
                onClose={() => setViewModalOpened(false)}
                title={`${allocation?.style_no}`}
                size={'xl'}
            >
                <div className={classes.viewModal}>
                    <Image
                        src={`/styleImages/${allocation?.image}`}
                        alt={`${allocation?.style_no}`}
                    />
                </div>
            </Modal>

        )
    }


    return (
        <div>
            <NavBar />
            <ViewModal />
            <div>
                <div className={classes.main}>
                    <Grid style={{ width: '100%' }}>
                        <Grid.Col span={{ xs: 8, sm: 8, md: 8 }}>
                            <div className={classes.poStyleDiv} >
                                <div className={classes.gridRowDiv} >
                                    <Text className={classes.textWithInput}>Po No:</Text>
                                    <Select
                                        data={poNoList}
                                        placeholder="Select Po No"
                                        style={{ flexGrow: 1 }}
                                        searchable
                                        clearable
                                        onChange={handlePoNoChange}
                                        onSearchChange={(e) => setPo({ ...po, poNo: e.toUpperCase() })}
                                        onClear={() => setPo({ poId: '', poNo: '' })}
                                    />
                                </div>
                                <div className={classes.gridRowDiv} >
                                    <Text className={classes.textWithInput}>Style No:</Text>
                                    <Select
                                        data={styleList}
                                        placeholder="Select Style No"
                                        style={{ flexGrow: 1 }}
                                        searchable
                                        clearable
                                        onChange={handleStyleChange}
                                    />
                                </div>
                                <div className={classes.gridRowDiv} >
                                    <Text className={classes.textWithInput}>Bin:</Text>
                                    <TextInput style={{ flexGrow: 1 }} value={allocation?.binlocation || 'N/A'} />
                                </div>
                            </div>
                            <Grid>
                                <Grid.Col span={{ xs: 6, sm: 4, md: 4, xl: 4 }} className={classes.gridColDiv}>
                                    <div className={classes.gridRowDiv} >
                                        <Text className={classes.textWithInput}>Supplier:</Text>
                                        {/* <TextInput style={{ flexGrow: 1 }} value={allocation?.supplierName} onChange={(e) => setAllocation({ ...allocation, supplierName: e.target.value })} /> */}
                                        <TextInput style={{ flexGrow: 1 }} value={allocation?.supplierName} />
                                    </div>

                                </Grid.Col>
                                <Grid.Col span={{ xs: 6, sm: 4, md: 4, xl: 4 }} className={classes.gridColDiv}>
                                    <div className={classes.gridRowDiv} >
                                        <Text className={classes.textWithInput}>Notes:</Text>
                                        <TextInput style={{ flexGrow: 1 }} value={allocation?.notes} onChange={(e) => setAllocation({
                                            ...allocation,
                                            notes: e.target.value
                                        })} />
                                    </div>
                                </Grid.Col>
                                <Grid.Col span={{ xs: 6, sm: 4, md: 4, xl: 4 }} className={classes.gridColDiv}>
                                    <div className={classes.gridRowDiv} >
                                        <Text className={classes.textWithInput}>Description:</Text>
                                        <TextInput style={{ flexGrow: 1 }} value={allocation?.description} onChange={(e) => setAllocation({
                                            ...allocation,
                                            description: e.target.value
                                        })} />
                                    </div>
                                </Grid.Col>
                                <Grid.Col span={{ xs: 6, sm: 4, md: 4, xl: 4 }} className={classes.gridColDiv}>
                                    <div className={classes.gridRowDiv} >
                                        <Text className={classes.textWithInput}>Color:</Text>
                                        <TextInput style={{ flexGrow: 1 }} value={allocation?.color} onChange={(e) => setAllocation({
                                            ...allocation,
                                            color: e.target.value
                                        })} />
                                    </div>
                                </Grid.Col>
                                <Grid.Col span={{ xs: 6, sm: 4, md: 4, xl: 4 }} className={classes.gridColDiv}>
                                    <div className={classes.gridRowDiv} >
                                        <Text className={classes.textWithInput}>Cost:</Text>
                                        <TextInput style={{ flexGrow: 1 }} value={allocation?.cost} />
                                    </div>
                                </Grid.Col>
                                <Grid.Col span={{ xs: 6, sm: 4, md: 4, xl: 4 }} className={classes.gridColDiv}>
                                    <div className={classes.gridRowDiv} >
                                        <Text className={classes.textWithInput}>MSRP:</Text>
                                        <TextInput style={{ flexGrow: 1 }} value={allocation?.msrp} />
                                    </div>
                                </Grid.Col>
                                {/* {FEATURE_FLAGS.IMAGE_UPLOAD && (<Grid.Col span={{ xs: 6, sm: 4, md: 4, xl: 4 }} className={classes.gridColDiv}>
                                    <div className={classes.gridRowDiv} >
                                        <Text className={classes.textWithInput}>Image:</Text>
                                        <FileInput value={file} accept="image/*"
                                            onChange={(e: any) => handleFileChange(e)}
                                            style={{ flexGrow: 1 }}
                                        />
                                    </div>
                                </Grid.Col>)} */}
                                {/* {FEATURE_FLAGS.IMAGE_UPLOAD && (<Grid.Col span={{ xs: 6, sm: 4, md: 4, xl: 4 }} className={classes.gridColDiv}>
                                    <Button style={{ marginTop: '25px' }} onClick={handleUpload}>{allocation?.image ? `Replace` : `Upload`}</Button>
                                </Grid.Col>)} */}
                                {/* {FEATURE_FLAGS.IMAGE_UPLOAD && (<Grid.Col span={{ xs: 6, sm: 4, md: 4, xl: 4 }} className={classes.gridColDiv}>
                                    {allocation?.image && (<Button style={{ marginTop: '25px' }} onClick={() => setViewModalOpened(true)}>{`View`}</Button>)}
                                </Grid.Col>)} */}
                            </Grid>
                        </Grid.Col>
                        <Grid.Col span={{ xs: 4, sm: 4, md: 4 }}>
                            {poTable && (<div>
                                <div className={classes.info}>
                                    {po.poNo && (
                                        <div className={classes.infoMain}>
                                            {/* <Text fw={650} className={classes.infoTitle} fz={'h4'}>PoNo:</Text> */}
                                            <Text fw={550} className={classes.infoText} fz={'h4'}>{po.poNo}</Text>
                                        </div>
                                    )}
                                    <Grid>
                                        <Grid.Col span={6}>
                                            <div className={classes.infoSub}>
                                                <Text className={classes.infoTitle} fz={'h5'}>Pending</Text>
                                                <Text className={classes.infoText} fz={'h5'}>{poTable.filter((item: any) => item.status === 'Pending').length}</Text>
                                            </div>
                                        </Grid.Col>
                                        <Grid.Col span={6}>
                                            <div className={classes.infoSub}>
                                                <Text className={classes.infoTitle} fz={'h5'}>Done</Text>
                                                <Text className={classes.infoText} fz={'h5'}>{poTable.filter((item: any) => item.status === 'Done').length}</Text>
                                            </div>
                                        </Grid.Col>
                                    </Grid>
                                </div>
                                <div className={classes.poTable}>
                                    <PoTable data={poTable} />
                                </div>
                            </div>)}
                        </Grid.Col>
                    </Grid>
                    <div className={classes.mainTable}>
                        {allocation && (<Table withTableBorder withColumnBorders stickyHeader striped className={classes.table}>
                            <Table.Thead >
                                <Table.Tr className={classes.tableHead}>
                                    <Table.Th >Store Name</Table.Th>
                                    {allocation.sizeQuantities[0].map((item: any) => {
                                        return (
                                            <Table.Th>{item.size}</Table.Th>
                                        )
                                    })}
                                    <Table.Th>Total</Table.Th>
                                    <Table.Th>Initial</Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                                <Table.Tr className={classes.mainRows}>
                                    <Table.Th>RCV QTY</Table.Th>
                                    {allocation.receivedQty.map((item: any, index: number) => {
                                        return (
                                            <Table.Td>
                                                <TextInput
                                                    type='number'
                                                    variant='unstyle'
                                                    value={item.quantity}
                                                    onChange={(e) => handleReceivedQtyChange(e, index)}
                                                    className={classes.tableData}
                                                />
                                            </Table.Td>
                                        )
                                    })}
                                    <Table.Td>
                                        <TextInput
                                            type='number'
                                            variant='unstyle'
                                            value={allocation.receivedQty.reduce((a: number, b: { quantity: number }) => a + b.quantity, 0)}
                                        />
                                    </Table.Td>
                                    <Table.Td></Table.Td>
                                </Table.Tr>
                                {allocation?.storeName?.map((store: string) => {
                                    const storeIndex = allocation.storeName.indexOf(store)
                                    return (
                                        <Table.Tr>
                                            <Table.Th>{store}</Table.Th>
                                            {allocation.sizeQuantities[storeIndex].map((item: any, index: number) => {
                                                return (
                                                    <Table.Td>
                                                        <TextInput
                                                            type='number'
                                                            variant='unstyle'
                                                            value={item.quantity}
                                                            onChange={(e) => handleQuantityChange(e, storeIndex, index)}
                                                        />
                                                    </Table.Td>
                                                )
                                            })}
                                            <Table.Td>{allocation.sizeQuantities[storeIndex].reduce((a: number, b: { quantity: number }) => a + b.quantity, 0)}</Table.Td>
                                            <Table.Td>
                                                <Select
                                                    data={userList}
                                                    searchable
                                                    clearable
                                                    variant='unstyle'
                                                    value={allocation.initial[storeIndex]}
                                                    onChange={(e) => handleInitialChange(e as string, storeIndex)}
                                                />
                                            </Table.Td>
                                        </Table.Tr>
                                    )
                                })}
                                <Table.Tr className={classes.mainRows}>
                                    <Table.Th>Total Allocation</Table.Th>
                                    {allocation.totalAllocationPerSize.map((total: number, index: number) => {
                                        return (
                                            <Table.Td>
                                                <TextInput
                                                    type='number'
                                                    variant='unstyle'
                                                    value={total}
                                                />
                                            </Table.Td>
                                        )
                                    })}
                                    <Table.Td>{allocation.totalAllocationPerSize.reduce((a: number, b: number) => a + b, 0)}</Table.Td>
                                    <Table.Td></Table.Td>
                                </Table.Tr>
                                <Table.Tr className={classes.mainRows}>
                                    <Table.Th>Overstock</Table.Th>
                                    {allocation.overstockPerSize.map((total: number, index: number) => {
                                        return (
                                            <Table.Td>
                                                <TextInput
                                                    type='number'
                                                    variant='unstyle'
                                                    value={total}
                                                />
                                            </Table.Td>
                                        )
                                    })}
                                    <Table.Td>{allocation.overstockPerSize.reduce((a: number, b: number) => a + b, 0)}</Table.Td>
                                    <Table.Td></Table.Td>
                                </Table.Tr>
                            </Table.Tbody>
                        </Table>)}
                    </div>
                </div>
                <div className={'footerDiv'}>

                    <Button variant='outline' className={classes.bottomBtn} onClick={() => setPrintModal(true)} ><IconPrinter />Print labels</Button>
                    <Button variant='outline' className={classes.bottomBtn} onClick={downloadAllocationPaper}><IconReceipt />Allocation Paper</Button>
                    <Button variant='outline' className={classes.bottomBtn} onClick={handleSave} ><IconDeviceFloppy />Save</Button>
                </div>
            </div>

            <Modal
                opened={printModal}
                onClose={() => setPrintModal(false)}
                title={`Print Label Options`}
                centered
                size={'lg'}
            >
                <div>
                    <Grid>
                        <Grid.Col span={6} style={{ gap: '20px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <Table withTableBorder withColumnBorders stickyHeader>
                                    <Table.Tbody>
                                        <Table.Tr>
                                            <Table.Th className={classes.tableHeadModal}>Size/Qty</Table.Th>
                                            <Table.Td ><TextInput
                                                type='text'
                                                variant='unstyled'
                                                className={classes.infoText}
                                            /></Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Th className={classes.tableHeadModal}>Orientation</Table.Th>
                                            <Table.Td ><Select
                                                data={['portrait', 'landscape']}
                                                defaultValue={'portrait'}
                                                value={orientation}
                                                disabled={single}
                                                onChange={(e) => setOrientation(e as 'portrait' | 'landscape')}
                                                placeholder='Select orientation'
                                            /></Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Th className={classes.tableHeadModal}>Image</Table.Th>
                                            <Table.Td > <Input
                                                type='file'
                                                accept="image/*"
                                                onChange={(e) => {
                                                    if (e.target.files) {
                                                        const file = e.target.files[0];
                                                        const reader = new FileReader();
                                                        reader.onload = () => {
                                                            if (reader.result) {
                                                                setImageData(reader.result.toString());
                                                                handleFileChange(file)
                                                            }
                                                        }
                                                        reader.readAsDataURL(file);
                                                    }
                                                }}
                                            /></Table.Td>
                                        </Table.Tr>
                                    </Table.Tbody>
                                </Table>
                            </div>
                        </Grid.Col>
                        <Grid.Col span={6} >
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <Table withTableBorder withColumnBorders stickyHeader>
                                    <Table.Tbody>
                                        <Table.Tr>
                                            <Table.Th className={classes.tableHeadModal}>URL</Table.Th>
                                            <Table.Td ><TextInput
                                                type='text'
                                                variant='unstyled'
                                                className={classes.infoText}
                                                onChange={handleImageUrlChange}
                                            /></Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Th className={classes.tableHeadModal}>Type</Table.Th>
                                            <Table.Td ><Select
                                                data={[{ label: 'Clothing', value: 'clothing' }, { label: 'Footwear/Accessories', value: 'footwear' }]}
                                                defaultValue={'clothing'}
                                                onChange={(e) => setItemType(e as 'clothing' | 'footwear')}
                                                placeholder='Select orientation'
                                            /></Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Th className={classes.tableHeadModal}>Single page</Table.Th>
                                            <Table.Td ><Checkbox
                                                defaultChecked
                                                checked={single}
                                                color={theme.colors.gray[8]}
                                                style={{ display: 'flex', alignItems: 'flex-end' }}
                                                onChange={(e) => {
                                                    setSingle(e.target.checked)
                                                    setOrientation('portrait');
                                                }}
                                            /></Table.Td>
                                        </Table.Tr>
                                    </Table.Tbody>
                                </Table>
                            </div>
                        </Grid.Col>
                    </Grid>
                </div>
                {allocation && (<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', flexDirection: 'row', gap: '20px' }}>
                    <PrintStoreLabels props={{
                        allocationData: {
                            storeName: allocation.storeName || [],
                            sizeQuantities: allocation.sizeQuantities,
                        }, styleData: {
                            color: allocation?.color || '',
                            cost: allocation?.cost || 0,
                            msrp: allocation?.msrp || 0,
                            description: allocation?.description || '',
                            supplier_name: allocation?.supplierName || '',
                            style_no: allocation?.style_no || '',
                            binlocation: allocation?.binlocation || ''
                        }, orientation, imageData,
                        storeTotals: allocation?.sizeQuantities.map((item: any) => {
                            return item.reduce((a: number, b: { size: string, quantity: number }) => a + b.quantity, 0);
                        }),
                        overstockTotal: allocation?.overstockPerSize.reduce((a: number, b: number) => a + b, 0),
                        styleNo: allocation.style_no || '',
                        itemType,
                        single,
                        totalAllocation: allocation.totalAllocationPerSize.reduce((a: number, b: number) => a + b, 0),
                        file,
                    }} />
                    <PrintStoreLabels props={{
                        allocationData: {
                            storeName: allocation.storeName || [],
                            sizeQuantities: allocation.sizeQuantities,
                        }, styleData: {
                            color: allocation?.color || '',
                            cost: allocation?.cost || 0,
                            msrp: allocation?.msrp || 0,
                            description: allocation?.description || '',
                            supplier_name: allocation?.supplierName || '',
                            style_no: allocation?.style_no || '',
                            binlocation: allocation?.binlocation || ''
                        }, orientation, imageData,
                        storeTotals: allocation?.sizeQuantities.map((item: any) => {
                            return item.reduce((a: number, b: { size: string, quantity: number }) => a + b.quantity, 0);
                        }),
                        overstockTotal: allocation?.overstockPerSize.reduce((a: number, b: number) => a + b, 0),
                        styleNo: allocation.style_no || '',
                        itemType,
                        single,
                        totalAllocation: allocation.totalAllocationPerSize.reduce((a: number, b: number) => a + b, 0),
                        file,
                        btnLabel: 'Store Label'
                    }} />
                </div>)}
            </Modal>
        </div>
    )
}

export default FloorUser;