
import { Table, Text, useMantineTheme } from '@mantine/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classes from './StyleTable.module.css';
interface StyleTableProps {
    styleData: any;
    style_no: string;
}
const StyleTable: React.FC<StyleTableProps> = ({ styleData, style_no }) => {
    const theme = useMantineTheme();
    // const [styleData, setStyleData] = useState<StyleData[]>([]);
    const [storeList, setStoreList] = useState<{ store: string, totalQuantity: number, totalTransQuantity: number, maxSize?: boolean }[]>([]);
    const token = useSelector((state: any) => state.user.sessionId);
    const [storeName, setStoreName] = useState('');
    const [newStyle, setNewStyle] = useState(false);

    const expandableRows = (record: any) => {
        return (<Table border={1} withColumnBorders striped className={classes.table} withTableBorder>
            <Table.Thead >
                <Table.Tr style={{ backgroundColor: '#999999' }}>
                    <Table.Th>Size</Table.Th>
                    <Table.Th>QTY</Table.Th>
                    <Table.Th>IN TRNS QTY</Table.Th>
                    <Table.Th>Bin</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {record.storeData.map((group: any) => (
                    <Table.Tr key={group.size}>
                        <Table.Td>{group.size}</Table.Td>
                        <Table.Td>{group.qty}</Table.Td>
                        <Table.Td>{group.inTransit}</Table.Td>
                        <Table.Td>{group.binLocation}</Table.Td>
                    </Table.Tr>

                ))}
            </Table.Tbody>
        </Table>)
    };


    return (
        <div className={classes.gridColDiv} style={{ backgroundColor: theme.colors.gray[0], color: theme.colors.gray[10] }}>
            {styleData.length > 0 && (<div className={classes.gridRowDiv} style={{ display: 'flex', justifyContent: 'center', backgroundColor:theme.colors.gray[1] }} >
                <Text className={classes.textWithInput}>Style No:</Text>
                <Text className={classes.textWithInput} style={{ fontWeight:'bold'}}>{style_no}</Text>
            </div>)}
            {styleData.length > 0 ? (<Table border={1} withColumnBorders striped stickyHeader className={classes.table} withTableBorder>
                <Table.Thead style={{ backgroundColor: theme.colors.gray[8], color: theme.colors.gray[0] }}>
                    <Table.Tr>
                        <Table.Th>Store</Table.Th>
                        <Table.Th>Quantity</Table.Th>
                        <Table.Th>In Transit</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {styleData.map((item: any) => {
                        return (<>
                            <Table.Tr onClick={() => setStoreName(item.store)}>
                                <Table.Td>{item.store}</Table.Td>
                                <Table.Td>{item.quantity}</Table.Td>
                                <Table.Td>{item.inTransit}</Table.Td>
                            </Table.Tr>
                            {storeName === item.store &&
                                <Table.Tr>
                                    <Table.Td colSpan={3}>
                                        {expandableRows(item)}
                                    </Table.Td>
                                </Table.Tr>
                            }
                        </>)
                    })}
                </Table.Tbody>
            </Table>) :
                <div style={{ textAlign: 'center', display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent:'center'}}>
                    <h3 className={'title'}>New Style</h3>
                    {style_no && <h2 style={{ border: '1px solid black', padding: '15px', borderRadius:'5px'}}>{style_no}</h2>}
                </div>}
        </div>
    );
};

export default StyleTable;