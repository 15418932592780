import { Button, Table, TextInput } from '@mantine/core';
import { IconDownload, IconFileDownload } from '@tabler/icons-react';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import NavBar from '../../components/NavBar/NavBar';
import { STORES_PAIRS } from '../../constants/stores';
import '../Global.css';
import classes from './AutomateTr.module.css';


const AutomateTr = () => {
    const [tableData, setTableData] = useState<any[]>([]);
    const token = useSelector((state: any) => state.user.sessionId);
    const [poNo, setPoNo] = useState('');
    const [storeList, setStoreList] = useState<string[]>(Object.keys(STORES_PAIRS));
    const [allocations, setAllocations] = useState<any[]>([]);
    const [selectedRow, setSelectedRow] = useState<string>('');
    const [selectedStyle, setSelectedStyle] = useState<{ store: string, index: number }>();
    const fetchData = async () => {
        try {
            const response = await fetch(`/allocation/tr/${poNo}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setAllocations(data);
            await setTableData(storeList.map((store) => {
                let dataFiltered = data.filter((data: any) => data.storeName.includes(store));
                return {
                    storeName: store,
                    styleNo: dataFiltered.map((data: any) => data.style_no),
                    supplierName: (new Set(dataFiltered.map((data: any) => data.supplierName))).values().next().value,
                    sizeQuantities: dataFiltered.map((data: any) => data.sizeQuantities[data.storeName.indexOf(store)]),
                    poNo: (new Set(dataFiltered.map((data: any) => data.po_no))).values().next().value,
                    cost: dataFiltered.map((data: any) => data.cost),
                    msrp: dataFiltered.map((data: any) => data.msrp),
                    color: dataFiltered.map((data: any) => data.color),
                    description: dataFiltered.map((data: any) => data.description),
                    notes: dataFiltered.map((data: any) => data.notes),
                    totalQty: dataFiltered.map((data: any) => data.sizeQuantities[data.storeName.indexOf(store)].reduce((total: number, item: { quantity: number; }) => total + item.quantity, 0)),
                    status: dataFiltered.map((data: any) => {
                        if (data.allocation_status[data.storeName.indexOf(store)] === true) {
                            return 'Allocated';
                        } else {
                            return 'Pending';
                        }
                    }),
                    skuNumbers: dataFiltered.map((data: any) => data.skuNumbers),
                };
            }).filter(record => record.totalQty.reduce((a: number, b: number) => a + b, 0) > 0));
        } catch (error) {
            console.error(error);
        }
    };

    const renderExpandedStyleDetails = (sizeQuantities: any[], skuNumbers: string[]) => {
        return (
            <Table withColumnBorders withRowBorders withTableBorder striped stickyHeader style={{ borderCollapse: 'collapse' }}>
                <Table.Thead>
                    <Table.Tr className={classes.expandedStyleTableHead}>
                        <Table.Th>Sku</Table.Th>
                        <Table.Th>Size</Table.Th>
                        <Table.Th>Quantity</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {sizeQuantities && skuNumbers && sizeQuantities.map((item: { size: string, quantity: number }, indx: number) => {
                        return (<Table.Tr key={indx} >
                            <Table.Td>{skuNumbers[indx]}</Table.Td>
                            <Table.Td>{item.size}</Table.Td>
                            <Table.Td>{item.quantity}</Table.Td>
                        </Table.Tr>)
                    })}
                </Table.Tbody>
            </Table>
        );
    };

    const renderExpandedRowDetails = (row: any) => {
        return (
            <Table withColumnBorders withRowBorders withTableBorder striped stickyHeader style={{ borderCollapse: 'collapse' }}>
                <Table.Thead>
                    <Table.Tr className={classes.expandedTableHead}>
                        <Table.Th>Stores</Table.Th>
                        <Table.Th>Supplier name</Table.Th>
                        <Table.Th>Total quantity</Table.Th>
                        <Table.Th>Status</Table.Th>
                        <Table.Th>Cost</Table.Th>
                        <Table.Th>MSRP</Table.Th>
                        <Table.Th>Description</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {row && row.styleNo.map((item: any, index: number) => {
                        return (<><Table.Tr onClick={() => setSelectedStyle({ store: row.storeName, index })}>
                            <Table.Td>{item}</Table.Td>
                            <Table.Td>{row.supplierName}</Table.Td>
                            <Table.Td>{row.totalQty[index]}</Table.Td>
                            <Table.Td>{row.status[index]}</Table.Td>
                            <Table.Td>{row.cost[index]}</Table.Td>
                            <Table.Td>{row.msrp[index]}</Table.Td>
                            <Table.Td>{row.description[index]}</Table.Td>
                        </Table.Tr>
                            {selectedStyle?.store === row.storeName && selectedStyle?.index === index &&
                                <Table.Tr>
                                    <Table.Td colSpan={7}>
                                        {renderExpandedStyleDetails(row.sizeQuantities[index], row.skuNumbers[index])}
                                    </Table.Td>
                                </Table.Tr>
                            }
                        </>
                        )
                    })}
                </Table.Tbody>
            </Table>
        );
    };
    const generateDataString = (store: string) => {
        let dataString = '';

        allocations.filter(allocation => allocation.storeName.includes(store)).forEach(allocation => {
            let i = allocation.storeName.indexOf(store);
            allocation.sizeQuantities.forEach((sizes: { size: string, quantity: number }[], storeIndex: number) => {
                if (i === storeIndex) {
                    sizes.forEach((size: { size: string, quantity: number }, sizeIndex: number) => {
                        // Access the corresponding SKU using the sizeIndex within this store's context
                        const sku = allocation.skuNumbers && allocation.skuNumbers.length > sizeIndex ? allocation.skuNumbers[sizeIndex] : 'N/A';
                        // Check if the quantity is greater than 0 and SKU is available before adding to the string
                        if (size.quantity > 0 && sku !== 'N/A') {
                            dataString += `${sku},${size.quantity},,\n`; // Format the string as needed
                        }
                    });
                }
            });
        });
        return dataString;
    };

    const handleDownload = (store: string) => {
        const dataString = generateDataString(store);

        // Check if the dataString is empty
        if (!dataString) {
            alert('No data available to download.');
            return;
        }

        // Format the filename to include poNo and storeName, replacing any spaces or invalid characters
        const formattedPoNo = poNo.replace(/[^a-zA-Z0-9]/g, '_');
        const formattedStoreName = store.replace(/[^a-zA-Z0-9]/g, '_');
        const fileName = `${formattedPoNo}_${formattedStoreName}.txt`;

        const blob = new Blob([dataString], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName; // Name of the file to be downloaded using formatted poNo and storeName
        link.click();
        URL.revokeObjectURL(url); // Clean up the URL object
    };

    const handleDownloadAll = async () => {
        const zip = new JSZip();
        const allStores = tableData.filter(data => data.totalQty.reduce((a:number, b:number) => a + b, 0) > 0).map(data => data.storeName);
        if(allStores.length === 0){
          alert('No data available to download.');
          return;
        }
        for (const store of allStores) {
          const dataString = generateDataString(store);
      
          // Check if the dataString is empty
          if (!dataString) {
            alert('No data available to download for some stores.');
            return;
          }
      
          // Format the filename to include poNo and storeName, replacing any spaces or invalid characters
          const formattedPoNo = poNo.replace(/[^a-zA-Z0-9]/g, '_');
          const formattedStoreName = store.replace(/[^a-zA-Z0-9]/g, '_');
          const fileName = `${formattedPoNo}_${formattedStoreName}.txt`;
      
          // Add the file to the ZIP
          zip.file(fileName, dataString);
        }
      
        // Generate the ZIP file and trigger the download
        const formattedPoNo = poNo.replace(/[^a-zA-Z0-9]/g, '_');
        const fileName = `${formattedPoNo}_ALL_STORES.zip`;
        const content = await zip.generateAsync({ type: 'blob' });
        saveAs(content, fileName);
      };

    return (
        <div>
            <NavBar />
            <div className={'main'}>
                <h2 className='title'>Automate Transfer</h2>
                <div className={classes.upperSection}>
                    <TextInput
                        placeholder='Po Number'
                        value={poNo}
                        onChange={(event) => setPoNo(event.target.value)}
                        style={{ flexGrow: 1 }}
                    />
                    <Button onClick={fetchData}>Get Data</Button>
                    <Button onClick={handleDownloadAll}><IconFileDownload /> Download All</Button>
                </div>
                <div>
                    <Table striped withColumnBorders withTableBorder stickyHeader className={classes.table}>
                        <Table.Thead className={classes.tableHead}>
                            <Table.Tr>
                                <Table.Th>Store name</Table.Th>
                                <Table.Th>Download Tr file</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {tableData && tableData.map((item: any) => {
                                return (<><Table.Tr onClick={() => setSelectedRow(item.storeName)}>
                                    <Table.Td>{item.storeName}</Table.Td>
                                    <Table.Td><IconDownload className={classes.downloadIcon} onClick={() => handleDownload(item.storeName)} /></Table.Td>
                                </Table.Tr>
                                    {selectedRow === item.storeName &&
                                        <Table.Tr>
                                            <Table.Td colSpan={2}>
                                                {renderExpandedRowDetails(item)}
                                            </Table.Td>
                                        </Table.Tr>
                                    }
                                </>
                                )
                            })}
                        </Table.Tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}

export default AutomateTr;