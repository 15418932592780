import { Button, Menu, Text, useMantineTheme } from '@mantine/core';
import { IconProps } from '@tabler/icons-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UseAccess } from '../../Utils/getAccess';

// Define the Props for the NavLinksGroup component
interface Link {
  label: string;
  link: string;
  data?: any;
  access: string;
}

interface NavLinksGroupProps {
  label: string;
  icon: React.ComponentType<IconProps>;
  opened: boolean; // Accept 'opened' state from parent
  onToggle: () => void; // Accept 'onToggle' handler to toggle state in parent
  links?: Link[];
  link?: string;
  access?: string;
}

export const NavLinksGroup: React.FC<NavLinksGroupProps> = ({
  label,
  icon: Icon,
  opened,
  onToggle,
  links,
  link,
  access
}) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const authorized = access ? UseAccess(access) : true;


  return (
    <div style={{ position: 'relative' }}> {/* Add relative positioning to the parent div */}
      {/* Parent Group, click to toggle the nested links */}
      {authorized && (<Menu shadow="md" width={200}>
        <Menu.Target >
          <div style={{
            cursor: 'pointer', marginTop: 10
          }} >
            <Button variant="subtle" onClick={onToggle} style={{ backgroundColor: theme.colors.gray[1], color: theme.colors.gray[9] }}>
              <Icon size={21} stroke={2} />
              <Text onClick={link !== undefined ? () => navigate(link) : () => null} >
                {label}
              </Text>
            </Button>
          </div>
        </Menu.Target>
        <Menu.Dropdown >
          {links?.map((link) => {
            const linkAccess = link.access ? UseAccess(link.access) : false;
            if (!linkAccess) return null;
            return (
              <Menu.Item
                key={link.label}
                onClick={() => navigate(link.link, { state: link.data })}
              >
                {link.label}
              </Menu.Item>
            )
          })}
        </Menu.Dropdown>
      </Menu>)}
    </div>
  );
};
