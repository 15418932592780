import { Avatar, Group, Text, UnstyledButton } from '@mantine/core';
import { IconChevronRight, IconLogout } from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import profileLogo from '../../assets/Avatar.svg';
import { logout } from '../../config/auth/userSlice';
import classes from './UserButton.module.css';

export function UserButton() {
    // get the user from the redux store
    const user = useSelector((state: any) => state.user.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();

        const handleLogOut = () => {
            dispatch(logout());
            navigate('/login');
        }
    if (!user) {
        return null;
    }

    return (
        <UnstyledButton className={classes.user}>
            <Group>
                {/* <Avatar
                    src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-8.png"
                    radius="xl"
                /> */}
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px', flexDirection: 'row' }} onClick={() => navigate('/profile')}>
                    <Avatar
                        src={profileLogo}
                        radius="xl"
                    />
                    <Text size="sm" fw={500}>
                        {user.first_name} {user.last_name}
                    </Text>
                    <IconChevronRight size={14} stroke={1.5} />
                </div>
                <Group style={{ paddingLeft: '40px' }} onClick={handleLogOut}>
                    <IconLogout size={20} />
                    <Text>Log out</Text>
                </Group>


                {/* <div style={{ flex: 1 }}>
                    <Text size="sm" fw={500}>
                        {user.first_name} {user.last_name}
                    </Text>

                    <Text c="dimmed" size="xs">
                        {user.email}
                    </Text>
                </div> */}

            </Group>
        </UnstyledButton>
    );
}