import { Button, Grid, Select, Table, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconDeviceFloppy, IconPlus, IconTrash } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from '../../Hooks/useDebounce';
import { delteAllocation, updatePoAndStyle } from '../../api/allocation';
import { getPono } from '../../api/poList';
import { saveFloorData, updateAllocation } from '../../api/saveFloorData';
import { getAllocationList } from '../../api/styleList';
import NavBar from '../../components/NavBar/NavBar';
import { STORES } from '../../constants/stores';
import { AllocationData } from '../../interface/Allocation';
import { PoNoWithStatus } from '../../interface/AllocationInvoice';
import '../Global.css';
import classes from './ModifyAllocation.module.css';

type SizeQuantity = {
    size: string;
    quantity: number;
};
const ModifyAllocation: React.FC = () => {
    const [poNoList, setPoNoList] = useState<any[]>([]);
    const [styleList, setStyleList] = useState<any[]>([]);
    const [allocation, setAllocation] = useState<AllocationData>();
    const [allocationData, setAllocationData] = useState<any[]>([]);
    const [po, setPo] = useState<{ poNo: string, poId: string }>({ poNo: '', poId: '' });
    const poSearch = useDebounce(po.poNo, 700);
    const token = useSelector((state: any) => state.user.sessionId);
    const [poTable, setPoTable] = useState<any[]>();
    const theme = useMantineTheme();
    const isMobile = useMediaQuery("(min-width: 0) and (max-width: 550px)") || false;
    const isTablet = useMediaQuery("(min-width: 551px) and (max-width: 1198px)") || false;
    const isDesktop = useMediaQuery("(min-width: 1199px)") || false;
    const [sizeData, setSizeData] = useState<{ size: string | undefined, quantity: number | undefined, index: number | -1 }>({
        size: undefined,
        quantity: undefined,
        index: -1
    });
    const [store, setStore] = useState<{ store: string, index: number | undefined }>();

    const getPo = async () => {
        const poList = await getPono(token, po?.poNo);
        if (poList) {
            setPoNoList(poList?.data?.map((item: PoNoWithStatus) => {
                return {
                    label: item.poNo,    // Use `item.poNo` for the label
                    value: item.poId!.toString(),  // Convert `poId` to string for the value
                };
            }))
        }
    }

    useEffect(() => {
        getPo();
    }, [poSearch]);


    const setPoTableData = async (data: any) => {
        setPoTable(data.map((item: any) => {
            return {
                styleNo: item.style_no,
                status: item.allocation_status.some((value: boolean) => value === false) ? 'Pending' : 'Done'
            }
        }));
    }


    const handleStyleChange = async (value: string | null) => {
        if (value) {
            setAllocation(allocationData.find((item: any) => item.style_id === parseInt(value)))
        }
    }

    const handlePoNoChange = async (value: string | null) => {
        if (value) {
            setPo({ poId: value?.toString() || '', poNo: poNoList.find((item: any) => item.value === value).label });
            const allocations = await getAllocationList(token, parseInt(value));
            setAllocationData(allocations);
            setPoTableData(allocations);
            if (allocations) {
                setStyleList(allocations.map((item: any) => {
                    return {
                        label: item.style_no,
                        value: item.style_id.toString()
                    }
                }))
            }
        }
    }

    // Store change
    const handleStoreChange = async (storeValue: string) => {
        if (storeValue && storeValue !== '') {
            await setStore({ store: storeValue || '', index: allocation?.storeName?.length });
            let storeIndex = STORES.indexOf(storeValue);
            while (storeIndex >= 0) {
                let prvStore = STORES[storeIndex - 1];
                let i = allocation?.storeName?.indexOf(prvStore) as number;
                if (i !== -1) {
                    await setStore({ store: storeValue || '', index: i + 1 })
                    break;
                }
                if (storeIndex === 0) {
                    await setStore({ store: storeValue || '', index: 0 })
                    break;
                }
                storeIndex--;
            }
        }
    }
    // Add store
    const handleAddStore = async () => {
        try {
            if (allocation && store?.store && store.index !== undefined) {
                if (allocation?.storeName?.includes(store?.store)) {
                    alert('Store already exists.');
                    return;
                }
                let newStores = allocation?.storeName?.splice(store.index, 0, store.store);
                let newQuantities = allocation.sizeQuantities?.splice(store.index, 0, (allocation.receivedQty ?? []).map((item: { size: string, quantity: number }) => {
                    return {
                        size: item.size,
                        quantity: 0
                    }
                }));
                let newInitial = allocation.initial?.splice(store.index, 0, 'SVP');
                let newStatus = allocation.allocation_status?.splice(store.index, 0, false);
                // const response = await saveFloorData(token, {
                //     ...allocation,
                //     storeName: newStores,
                //     sizeQuantities: newQuantities,
                //     initial: newInitial,
                //     allocation_status: newStatus
                // });
                const response = await saveFloorData(token, allocation);
                if (response.data) {
                    await setAllocation(response.data);
                    return;
                } else {
                    alert('Could not update data.');
                }

            } else {
                alert('Please select the store')
            }
        } catch (error) {
            console.log('Error Updating data:', error)
        }
    }

    // Delete store
    const handleDeleteStore = async (store: string, index: number) => {
        const confirm = window.confirm(`Do you want to delte ${store}`);
        if (confirm) {
            try {
                if (allocation && store && index !== undefined) {
                    if (!allocation?.storeName?.includes(store)) {
                        alert('Store does not exists.');
                        return;
                    }
                    let newStores = allocation?.storeName?.splice(index, 1);
                    let newQuantities = allocation.sizeQuantities?.splice(index, 1);
                    let newInitial = allocation.initial?.splice(index, 1);
                    let newStatus = allocation.allocation_status?.splice(index, 1);

                    // const response = await saveFloorData(token, {
                    //     ...allocation,
                    //     storeName: newStores,
                    //     sizeQuantities: newQuantities,
                    //     initial: newInitial,
                    //     allocation_status: newStatus
                    // });
                    const response = await saveFloorData(token, allocation);
                    if (response.data) {
                        await setAllocation(response.data);
                        return;
                    } else {
                        alert('Could not update data.');
                    }

                } else {
                    alert('Please select the store')
                }
            } catch (error) {
                console.log('Error Updating data:', error)
            }
        }
    }

    // Add size
    const handleAddSize = async () => {
        if (sizeData.size && sizeData.quantity && allocation?.receivedQty) {
            if (sizeData.index < 1 || sizeData.index > allocation.receivedQty.length + 1) alert('Enter proper index');
            try {
                if (allocation && allocation.totalAllocationPerSize?.length) {
                    let existing = allocation?.receivedQty?.filter((item: { size: string }) => item.size.toLowerCase() === sizeData.size?.toLowerCase());
                    if (existing.length > 0) {
                        alert('Size already exists.');
                        return;
                    }
                    // let newSku = allocation?.skuNumbers?.splice(sizeData.index, 0, '');
                    let newSizeQuantities = allocation.sizeQuantities;
                    newSizeQuantities = newSizeQuantities?.map((item: any) => {
                        return item.splice(sizeData.index - 1, 0, { size: sizeData.size, quantity: 0 });
                    });

                    let newInitial = allocation.initial;
                    if (newInitial) newInitial[sizeData.index - 1] = 'SVP';

                    let newStatus = allocation.allocation_status;
                    if (newStatus) newStatus[sizeData.index - 1] = false;

                    let newTotal = allocation.total || 0 + sizeData.quantity;

                    let newReceivedQty = allocation.receivedQty?.splice(sizeData.index - 1, 0, { size: sizeData.size, quantity: sizeData.quantity });

                    let newTotalAllocationPerSize = allocation.totalAllocationPerSize?.splice(sizeData.index - 1, 0, 0);

                    let newOverstockPerSize = allocation.overstockPerSize?.splice(sizeData.index - 1, 0, sizeData.quantity)
                    // const response = await saveFloorData(token, {
                    //     ...allocation,
                    //     sizeQuantities: newSizeQuantities,
                    //     initial: newInitial,
                    //     allocation_status: newStatus,
                    //     receivedQty: newReceivedQty,
                    //     total: newTotal,
                    //     totalAllocationPerSize: newTotalAllocationPerSize,
                    //     overstockPerSize: newOverstockPerSize
                    // });
                    const response = await saveFloorData(token, allocation);
                    if (response.data) {
                        await setAllocation(response.data);
                        return;
                    } else {
                        alert('Could not update data.');
                    }

                } else {
                    alert('Allocation not found.')
                }
            } catch (error) {
                console.log('Something went wrong', error);
            }
        } else {
            alert('Please enter size detials properly');
        }

    }
    // Delete size
    const handleDeleteSize = async (size: string, quantity: number, index: number) => {
        if (size && quantity && allocation?.receivedQty) {
            try {
                if (allocation && allocation.totalAllocationPerSize?.length) {
                    // let newSku = allocation?.skuNumbers?.splice(index, 0, '');
                    let newSizeQuantities = allocation.sizeQuantities;
                    newSizeQuantities = newSizeQuantities?.map((item: any) => {
                        return item.splice(index, 1);
                    });

                    let newInitial = allocation?.initial?.splice(index, 1);

                    let newStatus = allocation.allocation_status?.splice(index, 1);

                    let newTotal = allocation.total || 0 - quantity;

                    let newReceivedQty = allocation.receivedQty?.splice(index, 1);


                    let newTotalAllocationPerSize = allocation.totalAllocationPerSize?.splice(index, 1);

                    let newOverstockPerSize = allocation.overstockPerSize?.splice(index, 1);
                    // const response = await saveFloorData(token, {
                    //     ...allocation,
                    //     sizeQuantities: newSizeQuantities,
                    //     initial: newInitial,
                    //     allocation_status: newStatus,
                    //     receivedQty: newReceivedQty,
                    //     total: newTotal,
                    //     totalAllocationPerSize: newTotalAllocationPerSize,
                    //     overstockPerSize: newOverstockPerSize
                    // });
                    const response = await updateAllocation(token, allocation);
                    if (response.data) {
                        await setAllocation(response.data);
                        return;
                    } else {
                        alert('Could not update data.');
                    }

                } else {
                    alert('Allocation not found.')
                }
            } catch (error) {
                console.log('Something went wrong', error);
            }
        } else {
            alert('Please enter size detials properly');
        }
    }

    const handleSave = async () => {
        const po_id = allocation?.allocation_po_id as number;
        const styl_id = allocation?.style_id as number;
        const po_no = allocation?.po_no as string;
        const style_no = allocation?.style_no as string;
        try {
            const update = await updatePoAndStyle(token, po_id, styl_id, po_no, style_no);
            return;
        } catch (error) {
            console.log('Error updating Pono and StyleNo.');
        }
    }
    const handleDelete = async () => {
        const confirm = window.confirm('Are you sure you want to delete this allocation?');
        if (!confirm) return;
        try {
            const deletedAllocation = await delteAllocation(token, allocation?.allocation_id as number);
            if (deletedAllocation === true) {
                setStyleList(styleList.filter((item: any) => item.style_id !== allocation?.style_id));
                setAllocationData(allocationData.filter((item: any) => item.allocation_id !== allocation?.allocation_id));
                setAllocation(undefined);
                alert('Allocation deleted successfully.');
                return;
            }
            return;
        } catch (error) {
            console.log('Error deleting allocation:', error);
        }
    }

    return (
        <div>
            <NavBar />
            <div className={'main'}>
                <h2 className={'title'}>Modify Allocation</h2>
                <div style={{ width: '100%', marginBottom: '50px' }}>
                    <Grid >
                        <Grid.Col offset={isDesktop ? 2 : undefined} span={isDesktop ? 3 : isTablet ? 4 : 12}>
                            <div className={classes.gridRowDiv} >
                                {/* <Text className={classes.textWithInput}>Po No:</Text> */}
                                <Select
                                    data={poNoList}
                                    label='Po No'
                                    placeholder="Select Po No"
                                    style={{ flexGrow: 1 }}
                                    className={classes.selectDiv}
                                    searchable
                                    clearable
                                    onChange={handlePoNoChange}
                                    onSearchChange={(e) => setPo({ ...po, poNo: e.toUpperCase() })}
                                    onClear={() => {
                                        setPo({ poId: '', poNo: '' })
                                        setAllocation(undefined);
                                    }}
                                />
                            </div>
                        </Grid.Col>
                        <Grid.Col span={isDesktop ? 3 : isTablet ? 4 : 12}>
                            <div className={classes.gridRowDiv} >
                                {/* <Text className={classes.textWithInput}>Style No:</Text> */}
                                <Select
                                    data={styleList}
                                    label='Style No'
                                    placeholder="Select Style No"
                                    style={{ flexGrow: 1 }}
                                    className={classes.selectDiv}
                                    searchable
                                    clearable
                                    onChange={handleStyleChange}
                                    onClear={() => {
                                        setAllocation(undefined);
                                    }}
                                />
                            </div>
                        </Grid.Col>
                        <Grid.Col span={isMobile ? 12 : 2}>
                            {allocation && (<Button style={{ marginTop: '25px' }} variant='outline' className={classes.trashIcon} onClick={handleDelete}><IconTrash />Delete Allocation</Button>)}
                        </Grid.Col>
                    </Grid>
                    {allocation && (<><Grid >

                        <Grid.Col className={classes.storeSection} span={isMobile ? 12 : isTablet ? 3 : 3}>

                            <div className={classes.tableStore}>
                                <Table withTableBorder withColumnBorders stickyHeader >
                                    <Table.Thead className={classes.tableHead}>
                                        <Table.Tr>
                                            <Table.Th style={{ textAlign: 'center' }}>Stores</Table.Th>
                                        </Table.Tr>
                                    </Table.Thead>
                                    <Table.Tbody>
                                        {allocation.storeName?.map((store: string, index: number) => {
                                            return (
                                                <Table.Tr>
                                                    <Table.Td style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between' }}>
                                                        <Text>{index + 1}</Text> {store} <IconTrash className={classes.trashIcon} onClick={() => handleDeleteStore(store, index)} />
                                                    </Table.Td>
                                                </Table.Tr>)
                                        })}
                                    </Table.Tbody>
                                </Table>
                            </div>
                        </Grid.Col>
                        <Grid.Col className={classes.storeSection} span={isMobile ? 12 : isTablet ? 3 : 3}>
                            <div className={classes.tableStore}>
                                <Table withTableBorder withColumnBorders stickyHeader >
                                    <Table.Thead className={classes.tableHead}>
                                        <Table.Tr>
                                            <Table.Th style={{ textAlign: 'center' }}>Sizes</Table.Th>
                                        </Table.Tr>
                                    </Table.Thead>
                                    <Table.Tbody>
                                        {allocation.receivedQty?.map((item: { size: string, quantity: number }, index: number) => {
                                            return (
                                                <Table.Tr>
                                                    <Table.Td style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between' }}>
                                                        {index + 1}<div style={{ backgroundColor: theme.colors.gray[2], borderRadius: '5px', padding: '3px', width: '70px' }}>
                                                            {item.size}
                                                        </div>
                                                        {item.quantity}
                                                        <IconTrash className={classes.trashIcon} onClick={() => handleDeleteSize(item.size, item.quantity, index)} />
                                                    </Table.Td>
                                                </Table.Tr>)
                                        })}
                                    </Table.Tbody>
                                </Table>
                            </div>
                        </Grid.Col>
                        <Grid.Col span={isMobile ? 12 : isTablet ? 6 : 6} className={classes.storeSection}>
                            {/* <h3 style={{ textAlign: 'center' }}>Info</h3> */}
                            <div className={classes.rowSection}>
                                <TextInput
                                    label='Po No'
                                    placeholder='Po No'
                                    value={allocation.po_no}
                                    style={{ flexGrow: 1 }}
                                    onChange={(e) => setAllocation({ ...allocation, po_no: e.target.value.toUpperCase() })}
                                />
                                <TextInput
                                    label='Style No'
                                    placeholder='Style No'
                                    value={allocation.style_no}
                                    style={{ flexGrow: 1 }}
                                    onChange={(e) => setAllocation({ ...allocation, style_no: e.target.value.toUpperCase() })}
                                />
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                                    <Button onClick={handleSave} style={{ width: isMobile ? '50%' : '100%' }} className={classes.bottomBtn} ><IconDeviceFloppy /> Save</Button>
                                </div>
                            </div>
                            <div className={classes.rowSection} >
                                <Select
                                    label='Add store'
                                    searchable
                                    clearable
                                    data={STORES.filter((store: string) => !allocation.storeName?.includes(store))}
                                    placeholder='Add store...'
                                    className={classes.selectStore}
                                    onChange={(value) => handleStoreChange(value || '')}
                                />
                                <IconPlus className={classes.plusIcon} onClick={handleAddStore} />
                            </div>
                            <div className={classes.rowSection}>
                                <TextInput
                                    type='number'
                                    label='Index'
                                    placeholder='Index'
                                    className={classes.selectStore}
                                    onChange={(e) => setSizeData({ ...sizeData, index: parseInt(e.target.value) })}
                                />
                                <TextInput
                                    label='Add size'
                                    placeholder='Add Size...'
                                    className={classes.selectStore}
                                    onChange={(e) => setSizeData({ ...sizeData, size: e.target.value })}
                                />
                                <TextInput
                                    type='number'
                                    label='Add quantity'
                                    placeholder='Quantity'
                                    className={classes.selectStore}
                                    onChange={(e) => setSizeData({ ...sizeData, quantity: parseInt(e.target.value) })}
                                />
                                {/* </div>
                            <div className={classes.rowSection}> */}
                                <IconPlus className={classes.plusIcon} onClick={() => handleAddSize()} />
                            </div>
                        </Grid.Col>
                    </Grid></>)}
                </div>
            </div>
            <div className={'footerDiv'}>

            </div>
        </div>
    )
}

export default ModifyAllocation;