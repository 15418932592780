import { Select, Table } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from '../../Hooks/useDebounce';
import { getPono } from '../../api/poList';
import { getAllocationList } from '../../api/styleList';
import { PoNoWithStatus } from '../../interface/AllocationInvoice';
import '../../pages/Global.css';
import classes from './PoData.module.css';

interface TableData {
    store: string;
    styleCount: number;
    quantity: number;
    menQty: number;
    womenQty: number;
    kidsQty: number;
    otherQty: number;
}

const PoData: React.FC = () => {
    const [poNoList, setPoNoList] = useState<any[]>([]);
    const [po, setPo] = useState<{ poNo: string, poId: string }>({ poNo: '', poId: '' });
    const poSearch = useDebounce(po.poNo, 700);
    const token = useSelector((state: any) => state.user.sessionId);
    const [tableData, setTableData] = useState<TableData[]>();


    const getPo = async () => {
        const poList = await getPono(token, po?.poNo);
        if (poList) {
            setPoNoList(poList?.data?.map((item: PoNoWithStatus) => {
                return {
                    label: item.poNo,    // Use `item.poNo` for the label
                    value: item.poId!.toString(),  // Convert `poId` to string for the value
                };
            }))
        }
    }

    useEffect(() => {
        getPo();
    }, [poSearch]);

    const handlePoNoChange = async (value: string | null) => {
        if (value) {
            setPo({ poId: value?.toString() || '', poNo: poNoList.find((item: any) => item.value === value).label });
            const allocations = await getAllocationList(token, parseInt(value));
            let data: TableData[] = [];
            allocations?.forEach((element: any) => {
                element.storeName.forEach((store: string, index: number) => {
                    let M = element.description.startsWith('M ');
                    let W = element.description.startsWith('W ')
                    let K = element.description.startsWith('K ')
                    if (!data.find((item: TableData) => item.store === store)) {
                        data.push({
                            store: store,
                            styleCount: 1,
                            quantity: element.sizeQuantities[index].reduce((total: number, current: { quantity: number }) => total + current.quantity, 0),
                            menQty: M ? 1 : 0,
                            womenQty: W ? 1 : 0,
                            kidsQty: K ? 1 : 0,
                            otherQty: !M && !W && !K ? 1 : 0
                        })
                    } else {
                        let storeIndex = data.findIndex((item: TableData) => item.store === store);
                        data[storeIndex] = {
                            ...data[storeIndex],
                            styleCount: data[storeIndex].styleCount + 1,
                            quantity: data[storeIndex].quantity + element.sizeQuantities[index].reduce((total: number, current: { quantity: number }) => total + current.quantity, 0),
                            menQty: data[storeIndex].menQty + (element.description.startsWith('M ') ? 1 : 0),
                            womenQty: data[storeIndex].womenQty + (W ? 1 : 0),
                            kidsQty: data[storeIndex].kidsQty + (K ? 1 : 0),
                            otherQty: data[storeIndex].otherQty + (!M && !W && !K ? 1 : 0)
                        }
                    }
                });
            });
            setTableData(data);
        }
    }

    return (
        <div className={classes.main}>
                <h2 className={"title"} style={{ marginTop: '-5px' }}>PO Info</h2>
                <Select
                    data={poNoList}
                    placeholder="Select Po No"
                    style={{ flexGrow: 1, width: '100%' }}
                    searchable
                    clearable
                    onChange={handlePoNoChange}
                    onSearchChange={(e) => setPo({ ...po, poNo: e.toUpperCase() })}
                    onClear={() => setPo({ poId: '', poNo: '' })}
                />
            {tableData && (<div className={classes.table}>
                <Table withTableBorder withColumnBorders stickyHeader striped>
                    <Table.Thead className={classes.tableHead}>
                        <Table.Tr>
                            <Table.Th></Table.Th>
                            <Table.Th>Store</Table.Th>
                            <Table.Th>No of Styles</Table.Th>
                            <Table.Th>Mens</Table.Th>
                            <Table.Th>Women</Table.Th>
                            <Table.Th>Kids</Table.Th>
                            <Table.Th>Other</Table.Th>
                            <Table.Th>Total Quantity</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {tableData.map((item: any, index: number) => {
                            return (<Table.Tr key={item.store}>
                                <Table.Td>{index + 1}</Table.Td>
                                <Table.Td>{item.store}</Table.Td>
                                <Table.Td>{item.styleCount}</Table.Td>
                                <Table.Td>{item.menQty}</Table.Td>
                                <Table.Td>{item.womenQty}</Table.Td>
                                <Table.Td>{item.kidsQty}</Table.Td>
                                <Table.Td>{item.otherQty}</Table.Td>
                                <Table.Td>{item.quantity}</Table.Td>
                            </Table.Tr>)
                        })}
                    </Table.Tbody>
                </Table></div>)}
        </div>
    )
}

export default PoData;