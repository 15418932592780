import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Content, TDocumentDefinitions } from "pdfmake/interfaces";
import { StoreData } from "../interface/AllocationTable";
pdfMake.vfs = pdfFonts.vfs;

export const sizeAllocationPDF = async (sizes: string[], chunkSize: number, tableData: StoreData[], styleNo: string): Promise<void> => {
    const body: any[] = [];
    let portraitContent: Content[] = [];
    const chunkSizes = (sizes: string[], chunkSize: number): string[][] => {
        const chunks = [];
        for (let i = 0; i < sizes.length; i += chunkSize) {
          chunks.push(sizes.slice(i, i + chunkSize));
        }
        return chunks;
      };
      const sizeChunks = await chunkSizes(sizes,chunkSize)

    sizeChunks.forEach(chunk => {
        const gridPage = createThreeByThreeGrid(styleNo,chunk, tableData);
        portraitContent.push({
            pageOrientation: 'portrait',
            stack: [gridPage],
            style: 'sizeTable'
        });
    });

    const PortraitdocDefinition: TDocumentDefinitions = {
        pageSize: 'LETTER',
        pageOrientation: 'portrait',
        content: portraitContent,
        styles: {
            sizeTable: {
                fontSize: 10, // Adjust font size as needed
                margin: [5, 5, 5, 5]
            },
            tableHeader: {
                fillColor: '#f2f2f2', // light gray background
                bold: true,
                alignment: 'center',

            },
        }
    };


    // Create the PDF and open it in a new window
    const pdfDoc = pdfMake.createPdf(PortraitdocDefinition);
    pdfDoc.open();
};

const createThreeByThreeGrid = (styleNo: string,chunk: string[], allocationData: any): Content => {
    const tableLayout = {
        hLineWidth: () => 1,
        vLineWidth: () => 1,
        hLineColor: () => '#d0d0d0',
        vLineColor: () => '#d0d0d0',
        paddingLeft: () => 3,
        paddingRight: () => 3,
        paddingTop: () => 1,
        paddingBottom: () => 1,
    };
    let gridRows: Content[] = [];

    for (let rowIndex = 0; rowIndex < 2; rowIndex++) {
        let rowColumns: Content[] = [];
        for (let columnIndex = 0; columnIndex < 3; columnIndex++) {
            const sizeIndex = rowIndex * 3 + columnIndex;
            if (sizeIndex < chunk.length) {
                const sizeTable = createSizeSpecificTable(styleNo, chunk[sizeIndex], allocationData);
                rowColumns.push({
                    table: {
                        body: sizeTable,
                        widths: ['50%', 'auto'],
                    },
                    margin: [0, 0, 8, 15],
                    fontSize: 11,
                    layout: tableLayout
                });
            } else {
                rowColumns.push({ text: '' });
            }
        }
        gridRows.push({ columns: rowColumns, columnGap: 4 });

        // Add a dotted line after each row except the last one
        if (rowIndex < 2) {
            gridRows.push({
                canvas: [
                    {
                        type: 'line',
                        x1: 0, y1: 5,
                        x2: 520, y2: 6, // Adjust the length as needed
                        lineWidth: 1,
                        dash: { length: 4 },
                        lineColor: '#000000'
                    }
                ],
                margin: [0, 3, 0, 3] // Adjust margin as needed
            });
        }
    }


    return {
        unbreakable: true,
        stack: gridRows
    };
};

// Specific Size Table
const createSizeSpecificTable = (styleNo: string, size: string, tableData: StoreData[]): (string | number)[][] => {

    const headers = [`${styleNo}`, `Qty (Size: ${size})`];
    const styledHeader = headers.map(header => ({ text: header, style: 'tableHeader' }));

    const body: any[][] = [styledHeader];

    tableData.forEach(store => {
        const sizeQuantity = store.sizeQuantities.find(sq => sq.size === size);
        const quantity = sizeQuantity ? sizeQuantity.quantity.toString() : '0';
        body.push([store.storeName, quantity]);
    });

    return body;
};