import { Button, Modal, Text, TextInput } from '@mantine/core';
import { IconReport } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';


const BolReportDownload: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const token = useSelector((state: any) => state.user.sessionId);
    const scope = useSelector((state: any) => state.user.user.scope);
    const [modal, setModal] = useState<boolean>(false);
    const [year, setYear] = useState<number>(2025);
    const [carrier, setCarrier] = useState<string>('G2000');
    // Function to download the BOL report PDF
    const downloadBolReport = async (year: number, carrier: string) => {
        setLoading(true);
        let reportUrl = '/bol/getBolReport';
        if (scope === 'User') {
            reportUrl = '/bol/getCarrierBolReport';
        }
        try {
            // use fetch to get the PDF from the backend
            const response = await fetch(reportUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ year, carrier })
            });

            // Get the PDF Blob from the response
            const data = await response.blob();

            // Create a Blob URL for the PDF data
            const pdfUrl = window.URL.createObjectURL(data);

            // Open the PDF in a new tab
            window.open(pdfUrl, '_blank');

            // Optionally, clean up the object URL after opening the PDF
            window.URL.revokeObjectURL(pdfUrl);

        } catch (error) {
            console.error('Error downloading PDF:', error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div>
            <Modal opened={modal} onClose={() => setModal(false)} title="Download BOL Report">
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    {scope === 'User' ? (
                        <Text size='md' fw={500}>Carrier: {carrier}</Text>
                    ) : <TextInput label="Carrier" placeholder="Enter Carrier" value={carrier} onChange={(e) => setCarrier(e.target.value)} />}
                    <TextInput label="Year" placeholder="Enter Year" value={year} onChange={(e) => setYear(parseInt(e.target.value || '2025'))} />
                    <Button onClick={() => downloadBolReport(year, carrier)}>Download</Button>
                </div>
            </Modal>
            <IconReport className='icon' onClick={() => setModal(true)} />
        </div>
    );
};

export default BolReportDownload;
