import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Content, TDocumentDefinitions } from "pdfmake/interfaces";
import { StoreData } from "../interface/AllocationTable";
pdfMake.vfs = pdfFonts.vfs;

  
export const allocationPaperPDF = async (
    sizes: any,
    tableData: StoreData[],
    totalProductionQuantities: number[],
    totalProductionSum: number,
    allocation: any,
    poNo:string
) => {
    const body: any[] = [];
    const { styleNo, cost, msrp, supplierName, binLocation, color, description } = allocation;

    const header = [
        { text: 'Store', style: 'tableHeader' },
        ...sizes.map((size: any) => ({ text: size, style: 'tableHeader' })),
        { text: 'Total', style: 'tableHeader' },
        { text: 'INITIALS', style: 'tableHeader' }
    ]
    body.push(header);

    const totalProductionQty = ['RCV QTY', ...sizes.map((_: any, index: number) => totalProductionQuantities[index]), totalProductionSum, ''];
    body.push(totalProductionQty);

    const calculateRowTotal = (rowIndex: number) => {
        return tableData[rowIndex].sizeQuantities.reduce((total, sizeData) => total + sizeData.quantity, 0);
    };

    const calculateTotalSizeQuantitiesSum = (): number => {
        return tableData.reduce((total, _rowData, rowIndex) => total + calculateRowTotal(rowIndex), 0);
    };


    const calculateTotalAllocation = (sizeIndex: number) => {
        return tableData.reduce(
            (total, row) => {
                // Check if row and row.sizeQuantities are not null
                if (row && row.sizeQuantities) {
                    // Check if sizeIndex is within the bounds of the sizeQuantities array
                    if (sizeIndex < row.sizeQuantities.length) {
                        return total + row.sizeQuantities[sizeIndex].quantity;
                    }
                }
                return total;
            },
            0
        );
    };

    const calculateOverstock = (sizeIndex: number) => {
        const totalAllocation = calculateTotalAllocation(sizeIndex);
        const totalProductionQuantity = totalProductionQuantities[sizeIndex] || 0;
        const result = Math.max(totalProductionQuantity - totalAllocation);
        return isNaN(result) ? 0 : result;
    };


    // Add your table data here, looping through tableData, for example
    tableData.forEach((row: StoreData, index: number) => {
        const rowData = [
            row.storeName,
            ...row.sizeQuantities.map(sq => sq.quantity),
            calculateRowTotal(index),
            ''
        ];
        body.push(rowData);
    });
    // Add total allocation row
    const totalAllocationRow = ['Total Allocation', ...sizes.map((_: any, index: number) => calculateTotalAllocation(index)), calculateTotalSizeQuantitiesSum(), 0];
    body.push(totalAllocationRow);

    // Add overstock row
    const overstockRow = ['Overstock', ...sizes.map((_: any, index: number) => calculateOverstock(index)), Math.max(totalProductionSum - calculateTotalSizeQuantitiesSum()), 0];
    body.push(overstockRow);




    let Landscapecontent: Content[] = [
        {

            stack: [
                {
                    text: 'SVP SPORTS - DISTRIBUTION CENTER',
                    fontSize: 24,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 0, 0, 10]
                },
                { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 700, y2: 5, lineWidth: 0.5, lineColor: '#d0d0d0' }] },
                {
                    columns: [
                        { text: `#PO: ${poNo}`, style: 'header' },
                        { text: `Supplier: ${supplierName}`, style: 'header' },
                    ],
                    columnGap: 10,
                },
                { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 700, y2: 5, lineWidth: 0.5, lineColor: '#d0d0d0' }] },
                {
                    columns: [
                        { text: `StyleNo: ${styleNo}`, style: 'header' },
                    ],
                    columnGap: 10,
                },
                {
                    columns: [
                        { text: `Description: ${description}`, style: 'subheader' },
                        { text: `Bin: ${binLocation}`, style: 'subheader' },

                    ],
                    columnGap: 8,
                },
                { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 700, y2: 5, lineWidth: 0.5, lineColor: '#d0d0d0' }] },
                {
                    columns: [
                        { text: `Color: ${color}`, style: 'subheader' },
                        { text: `Cost: ${cost}`, style: 'subheader' },
                        { text: `MSRP: ${msrp}`, style: 'subheader' },
                    ],
                    columnGap: 5,
                },
                { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 700, y2: 5, lineWidth: 0.5, lineColor: '#d0d0d0' }] },
            ],
        },
        {
            columns: [
                { width: '*', text: '' },
                {
                    width: 'auto',
                    alignment: 'center',
                    table: {
                        body: body,
                        widths: ['auto', ...new Array(sizes.length).fill('*'), 'auto', 'auto'],
                    },
                    layout: {
                        hLineWidth: () => 1,
                        vLineWidth: () => 1,
                        hLineColor: () => '#d0d0d0',
                        vLineColor: () => '#d0d0d0',
                        paddingLeft: () => 1,
                        paddingRight: () => 1,
                        paddingTop: () => 1,
                        paddingBottom: () => 1,
                    },
                    style: {
                        fontSize: 10
                    }
                },
                { width: '*', text: '' }
            ],
            columnGap: 10
        }
    ];

    const LandscapedocDefinition: TDocumentDefinitions = {
        pageSize: 'LETTER',
        pageOrientation: 'landscape',
        content: Landscapecontent, // Assign the prepared content here
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                alignment: 'center',
                margin: [0, 0, 0, 10]
            },
            subheader: {
                fontSize: 14,
                bold: true,
                alignment: 'center',
                margin: [0, 10, 0, 5]
            },
            subheader1: {
                fontSize: 14,
                bold: true,
                alignment: 'center',
                margin: [0, 10, 0, 35]
            },
            tableHeader: {
                fillColor: '#f2f2f2', // light gray background
                bold: true,
                alignment: 'center',
                margin: [2, 2, 2, 2]
            },
        }
    };

    

    const pdfDoc = pdfMake.createPdf(LandscapedocDefinition);
    pdfDoc.open();
}

