import { Button, Grid, PasswordInput, Select, TextInput } from "@mantine/core";
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { IRegisterUser } from "../../interface/User";
import '../../pages/Global.css';
import classes from './RegistrationForm.module.css';
import { roleHierarchy } from "../../Utils/getAccess";

interface RegistrationFormProps {
    email?: string;
    location?: string;
    scope?: 'User' | 'Admin' | 'SuperAdmin';
    first_name?: string;
    last_name?: string;
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({ email, location, first_name, last_name, scope }) => {
    const token = useSelector((state: any) => state.user.sessionId);
    const initialState: IRegisterUser = {
        email: '',
        password: '',
        confirmPassword: '',
        location: 'DC-Caledonia',
        scope: 'Admin',
        first_name: '',
        last_name: ''
    };
    const scopeOptions = Array.from(Object.keys(roleHierarchy))
    useEffect(() => {
        if (email) setForm({ ...form, email: email });
        if (location) setForm({ ...form, location: location });
        if (first_name) setForm({ ...form, first_name: first_name });
        if (last_name) setForm({ ...form, last_name: last_name });
        if (scope) setForm({ ...form, scope: scope });
    }, [])
    const [form, setForm] = useState<IRegisterUser>(initialState);

    const validateForm = async () => {
        if (!form.email || !form.first_name || !form.last_name || !form.password || !form.confirmPassword || !form.location || !form.scope)
            return { status: false, message: 'Fill in all the fields.' };
        if (form.password !== form.confirmPassword)
            return { status: false, message: 'Passwords do not match.' };
        // match regular expression for email
        if (!form.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/))
            return { status: false, message: 'Invalid email.' };

        return { status: true, message: '' };
    }

    const handleSubmit = async () => {
        const valid: { status: boolean, message: string } = await validateForm();

        if (!valid.status) {
            alert(valid.message);
            return;
        }
        try {
            const response = await fetch('auth/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ ...form })
            });
            if (response.status === 400) {
                alert('User already exists.');
                return;
            }
            if (response.status === 200) {
                alert('User registered successfully.');
                setForm(initialState);
                return;
            }
            alert(`Error registering user:${form.first_name}`);
        } catch (error) {
            alert('Error registering user.');
        }
    }

    return (
        <div className="main">
            <h2 className="title">Register</h2>
            <div className={classes.container}>
                <Grid className={classes.mainGrid}>
                    <Grid.Col span={12}>
                        <TextInput
                            label='Email'
                            type="email"
                            required
                            value={form.email}
                            placeholder="Enter your email..."
                            onChange={(e) => setForm({ ...form, email: e.target.value || '' })}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ sm: 12, md: 6 }}>
                        <TextInput
                            label='First name'
                            required
                            value={form.first_name}
                            placeholder="Enter your first name..."
                            onChange={(e) => setForm({ ...form, first_name: e.target.value || '' })}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ sm: 12, md: 6 }}>
                        <TextInput
                            label='Last name'
                            required
                            value={form.last_name}
                            placeholder="Enter your last name..."
                            onChange={(e) => setForm({ ...form, last_name: e.target.value || '' })}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ sm: 12, md: 6 }}>
                        <Select
                            label='Location'
                            data={['DC-Caledonia', 'DC']}
                            defaultValue={'DC-Caledonia'}
                            onChange={(value) => {
                                if (value === 'DC-Caledonia' || value === 'DC') {
                                    setForm({ ...form, location: value });
                                } else {
                                    console.error(`Invalid scope value: ${value}`);
                                }
                            }}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ sm: 12, md: 6 }}>
                        <Select
                            label='Scope'
                            data={scopeOptions}
                            defaultValue={'Admin'}
                            onChange={(value) => {
                                if (value === 'User' || value === 'Admin'|| value === 'Hr' || value === 'SuperAdmin') {
                                    setForm({ ...form, scope: value });
                                } else {
                                    console.error(`Invalid scope value: ${value}`);
                                }
                            }}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ sm: 12, md: 6 }}>
                        <PasswordInput
                            label='Password'
                            type="password"
                            value={form.password}
                            placeholder="Enter your Password..."
                            onChange={(e) => setForm({ ...form, password: e.target.value || '' })}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ sm: 12, md: 6 }}>
                        <PasswordInput
                            label='Confrim Password'
                            type="password"
                            value={form.confirmPassword}
                            placeholder="Enter your password again..."
                            onChange={(e) => setForm({ ...form, confirmPassword: e.target.value || '' })}
                        />
                    </Grid.Col>
                </Grid>
                <div className={classes.buttonContainer}>
                    <Button className={classes.button} onClick={handleSubmit}> Register</Button>
                </div>
            </div>
        </div>
    )
}

export default RegistrationForm;