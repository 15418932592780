import { Button, FileInput, Grid, Group, Image, Input, Modal, Select, Table, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconArrowLeft, IconArrowRight, IconEdit, IconFilter, IconPlus, IconTrash, IconUpload } from '@tabler/icons-react';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import NavBar from '../../components/NavBar/NavBar';
import BolPrint from '../../components/Print/BolPrint';
import BolReportDownload from '../../components/Print/BolReport';
import { BolData } from '../../interface/BillOfLading';
import { getShippingDetails } from '../../Utils/utils';
import classes from './BillOfLading.module.css';


const BillOfLading: React.FC = () => {
    const [bolList, setBolList] = useState([] as any[]);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        total: 1
    });
    const theme = useMantineTheme();
    const token = useSelector((state: any) => state.user.sessionId);
    const [viewModal, setViewModal] = useState(false);
    const [newBol, setNewBol] = useState({} as any);
    const initialValues: BolData = {
        id: '',
        dclocation: '',
        third_party: null,
        bol_type: '',
        shipper: {
            name: '',
            address: '',
            postal_code: '',
            sid_no: ''
        },
        receiver: {
            name: '',
            address: '',
            postal_code: '',
            cid_no: ''
        },
        po: [],
        bol_no: '',
        amount: null,
        type: '',
        status: false,
        payment_status: false,
        trailer: '',
        supplier: '',
        bol_date: '',
        note: '',
        description: '',
        invoice_no: '',
        invoice_file: '',
        created_at: '',
        updated_at: ''
    }
    const [bol, setBol] = useState({} as BolData);
    const [openModal, setOpenModal] = useState(false);
    const columns = useMemo(() => [
        {
            accessorKey: 'index',
            header: 'No.'
        },
        {
            accessorKey: 'shipper',
            header: 'Shipper'
        },
        {
            accessorKey: 'receiver',
            header: 'Receiver'
        },
        {
            accessorKey: 'po_no',
            header: 'PO No.'
        },
        {
            accessorKey: 'bol_date',
            header: 'Bol Date',
            width: '100px'
        },
        {
            accessorKey: 'amount',
            header: 'Rate'
        },
        {
            accessorKey: 'type',
            header: 'Load/Unload'
        },
        {
            accessorKey: 'status',
            header: 'Status'
        },
        {
            accessorKey: 'bol_no',
            header: 'Bol No.'
        },
        {
            accessorKey: 'payment_status',
            header: 'Payment Status'
        },
        {
            accessorKey: 'trailer',
            header: 'Trailer Info.'
        },
        {
            accessorKey: 'invoice_no',
            header: 'Invoice'
        },
        {
            accessorKey: 'modify',
            header: 'Modify'
        }
    ], []);
    const isSmallScreen = useMediaQuery('(max-width: 668px)');
    const [filterModal, setFilterModal] = useState(false);
    const initialFilter = {
        shipper: '',
        receiver: '',
        po_no: '',
        bol_no: '',
        type: '',
        invoice_no:'',
        status: null as boolean | null,
        payment_status: null as boolean | null,
        trailer: '',
        supplier: '',
        start_date: '',
        end_date: ''
    }
    const [filter, setFilter] = useState(initialFilter);
    const [createModal, setCreateModal] = useState(false);
    const handleCloseCreateModal = () => {
        // createBol();
        setCreateModal(false);
        setNewBol({} as any);
    }
    const createBol = async () => {
        // CHECK NECESSARY FIELDS
        if (!newBol.delivery_type || newBol.delivery_type === undefined || !newBol.amount || !newBol.bol_date || !newBol.type || !newBol.trailer ||
            !newBol.payment_status || !newBol.status || newBol.po === undefined || !newBol.po) {
            if (newBol.delivery_type === 'external') {
                if (!newBol.supplier) {
                    alert('Please fill all the required fields');
                    return;
                }
            } else {
                if (!newBol.shipper.name || !newBol.receiver.name) {
                    alert('Please fill all the required fields');
                    return;
                }
            }
        } 
        try {
            const response = await fetch(`/bol/createBol`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    bol: { ...newBol }
                })
            }); 
            const data = await response.json();
            const createdBol = data.data;
            if (response.ok) {
                alert('Bol created successfully');
                setBolList([...bolList, createdBol]);
                // handleCloseCreateModal();
            } else {
                alert('Error in creating Bol');
            }
        } catch (error) {
            console.log('Error in createBol:', error);
            alert('Error in creating Bol');
        }
    }

    const getBolData = async (bol_no: string) => {
        try {
            const response = await fetch(`/bol/bolDetails`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    bol_no: bol_no
                })
            });
            const data = await response.json();
            setBol(data);
        } catch (error) {
            console.log('Error in getBolData:', error);
        }
    }

    const handleOpenModal = async (bol: BolData) => {
        // await getBolData(bol.bol_no);
        await setBol(bol);
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setBol(initialValues);
        setOpenModal(false);
    }
    const handleDataUpdate = (column: string, e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        setBol((prev) => ({ ...prev, [column]: value }));
    }

    const handleRowUpdate = (id: string, data: any) => {
        const updatedBolList: any = bolList.map((bol: any) => bol.id === id ? data : bol);
        setBolList(updatedBolList);
    }
    const handleUpdateBol = async () => {
        try {
            const response = await fetch(`/bol/updateBol`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ bol: bol })
            });
            const data = await response.json();
            if (response.ok) {
                handleCloseModal();
                handleRowUpdate(bol.id, data.data[0]);
                alert('Bol updated successfully');
            } else {
                alert('Error in updating Bol');
            }
        } catch (error) {
            console.log('Error in handleUpdateBol:', error);
        }
    }
    const handlePoUpdate = (type: string, index: number, e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let updatedPo = [...newBol.po];
        if (type === 'po_no') {
            updatedPo[index][type] = value;
        } else {
            updatedPo[index][type] = parseInt(value);
        }
        setNewBol({ ...newBol, po: updatedPo });
    }
    const handleExistingPoUpdate = (type: string, index: number, e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        // get the po from the index
        let existingPo = bol.po?.[index];
        if (!existingPo) return;
        if (type === 'po_no') {
            existingPo[type] = value;
        }
        if (type === 'skid') {
            existingPo[type] = parseInt(value);
        }
        let updatedPo = bol.po ?? [];
        updatedPo[index] = existingPo || { 'po_no': '', 'skid': 0 };
        setBol({ ...bol, po: updatedPo });
    }
    const handleReset = async () => {
        await setFilter({ ...initialFilter });
        // await getBolList();
    }

    useEffect(() => {
        getBolList(pagination.page, pagination.limit);
    }, [pagination.page]);

    const getBolList = async (page: number, limit: number) => {
        try {
            const response = await fetch(`/bol/getBolList`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    page: page,
                    limit: limit,
                    ...filter
                })
            });
            const data = await response.json();
            setBolList(data.data);
            setPagination({
                ...pagination,
                page: page,
                total: Math.ceil(data.totalRows / pagination.limit)
            });
        } catch (error) {
            console.log('Error in getBolList:', error);
        }
    }
    const handlePagination = (operation: string) => {
        if (operation === 'increase') {
            setPagination({ ...pagination, page: pagination.page === pagination.total ? pagination.page : pagination.page + 1 })
        } else {
            setPagination({ ...pagination, page: pagination.page === 1 ? pagination.page : pagination.page - 1 })
        }
    }
    const handleCreateBol = () => {
        createBol();
        // handleCloseCreateModal();
    }
    const handlePoQtyUpdate = (value: string) => {
        // check if value is a number and positive integer
        let poQty = parseInt(value);
        if (parseInt(value) < 0) {
            poQty = 0;
        }
        setNewBol({
            ...newBol,
            po: Array.from({ length: poQty < 0 ? 0 : poQty }, () => ({ po_no: '', skid: '' }))
        });
    }
    const handleFilter = () => {
        // setPagination({ ...pagination, page: 1 });
        getBolList(1, pagination.limit);
        setFilterModal(false);
    }
    const handleFilterChange = (column: string, value: string | boolean) => {
        setFilter({ ...filter, [column]: value });
    }
    const handleDeleteBol = async () => {
        const confirm = window.confirm('Are you sure you want to delete this Bol?');
        if (!confirm) return;
        try {
            const response = await fetch(`/bol/deleteBol`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ bol_no: bol.bol_no })
            });
            const data = await response.json();
            if (response.ok) {
                alert('Bol deleted successfully');
                setBolList(bolList.filter((bol: any) => bol.bol_no !== data.data[0].bol_no));
            } else {
                alert('Error in deleting Bol');
            }
        } catch (error) {
            console.log('Error in handleDeleteBol:', error);
        }
    }
    const handleReport = async () => {
        try {
            const response = await fetch(`/bol/getBolReport`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();

        } catch (error) {
            console.log('Error in handleReport:', error);
        }
    }
    const handleReceiverLocationChange = (value: string | null) => {
        if (!value || value === 'Other') return;
        const shippingDetails = getShippingDetails(value);
        setNewBol({ ...newBol, to: shippingDetails.company, note: shippingDetails.note, receiver: { ...bol.receiver, name: shippingDetails.name, address: shippingDetails.address, postal_code: shippingDetails.city } });
    }
    const handleShipperLocationChange = (value: string | null) => {
        if (!value || value === 'Other') return;
        const shippingDetails = getShippingDetails(value);
        setNewBol({ ...newBol, from: shippingDetails.company, shipper: { ...bol.shipper, name: shippingDetails.name, address: shippingDetails.address, postal_code: shippingDetails.city } });
    }

    const handleUploadFile = async (bol_no: string, files: File[]) => {
        // Handle file upload logic here
        const formData = new FormData();
        Array.from(files)?.forEach((file: File) => {
            formData.append("files", file);
            formData.append("bol_no", bol_no);
        });

        if (!formData) {
            alert('Error uploading file');
            return;
        }
        try {
            const response = await fetch(`/bol/uploadImage`, {
                method: 'POST',
                headers: {
                    // 'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });
            const data = await response.json();
            if (response.status !== 200) {
                alert('Error uploading file');
            }
            alert('File uploaded successfully');
        } catch (error) {
            console.error(error);
        }
    }
    const handleViewInvoice = async (invoice_file: string) => {
        await setBol((prevBol) => ({
            ...prevBol, // spread the existing data
            invoice_file: invoice_file, // update the invoice_file field
        }));
        setViewModal(true);
    }
    const handleDownloadInvoice = async (invoice_file: string | null) => {
        if(!invoice_file) return;
        fetch(`/Invoice_BOL/${invoice_file}`)
        .then((response) => {
            if (!response.ok) {
                alert('File not found');
                throw new Error('File not found');
            }
            return response.blob(); // Get the blob of the file
        })
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', invoice_file); // Set the filename for download
            document.body.appendChild(link);
            link.click(); // Trigger the download
            link.remove(); // Cleanup
        })
        .catch((error) => {
            console.error('Error downloading file:', error);
        });
    }
    const handleFileChange = (bol_no: string, files: File[] | null) => {
        if (files) {
            handleUploadFile(bol_no, files);
        }
    };
    return (
        <div>
            {/* Create Modal */}
            <Modal opened={createModal} onClose={handleCloseCreateModal} title="Create" size={'xl'}>
                <Grid>
                    <Grid.Col span={12} className={classes.gridSection}>
                        <Select
                            // label="Delivery type"
                            variant='unstyled'
                            className={classes.label}
                            styles={{
                                input: {
                                    fontSize: '18px',
                                    fontWeight: '500',
                                    textAlign: 'center'
                                }
                            }}
                            placeholder="Select INTERNAL/EXTERNAL ..."
                            data={[{ label: 'Internal', value: 'internal' }, { label: 'External', value: 'external' }]}
                            value={newBol.delivery_type}
                            onChange={(value) => setNewBol({ ...newBol, delivery_type: value })} />
                        {newBol.delivery_type === 'external' && (<Grid.Col span={{ base: 12, xs: 6 }}>
                            <TextInput placeholder='Supplier' label='Supplier' value={newBol.supplier} onChange={(e) => setNewBol({ ...newBol, supplier: e.target.value })} />
                        </Grid.Col>)}
                        {/* <Grid>
                            {newBol.delivery_type === 'internal' && (<Grid.Col span={{ base: 12, xs: 6 }}>
                                <TextInput placeholder='Shipper' label='Shipper' value={newBol.from} onChange={(e) => setNewBol({ ...newBol, from: e.target.value })} />
                            </Grid.Col>)}
                            {newBol.delivery_type === 'internal' && (<Grid.Col span={{ base: 12, xs: 6 }}>
                                <TextInput placeholder='Receiver' label='Receiver' value={newBol.to} onChange={(e) => setNewBol({ ...newBol, to: e.target.value })} />
                            </Grid.Col>)}
                        </Grid> */}
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, xs: 6 }} className={classes.gridSection}>
                        <Grid className={classes.titleBox}>
                            <Grid.Col span={5}>
                                <Text className={classes.titleBoxText}>Shipper</Text>
                            </Grid.Col>
                            <Grid.Col span={7}>
                                <Select
                                    className={classes.titleBoxElement}
                                    variant='unstyled'
                                    placeholder='Select Location'
                                    data={[{ label: 'DC-Caledonia', value: 'DC-Caledonia' },
                                    { label: 'DC', value: 'DC' },
                                    { label: 'OPM-Aurora', value: 'OPM-Aurora' },
                                    { label: 'SVP-Queensway', value: 'SVP-Queensway' },
                                    { label: 'SVP-Kingston', value: 'SVP-Kingston' },
                                    { label: 'Other', value: 'Other' }
                                    ]}
                                    onChange={(value) => handleShipperLocationChange(value)}
                                />
                            </Grid.Col>
                        </Grid>
                        <TextInput
                            label='Name'
                            placeholder='Enter Shipper'
                            value={newBol.shipper?.name || ''}
                            onChange={(e) => setNewBol({
                                ...newBol,
                                shipper: { ...newBol.shipper, name: e.target.value }
                            })}
                        />
                        <TextInput
                            label='Address'
                            placeholder='Enter Shipper Address'
                            value={newBol.shipper?.address || ''}
                            onChange={(e) => setNewBol({
                                ...newBol,
                                shipper: { ...newBol.shipper, address: e.target.value }
                            })}
                        />
                        <TextInput
                            label='Postal Code'
                            placeholder='Enter Shipper Postal Code'
                            value={newBol.shipper?.postal_code || ''}
                            onChange={(e) => setNewBol({
                                ...newBol,
                                shipper: { ...newBol.shipper, postal_code: e.target.value }
                            })}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, xs: 6 }} className={classes.gridSection}>
                        <Grid className={classes.titleBox}>
                            <Grid.Col span={5}>
                                <Text className={classes.titleBoxText}>Receiver</Text>
                            </Grid.Col>
                            <Grid.Col span={7}>
                                <Select
                                    className={classes.titleBoxElement}
                                    variant='unstyled'
                                    placeholder='Select Location'
                                    data={[{ label: 'DC-Caledonia', value: 'DC-Caledonia' },
                                    { label: 'DC', value: 'DC' },
                                    { label: 'OPM-Aurora', value: 'OPM-Aurora' },
                                    { label: 'SVP-Queensway', value: 'SVP-Queensway' },
                                    { label: 'SVP-Kingston', value: 'SVP-Kingston' },
                                    { label: 'Other', value: 'Other' }
                                    ]}
                                    onChange={(value) => handleReceiverLocationChange(value)}
                                />
                            </Grid.Col>
                        </Grid>
                        <TextInput
                            label='Name'
                            placeholder='Enter Receiver'
                            value={newBol.receiver?.name || ''}
                            onChange={(e) => setNewBol({ ...newBol, receiver: { ...newBol.receiver, name: e.target.value } })}
                        />
                        <TextInput
                            label='Address'
                            placeholder='Enter Receiver Address'
                            value={newBol.receiver?.address || ''}
                            onChange={(e) => setNewBol({ ...newBol, receiver: { ...newBol.receiver, address: e.target.value } })}
                        />
                        <TextInput
                            label='Postal Code'
                            placeholder='Enter Receiver Postal Code'
                            value={newBol.receiver?.postal_code || ''}
                            onChange={(e) => setNewBol({ ...newBol, receiver: { ...newBol.receiver, postal_code: e.target.value } })}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, xs: 6 }} className={classes.gridSection}>
                        <TextInput label='Amount' placeholder='Enter Amount' value={newBol.amount} onChange={(e) => setNewBol({ ...newBol, amount: e.target.value })} />
                        <Select label='Payment Status' placeholder='Select Payment Status' data={[{ label: 'Unpaid', value: 'false' }, { label: 'Paid', value: 'true' }]} value={newBol.payment_status} onChange={(value) => setNewBol({ ...newBol, payment_status: value })} />
                        <TextInput type='date' label='Bol Date' placeholder='Enter Bol Date' value={newBol.bol_date} onChange={(e) => setNewBol({ ...newBol, bol_date: e.target.value })} />
                        <Select label='Status' placeholder='Select Status' data={[{ label: 'Pending', value: 'false' }, { label: 'Done', value: 'true' }]} value={newBol.status} onChange={(value) => setNewBol({ ...newBol, status: value })} />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, xs: 6 }} className={classes.gridSection}>
                        <TextInput label='Carrier' placeholder='Enter carrier' value={newBol.trailer} onChange={(e) => setNewBol({ ...newBol, trailer: e.target.value })} />
                        <TextInput label='Load/Unload' placeholder='Enter Type load/unload' value={newBol.type} onChange={(e) => setNewBol({ ...newBol, type: e.target.value })} />
                        <TextInput label='Description' placeholder='Enter Description' value={newBol.description} onChange={(e) => setNewBol({ ...newBol, description: e.target.value })} />
                        <TextInput label='Special instruction' placeholder='Enter Note' value={newBol.note} onChange={(e) => setNewBol({ ...newBol, note: e.target.value })} />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, xs: 6 }} className={classes.gridSection}>
                        <Grid className={classes.titleBox}>
                            <Grid.Col span={5}>
                                <Text className={classes.titleBoxText}>PO No.</Text>
                            </Grid.Col>
                            <Grid.Col span={7}>
                                <TextInput placeholder='Enter Qty' style={{ width: '30%' }} variant='unstyled' type='number' value={newBol.po_qty} onChange={(e) => handlePoQtyUpdate(e.target.value)} />
                            </Grid.Col>
                        </Grid>
                        {newBol?.po?.map((po: any, index: number) => (
                            <div key={index} className='rowDiv' style={{ gap: '10px', alignItems: 'center', borderRadius: '5px' }}>
                                <Text>{index + 1}.</Text>
                                <TextInput key={index} placeholder='PO No.' value={newBol.po[index]?.po_no || ''} onChange={(e) => handlePoUpdate('po_no', index, e)} />
                                <TextInput key={index} placeholder='Skid' type='number' value={newBol.po[index]?.skid || ''} onChange={(e) => handlePoUpdate('skid', index, e)} />
                            </div>
                        ))}
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, xs: 6 }} className={classes.gridSection}>
                        <Text className={classes.titleBoxText} style={{ borderRadius: '10px' }}>Third Party</Text>
                        <TextInput label='Name' placeholder='Enter Third Party' value={newBol.third_party?.name || ''} onChange={(e) => setNewBol({ ...newBol, third_party: { ...newBol.third_party, name: e.target.value } })} />
                        <TextInput label='Address' placeholder='Enter Address' value={newBol.third_party?.address || ''} onChange={(e) => setNewBol({ ...newBol, third_party: { ...newBol.third_party, address: e.target.value } })} />
                        <TextInput label='Postal Code' placeholder='Enter Postal Code' value={newBol.third_party?.postal_code || ''} onChange={(e) => setNewBol({ ...newBol, third_party: { ...newBol.third_party, postal_code: e.target.value } })} />
                    </Grid.Col>
                </Grid>
                <div className={classes.buttonDiv} style={{ marginTop: '20px' }}>
                    <Button onClick={handleCreateBol}>Create</Button>
                    <Button variant="outline" onClick={handleCloseCreateModal}>Cancel</Button>
                </div>
            </Modal>
            {/* Modify Modal*/}
            <Modal opened={openModal} onClose={handleCloseModal} title={bol.bol_no} size={'xl'}>
                {/* {bol && ( */}
                <Grid>
                    <Grid.Col span={6} className={classes.gridSection}>
                        <Text className={classes.label}>Shipper</Text>
                        <TextInput
                            label="Name"
                            placeholder="Enter Shipper"
                            value={bol.shipper?.name}
                            onChange={(e) => {
                                setBol((prev) => ({
                                    ...prev,
                                    shipper: {
                                        ...prev.shipper,
                                        name: e.target.value
                                    }
                                }));
                            }}
                        />
                        <TextInput
                            label="Address"
                            placeholder="Enter Shipper Address"
                            value={bol.shipper?.address || ''}
                            onChange={(e) => {
                                // Update only the specific part of the `shipper` object
                                setBol((prev) => ({
                                    ...prev,
                                    shipper: {
                                        ...prev.shipper,
                                        address: e.target.value
                                    }
                                }));
                            }}
                        />
                        <TextInput
                            label="Postal Code"
                            placeholder="Enter Shipper Postal Code"
                            value={bol.shipper?.postal_code || ''}
                            onChange={(e) => {
                                // Update only the specific part of the `shipper` object
                                setBol((prev) => ({
                                    ...prev,
                                    shipper: {
                                        ...prev.shipper,
                                        postal_code: e.target.value
                                    }
                                }));
                            }}
                        />
                    </Grid.Col>

                    <Grid.Col span={6} className={classes.gridSection}>
                        <Text className={classes.label}>Receiver</Text>
                        <TextInput label="Name" placeholder="Enter Receiver" value={bol.receiver?.name || ''}
                            onChange={(e) => setBol({ ...bol, receiver: { ...bol.receiver, name: e.target.value } })} />
                        <TextInput label="Address" placeholder="Enter Receiver Address" value={bol.receiver?.address || ''}
                            onChange={(e) => setBol({ ...bol, receiver: { ...bol.receiver, address: e.target.value } })} />
                        <TextInput label="Postal Code" placeholder="Enter Receiver Postal Code" value={bol.receiver?.postal_code || ''}
                            onChange={(e) => setBol({ ...bol, receiver: { ...bol.receiver, postal_code: e.target.value } })} />
                    </Grid.Col>
                    {bol.third_party && (<Grid.Col span={12} className={classes.gridSection}>
                        <Text className={classes.label}>Third Party</Text>
                        <TextInput label="Name" placeholder="Enter Third Party" value={bol.third_party?.name || ''}
                            onChange={(e) => setBol({ ...bol, third_party: { ...bol.third_party, name: e.target.value } })} />
                        <TextInput label="Address" placeholder="Enter Address" value={bol.third_party?.address || ''}
                            onChange={(e) => setBol({ ...bol, third_party: { ...bol.third_party, address: e.target.value } })} />
                        <TextInput label="Postal Code" placeholder="Enter Postal Code" value={bol.third_party?.postal_code || ''}
                            onChange={(e) => setBol({ ...bol, third_party: { ...bol.third_party, postal_code: e.target.value } })} />
                    </Grid.Col>)}
                    <Grid.Col span={6} className={classes.gridSection}>
                        <Text className={classes.label}>Carrier</Text>
                        <TextInput label="Carrier" placeholder="Enter Carrier" value={bol.trailer || ''}
                            onChange={(e) => handleDataUpdate('trailer', e)} />
                        <TextInput type='number' label="Amount" placeholder="Enter Amount" value={bol.amount || ''} onChange={(e) => handleDataUpdate('amount', e)} />
                        <TextInput label="Load/unload" placeholder="Enter Type Load/unload" value={bol.type || ''} onChange={(e) => handleDataUpdate('type', e)} />
                    </Grid.Col>
                    <Grid.Col span={6} className={classes.gridSection}>
                        <Text className={classes.label}>Status</Text>
                        <Select
                            label="Status"
                            placeholder="Select Status"
                            data={[
                                { label: 'Pending', value: 'false' },
                                { label: 'Done', value: 'true' }
                            ]}
                            value={bol.status?.toString()}
                            onChange={(value) => setBol({ ...bol, status: value === 'true' })}
                        />
                        <Select
                            label="Payment Status"
                            placeholder="Select Payment Status"
                            data={[
                                { label: 'Unpaid', value: 'false' },
                                { label: 'Paid', value: 'true' }
                            ]}
                            value={bol.payment_status?.toString()}
                            onChange={(value) => setBol({ ...bol, payment_status: value === 'true' })}
                        />
                        <TextInput label="Invoice No." placeholder="Enter Invoice No." value={bol.invoice_no || ''} onChange={(e) => setBol({ ...bol, invoice_no: e.target.value })} />
                    </Grid.Col>
                    <Grid.Col span={12} className={classes.gridSection}>
                        <Text className={classes.label}>PO No.</Text>
                        <div className='rowDiv' style={{ gap: '10px', alignItems: 'center', borderRadius: '5px' }}>
                            {bol.po?.map((item: any, index: number) =>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'center', borderRadius: '5px', border: '1px solid black' }}>
                                    <TextInput variant='unstyled' placeholder='PO No.' value={item.po_no} onChange={(e) => handleExistingPoUpdate('po_no', index, e)} />
                                    <IconArrowRight />
                                    <TextInput variant='unstyled' placeholder='Skid' value={item.skid} onChange={(e) => handleExistingPoUpdate('skid', index, e)} />
                                </div>
                            )}
                        </div>
                    </Grid.Col>
                    <Grid.Col span={12} className={classes.gridSection}>
                        <Text className={classes.label}>Details</Text>
                        <TextInput
                            label='Supplier'
                            required
                            value={bol.supplier}
                            placeholder="Enter your supplier..."
                            onChange={(e) => setBol({ ...bol, supplier: e.target.value || '' })}
                        />
                        <TextInput type='date' label="Bol Date" placeholder="Enter Bol Date" value={bol.bol_date?.split('T')[0] || ''} onChange={(e) => handleDataUpdate('bol_date', e)} />
                        <TextInput label="Special instructions" placeholder="Enter Special instructions" value={bol.note || ''}
                            onChange={(e) => setBol({ ...bol, note: e.target.value })} />
                        <TextInput label="Description" placeholder="Enter Description" value={bol.description || ''} onChange={(e) => handleDataUpdate('description', e)} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <div className={classes.buttonDiv}>
                            <Button onClick={() => handleUpdateBol()}>{'Update'}</Button>
                            <BolPrint bol={bol} />
                            <FileInput
                                // type="file"
                                multiple
                                onChange={(value) => handleFileChange(bol.bol_no, value)}
                                className={classes.fileInput}
                                placeholder={`Upload Invoice `}
                            // variant="unstyled"
                            />
                            <Button variant="outline" onClick={handleCloseModal}>Cancel</Button>
                            <Button variant="outline" color='red' onClick={() => handleDeleteBol()}><IconTrash /> Delete</Button>
                        </div>
                    </Grid.Col>
                </Grid>

            </Modal>
            {/* Filter Modal */}
            <Modal opened={filterModal} onClose={() => setFilterModal(false)} title="Filter" size={'xl'}>
                <Grid>
                    <Grid.Col span={{ base: 12, xs: 6 }}>
                        <TextInput label="Bol No." placeholder="Enter Bol No." value={filter.bol_no} onChange={(e) => handleFilterChange('bol_no', e.target.value)} />
                        <TextInput label="Shipper" placeholder="Enter Shipper" value={filter.shipper} onChange={(e) => handleFilterChange('shipper', e.target.value)} />
                        <TextInput label="Receiver" placeholder="Enter Receiver" value={filter.receiver} onChange={(e) => handleFilterChange('receiver', e.target.value)} />
                        <TextInput label="PO No." placeholder="Enter PO No." value={filter.po_no} onChange={(e) => handleFilterChange('po_no', e.target.value)} />
                        <TextInput label="Load/Unload" placeholder="Enter Type Load/Unload" value={filter.type} onChange={(e) => handleFilterChange('type', e.target.value)} />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, xs: 6 }}>
                    <TextInput label="Invoice No" placeholder="Enter Invoice no" value={filter.invoice_no} onChange={(e) => handleFilterChange('invoice_no', e.target.value)} />
                        <Select
                            label="Status"
                            placeholder="Select Status"
                            data={[
                                { label: 'Pending', value: 'false' },
                                { label: 'Done', value: 'true' }
                            ]}
                            value={filter.status?.toString()}
                            onChange={(value) => handleFilterChange('status', value === 'true')}
                        />
                        <Select
                            label="Payment Status"
                            placeholder="Select Payment Status"
                            data={[
                                { label: 'Unpaid', value: 'false' },
                                { label: 'Paid', value: 'true' }
                            ]}
                            value={filter.payment_status?.toString()}
                            onChange={(value) => handleFilterChange('payment_status', value === 'true')}
                        />
                        <TextInput label="Carrier" placeholder="Enter carrier" value={filter.trailer} onChange={(e) => handleFilterChange('trailer', e.target.value)} />
                        <TextInput label="Supplier" placeholder="Enter Supplier" value={filter.supplier} onChange={(e) => handleFilterChange('supplier', e.target.value)} />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, xs: 6 }}>
                        <TextInput label="Start Date" type='date' placeholder="Enter Start Date" value={filter.start_date} onChange={(e) => handleFilterChange('start_date', e.target.value.split('T')[0])} />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, xs: 6 }}>
                        <TextInput label="End Date" type='date' placeholder="Enter End Date" value={filter.end_date} onChange={(e) => handleFilterChange('end_date', e.target.value.split('T')[0])} />
                    </Grid.Col>
                </Grid>
                <div className={classes.buttonDiv} style={{ marginTop: '20px' }}>
                    <Button onClick={handleFilter}>Apply</Button>
                    <Button variant="outline" onClick={handleReset}>Reset</Button>
                    <Button variant="outline" onClick={() => setFilterModal(false)}>Cancel</Button>
                </div>
            </Modal>
            {/* View Modal */}
            <Modal opened={viewModal} onClose={() => setViewModal(false)} title="View Invoice" size={'xl'}>
                {bol?.invoice_file ? (
                    <div>
                        <div style={{ height: '70vh', overflow: 'auto' }}>
                            <Image
                                src={`/Invoice_BOL/${bol?.invoice_file}`}
                                alt={`${bol?.invoice_no}`}
                            />
                        </div>
                        <div className='rowDiv' style={{ justifyContent: 'center', gap: '20px', marginTop: '5px' }}>
                            <Button onClick={() => handleDownloadInvoice(bol?.invoice_file)}>Download</Button>
                            <Button onClick={() => setViewModal(false)} variant='outline'>Cancel</Button>
                        </div>

                    </div>) : (<div>
                        <Text fw={650} fz={18} style={{ textAlign: 'center', marginTop: '50px' }}>No file attached for the invoice.</Text>
                    </div>)}
            </Modal>
            <NavBar />
            <div className={'main'}>
                <h2>Bill of Lading</h2>
                <div className={classes.tableDiv}>
                    <Table highlightOnHover stickyHeader stripedColor={theme.colors.blue[0]}>
                        <Table.Thead className={'tableHead'}>
                            <Table.Tr>
                                {columns.map((column) => (
                                    <Table.Th key={column.accessorKey} style={{ width: column.width || 'auto' }}>{column.header}</Table.Th>
                                ))}
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {bolList?.map((bol: any, index: number) => (
                                // <Table.Tr key={bol.id} style={{ backgroundColor: bol.status && bol.payment_status ? '#89ab92' : undefined }}>
                                <Table.Tr key={bol.id} style={{ backgroundColor: bol.status && bol.payment_status ? '#008000' : undefined }}>
                                    <Table.Td>{index + 1 + (pagination.page - 1) * pagination.limit}</Table.Td>
                                    <Table.Td>{bol.shipper?.name}</Table.Td>
                                    <Table.Td>{bol.receiver?.name}</Table.Td>
                                    <Table.Td>{bol.po?.map((po: any) => po.po_no).join('/ ')}</Table.Td>
                                    <Table.Td>{bol.bol_date?.split('T')[0]}</Table.Td>
                                    <Table.Td>{bol?.amount}</Table.Td>
                                    <Table.Td>{bol?.type}</Table.Td>
                                    <Table.Td>{bol?.status ? 'Done' : 'Pending'}</Table.Td>
                                    <Table.Td style={{ fontWeight: 'bold' }}>{bol?.bol_no}</Table.Td>
                                    <Table.Td>{bol?.payment_status ? 'Paid' : 'Unpaid'}</Table.Td>
                                    <Table.Td>{bol?.trailer}</Table.Td>
                                    <Table.Td
                                        onClick={() => handleViewInvoice(bol.invoice_file)}>
                                            <div className={classes.invoice}>
                                        {bol?.invoice_no || 'N/A'}
                                            </div>
                                    </Table.Td>
                                    <Table.Td><IconEdit onClick={() => handleOpenModal(bol)} className={'icon'} /></Table.Td>
                                </Table.Tr>
                            ))}
                        </Table.Tbody>
                    </Table>
                </div>
                <div className={'footerDiv'}>
                    <Grid style={{ width: '100%' }}>
                        {!isSmallScreen && (<Grid.Col span={{ base: 12, xs: 4 }}>
                            <BolReportDownload />
                            {/* <IconReport className='icon' onClick={() => handleReport()} /> */}
                        </Grid.Col>)}
                        <Grid.Col span={isSmallScreen ? 12 : 4}>
                            <Group justify='center'>
                                {isSmallScreen && <BolReportDownload />}
                                <div className={'paginationGroup'}>
                                    <Button disabled={pagination.page === 1} className={'pagincationIcon'} size='xs' onClick={() => handlePagination('decrease')}><IconArrowLeft /></Button>
                                    <Text size='lg' fw={500}>{pagination.page}/{pagination.total || 1}</Text>
                                    <Button disabled={pagination.page === pagination.total} className={'pagincationIcon'} size='xs' onClick={() => handlePagination('increase')}><IconArrowRight /></Button>
                                    {isSmallScreen && (
                                        <div className='rowDiv' style={{ gap: '10px', justifyContent: 'flex-end' }}>
                                            <Button variant='outline' onClick={() => setFilterModal(true)}><IconFilter /></Button>
                                            <Button onClick={() => setCreateModal(true)}><IconPlus /></Button>
                                        </div>)}
                                </div>
                            </Group>
                        </Grid.Col>
                        {!isSmallScreen && (<Grid.Col span={{ base: 12, xs: 4 }}>
                            <div className='rowDiv' style={{ gap: '10px', justifyContent: 'flex-end' }}>
                                <Button variant='outline' onClick={() => setFilterModal(true)}><IconFilter /> Filter</Button>
                                <Button onClick={() => setCreateModal(true)}><IconPlus /> Add</Button>
                            </div>
                        </Grid.Col>)}
                    </Grid>
                </div>
            </div>
        </div >
    )
}

export default BillOfLading;