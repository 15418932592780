export async function saveFloorData(token:string, allocation:any ) {
    try {
        const response = await fetch('/allocation/save',{
            method:'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({...allocation}),
        })
        if(response.status === 200){
            const data = await response.json();
            return data;
        }
        return null;
    } catch (error) {
        console.log('Error saving floor data.')
    }
}

export async function updateAllocation(token:string, allocation:any ) {
    try {
        const response = await fetch('/allocation/update',{
            method:'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({...allocation}),
        })
        if(response.status === 200){
            const data = await response.json();
            return data;
        }
        return null;
    } catch (error) {
        console.log('Error saving floor data.')
    }
}