import { Table } from '@mantine/core';
import React from 'react';
import classes from './PoTable.module.css';

interface PoTableProps {
    data: any
}

const PoTable: React.FC<PoTableProps> = ({ data }) => {
    return (
        <div>
            {data && (<Table withColumnBorders stickyHeader striped>
                <Table.Thead className={classes.tableHead}>
                    <Table.Tr>
                        <Table.Th>Style No</Table.Th>
                        <Table.Th>Status</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody className={classes.tableBody}>
                    {data.map((item: any) => {
                        return(<Table.Tr>
                            <Table.Td>{item.styleNo}</Table.Td>
                            <Table.Td>{item.status}</Table.Td>
                        </Table.Tr>)
                    })}
                </Table.Tbody>
            </Table>)}
        </div>
    )
}

export default PoTable;