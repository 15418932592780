import React from 'react';
import NavBar from '../../components/NavBar/NavBar';
import PurchaseOrders from '../../components/PurchaseOrdersList/PurchaseOrders';


const PoList: React.FC = () => {
    return (
        <div>
            <NavBar />
           <PurchaseOrders/>
        </div>
    )
}

export default PoList;