import { createTheme, MantineColorsTuple, MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import PrivateRoute from './config/PrivateRoute';
import AutomateTr from './pages/Admin/AutomateTr';
import ModifyAllocation from './pages/Admin/ModifyAllocation';
import Register from './pages/Admin/Register';
import UserRoleAccess from './pages/Admin/UserRoleAccess';
import AllocationCalculator from './pages/Calculator/Allocation';
import RestockCalculator from './pages/Calculator/Restock';
import Bin from './pages/Floor User/Bin';
import FloorUser from './pages/Floor User/FloorUser';
import HomePage from './pages/HomePage';
import PoList from './pages/Inventory Management/PoList';
import PoWorksheet from './pages/Inventory Management/PoWorksheet';
import StyleList from './pages/Inventory Management/StyleList';
import TransferList from './pages/Inventory Management/TransferList';
import LoginPage from './pages/Login';
import NotFound from './pages/NotFound';
import PrintLabel from './pages/Operations/PrintLabel';
import StyleAlloc from './pages/Operations/StyleAlloc';
import ProfilePage from './pages/ProfilePage';
import LogSheet from './pages/Sheets/LogSheet';
import Sheet from './pages/Sheets/Sheet';
import Stats from './pages/Hr/Stats';
import ManageUsers from './pages/Hr/ManageUsers';
import BillOfLading from './pages/Hr/BillOfLaing';
import BolList from './pages/User/BolList';



function App() {
  const myColor: MantineColorsTuple = [
    '#f1f4fe',
    '#e4e6ed',
    '#c8cad3',
    '#a9adb9',
    '#9094a3',
    '#7f8496',
    '#777c91',
    '#656a7e',
    '#595e72',
    '#4a5167'
  ];

  const theme = createTheme({
    colors: {
      gray: [
        '#E9E9E9',
        '#BAC3D0',
        '#A3B0C4',
        '#8B9DB7',
        '#516E98',
        '#456491',
        '#374C6D',
        '#2D5184',
        '#002558',
        '#011839',
        '#04162e',
        '#000000',
      ],
      // dark: ['#070F2B', '#0E1B4D', '#1A2B6C', '#243A8E', '#3144B0', '#4A5FC9', '#6B7FF2', '#C8D0F8', '#E8E9FF', '#8EA4F9'],
      // dark: [
      //   "#c8cad3",
      //   "#656a7e",
      //   "#595e72",
      //   "#777c91",
      //   "#9094a3",
      //   "#e4e6ed",
      //   "#a9adb9",
      //   "#7f8496",
      //   "#4a5167",
      //   "#f1f4fe",
      // ],
      light: [
        '#f7fbff',
        '#E0E0E0',
        '#BDBDBD',
        '#757575',
        '#616161',
        '#424242',
        '#212121',
        '#000000',
        '#FFFFFF',
        '#FAFAFA',
      ]

    },
    // primaryColor: 'dark',
    components: {
      Button: {
        defaultProps: {
          color: 'dark',
        },
      },
    },
  });


  return (
    <MantineProvider theme={theme}>
      <Router>
        <Routes>
          <Route path='/login' element={<PrivateRoute page={<LoginPage />} />} />
          <Route path='/' element={<PrivateRoute page={<HomePage />} />} />
          <Route path="/profile" element={<PrivateRoute page={<ProfilePage />} />} />

          {/*Calculator.......*/}
          <Route path="/allocation" element={<PrivateRoute page={<AllocationCalculator />} />} />
          <Route path="/restock" element={<PrivateRoute page={<RestockCalculator />} />} />

          {/*Floor user.......*/}
          <Route path="/floorUser" element={<PrivateRoute page={<FloorUser />} />} />
          <Route path="/bin" element={<PrivateRoute page={<Bin />} />} />

          {/*Sheets .......*/}
          <Route path="/logSheet" element={<PrivateRoute page={<LogSheet />} />} />
          <Route path="/sheet" element={<PrivateRoute page={<Sheet />} />} />

          {/*Operations.......*/}
          <Route path="/printLabel" element={<PrivateRoute page={<PrintLabel />} />} />
          <Route path="/styleAlloc" element={<PrivateRoute page={<StyleAlloc />} />} />

          {/*Hr.......*/}
          <Route path="/manageUsers" element={<PrivateRoute page={<ManageUsers />} />} />
          <Route path="/stats" element={<PrivateRoute page={<Stats />} />} />
          <Route path="/bol" element={<PrivateRoute page={<BillOfLading />} />} />

          {/*BOL.......*/}
          <Route path="/bolList" element={<PrivateRoute page={<BolList />} />} />

          {/*Inventory management.......*/}
          <Route path="/poList" element={<PrivateRoute page={<PoList />} />} />
          <Route path="/transferList" element={<PrivateRoute page={<TransferList />} />} />
          <Route path="/poWorksheet" element={<PrivateRoute page={<PoWorksheet />} />} />
          <Route path="/styleList" element={<PrivateRoute page={<StyleList />} />} />

          {/*Admin.......*/}
          <Route path="/modifyAllocation" element={<PrivateRoute page={<ModifyAllocation />} />} />
          <Route path='/register' element={<PrivateRoute page={<Register />} />} />
          <Route path="/users" element={<PrivateRoute page={<UserRoleAccess />} />} />
          <Route path="/automateTransfer" element={<PrivateRoute page={<AutomateTr />} />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </MantineProvider>
  );
}


export default App;