import { useSelector } from "react-redux";

export const roleHierarchy: { [key: string]: number } = {
    
    User: 1,
    Admin: 2,
    Hr: 3,
    SuperAdmin: 4,
};

export const UseAccess = (access: string) => {
    const scope = useSelector((state: any) => state.user?.user?.scope);

    if (!scope) return false;

    // Role hierarchy - SuperAdmin > Admin > User

    // Compare access level with the user's role
    if (roleHierarchy[scope] >= roleHierarchy[access]) {
        return true;
    }

    return false;
};

export const sortSizes = (sizes: { size: string, sku: string, quantity: number }[]): { size: string, sku: string, quantity: number }[] => {
    // Define regex patterns to detect different size formats
    const letterPattern = /^[A-Za-z]+$/; // For sizes like 'S', 'M', 'L', 'XL'
    const numericPattern = /^\d+(\.\d+)?$/; // For sizes like '5', '5.5', '6', etc.
    const mixedPattern = /^[A-Za-z]+\d+(\.\d+)?$/; // For sizes like 'M5', 'W6.5', etc.

    // Function to parse letter-based sizes (e.g., XS, S, M, L, XL)
    const parseLetterSize = (size: string): number => {
        const sizeOrder = ['XS', 'S', 'M', 'L', 'XL', 'XXL']; // Customize this order as needed
        return sizeOrder.indexOf(size.toUpperCase());
    };

    // Function to parse numeric sizes (e.g., '5', '5.5', '6', '6.5')
    const parseNumericSize = (size: string): number => parseFloat(size);

    // Function to parse mixed sizes (e.g., 'M5', 'W6.5', 'M7')
    const parseMixedSize = (size: string): number => {
        const match = size.match(/^([A-Za-z]+)(\d+(\.\d+)?)$/);
        if (match) {
            const letter = match[1];
            const numeric = parseFloat(match[2]);
            const letterWeight = (letter === 'M') ? 1 : (letter === 'W' ? 2 : 0); // Customize if needed
            return letterWeight * 1000 + numeric; // Combine weight and numeric for sorting
        }
        return parseFloat(size); // Fallback for other cases
    };

    // Determine the pattern based on the first size in the array
    const firstSize = sizes[0].size;

    let parsedSizes: number[];

    if (letterPattern.test(firstSize)) {
        // If sizes are letter-based (e.g., XS, S, M, L, XL)
        parsedSizes = sizes.map(item => parseLetterSize(item.size));
    } else if (numericPattern.test(firstSize)) {
        // If sizes are numeric (e.g., '5', '5.5', '6', '6.5')
        parsedSizes = sizes.map(item => parseNumericSize(item.size));
    } else if (mixedPattern.test(firstSize)) {
        // If sizes are mixed (e.g., 'M5', 'W6', 'M7')
        parsedSizes = sizes.map(item => parseMixedSize(item.size));
    } else {
        // Fallback if no pattern is recognized
        throw new Error("Unrecognized size format");
    }

    // Sort based on parsed size values
    const sortedSizes = sizes
        .map((item, index) => ({ ...item, value: parsedSizes[index] })) // Pair each object with its parsed size value
        .sort((a, b) => a.value - b.value) // Sort by parsed size value
        .map(item => ({ size: item.size, sku: item.sku, quantity: item.quantity })); // Return the sorted objects

    return sortedSizes;
}