export const updatePoAndStyle = async (token: string, po_id: number, style_id: number, po_no?: string, style_no?: string) => {

    try {
        const response = await fetch(`/allocation/updatePoStyle`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ po_id, po_no, style_id, style_no }),
        });
        if (response.status === 204) {
            return null;
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.log('Error fetching PONO:', error);
    }
}

export const delteAllocation = async (token: string, allocation_id: number):Promise<any> => {
    const response = await fetch(`/allocation/delete/${allocation_id}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
    const data = await response.json();
    if (response.status === 200) {
        return true;
    }
    return false;
}