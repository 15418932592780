type Pair = {
    [key: string]: string;
};

export const STORES = [
    'STEELES',
    'WEB',
    'SC SuperStore',
    'Brampton',
    'SVP NM',
    'ORFUS',
    'VAUGHAN',
    'SVP QW',
    'Kingston',
    'NIAGARA',
    'ALLISTON',
    'OPM-HAMILTON',
    'CARTWRIGHT',
    'PICKERING',
    'YORKGATE'
];

export const STORES_PAIRS: Pair = {
    'STEELES': 'ST',
    'WEB': 'WEB',
    'SC SuperStore': 'SCS',
    'Brampton': 'Brampton',
    'SVP NM': 'SVP NM',
    'ORFUS': 'ORFUS',
    'VAUGHAN': 'V',
    'SVP QW': 'SVP QW',
    'Kingston': 'KNG',
    'NIAGARA': 'NF',
    'ALLISTON': 'AL',
    'OPM-HAMILTON': 'OPMH',
    'CARTWRIGHT': 'CW',
    'PICKERING': 'PC',
    'YORKGATE': 'YG'
}

export const RMS_STORE_PAIRS: Pair = {
    "Steeles": "ST",
    "Web": "WEB",
    "OPM": "OPM",
    "Vaughan": "V",
    "Niagara": "NF",
    "Alliston": "AL",
    "Scarborough": "SC",
    "Cartwright": "CW",
    "Brampton": "BR",
    "Pickering": "PC",
    "Yorkgate": "YG",
    "OPM-Hamilton": "OHAM",
    "New-Scarborough": "SCS",
    "Dufferin-Orfus": "ORFUS",
    "Newmarket": 'SVP NM',
    "Queensway": 'QW',
    "DC": 'DC',
    "DC-Caledonia": 'DC-CAL',
    "DC-Queensway": 'DC-QW',
}