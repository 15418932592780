import { Select, Table } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { STORES_PAIRS } from '../../constants/stores';
import { useDebounce } from '../../Hooks/useDebounce';
import { PoNoWithStatus } from '../../interface/AllocationInvoice';
import classes from './StyleData.module.css';

const StyleData: React.FC = () => {
    const [rowData, setRowData] = useState<PoNoWithStatus>();
    const token = useSelector((state: any) => state.user.sessionId);
    const [tableData, setTableData] = useState<any[]>([]);
    const [styleList, setStyleList] = useState<{ label: string, value: string }[]>([]);
    const [style, setStyle] = useState<{ style_no: string, style_id: string }>({
        style_no: '',
        style_id: ''
    });
    const styleSearch = useDebounce(style.style_no, 600);
    const [selectedRow, setSelectedRow] = useState<number>();

    useEffect(() => {
        // setPagination(initialPagination);
        getData(styleSearch);
    }, [styleSearch]);

    const getData = async (style_no?: string) => {
        try {
            const response = await fetch('/style/getStyleList', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    style_no: style_no ? style_no : style.style_no,

                })
            });
            const { data, total, current } = await response.json();
            if (response.status === 200) {
                // await setTableData(data);
                await setStyleList(data.map((item: { style_no: string, style_id: number }) => {
                    return {
                        label: item.style_no,
                        value: item.style_id.toString()
                    }
                }))
                return;
            }
            alert('Could not find the data. Please check the filter values again.');
            // setTableData([]);
            return;

        } catch (error) {
            console.log('Error fetcing transfer data.')
        }
    }
    const handleStyleChange = async (value: string | null) => {
        if (value) {
            try {
                const response = await fetch('/allocation/allocationByStyle', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        style_id: parseInt(value)
                    })
                });
                const data = await response.json();
                if (response.status !== 200) {
                    alert('Could not find the data. Please check the filter values again.');
                    setTableData([]);
                    return;
                }
                setTableData(data.data);
            } catch (error) {
                console.log('Error Fetching style data.', error);
            }
        }
    }

    const renderExpandedRowDetails = (row: any) => {
        return (
            <Table withColumnBorders withRowBorders withTableBorder striped stickyHeader style={{ borderCollapse: 'collapse' }}>
                <Table.Thead>
                    <Table.Tr className={classes.expandedTableHead}>
                        <Table.Th>Stores</Table.Th>
                        {row.storeName.map((store: string) => {
                            return <Table.Th>{STORES_PAIRS[store]}</Table.Th>
                        })}
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    <Table.Tr>
                        <Table.Th>Quantity</Table.Th>
                        {row.sizeQuantities.map((item: any) => {
                            const total = item.reduce((total: number, sz: { quantity: number }) => total + sz.quantity, 0);
                            return <Table.Td>{total}</Table.Td>
                        })}
                    </Table.Tr>
                </Table.Tbody>
            </Table>
        );
    };

    return (
        <div className={classes.main}>
            <h2 className={classes.title} style={{ marginTop: '-5px'}}>Style Info</h2>
            <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', flexGrow: 1 }}>
                    <Select
                        placeholder='Search Style No . . .'
                        searchable
                        clearable
                        style={{ width: '100%', marginBottom: '15px' }}
                        onSearchChange={(e) => setStyle({ ...style, style_no: e.toUpperCase() })}
                        onChange={handleStyleChange}
                        data={styleList}
                    />
                </div>
                <Table striped withColumnBorders withTableBorder stickyHeader>
                    <Table.Thead className={classes.tableHead}>
                        <Table.Tr>
                            <Table.Th>Po No</Table.Th>
                            <Table.Th>Updated At</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {tableData && tableData.map((item: any) => {
                            return (<><Table.Tr onClick={() => setSelectedRow(item.allocation_id)}>
                                <Table.Td>{item.po_no}</Table.Td>
                                <Table.Td>{item.updatedAt}</Table.Td>
                            </Table.Tr>
                                {selectedRow === item.allocation_id &&
                                    <Table.Tr>
                                        <Table.Td colSpan={2}>
                                            {renderExpandedRowDetails(item)}
                                        </Table.Td>
                                    </Table.Tr>
                                }
                            </>
                            )
                        })}
                    </Table.Tbody>
                </Table>
            </div>
        </div>
    )
}

export default StyleData;