import { Button, Grid, Modal, PasswordInput, Select, Table, TextInput, useMantineTheme } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NavBar from '../../components/NavBar/NavBar';
import classes from './UserRoleAccess.module.css';
import UserModalProps from './userModal';

const UserRoleAccess: React.FC = () => {
    const [userList, setUserList] = useState([]);
    const [password, setPassword] = useState<{ password: string, confirmPassword: string }>({ password: '', confirmPassword: '' })
    const [userModal, setUserModal] = useState(false);
    const [user, setUser] = useState({} as UserModalProps);
    const theme = useMantineTheme();
    const token = useSelector((state: any) => state.user.sessionId);

    const getData = async () => {
        try {
            const response = await fetch(`/auth/users`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            const data = await response.json();
            if (response.status !== 200) {
                console.log('Unable to get the data.');
            }
            setUserList(data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const handleModify = async (user: UserModalProps) => {
        await setUser(user);
        setUserModal(true);
    }
    const handleSave = async () => {
        let pwd = undefined;
        if (password.password === password.confirmPassword && password.password && password.confirmPassword) {
            pwd = password.password;
        }
        try {
            const response = await fetch('auth/update', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ ...user, password: pwd })
            });
            if (response.status === 400) {
                alert('Can not find user');
                return;
            }
            if (response.status === 200) {
                alert('User updated successfully.');
                return;
            }
            alert(`Error updating user:${user.first_name}`);
        } catch (error) {
            alert('Something went wrong.')
            console.log(error);
        }
    }
    return (
        <>
            <NavBar />
            <Modal
                opened={userModal}
                onClose={() => setUserModal(false)}
                title="Modify User"
                size="xl"
            >
                <div className={classes.container}>
                    <Grid className={classes.mainGrid}>
                        <Grid.Col span={12}>
                            <TextInput
                                label='Email'
                                type="email"
                                disabled
                                value={user.email}
                                // placeholder="Enter your email..."
                                onChange={(e) => setUser({ ...user, email: e.target.value || '' })}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 6 }}>
                            <TextInput
                                label='First name'
                                required
                                value={user.first_name}
                                placeholder="Enter your first name..."
                                onChange={(e) => setUser({ ...user, first_name: e.target.value || '' })}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 6 }}>
                            <TextInput
                                label='Last name'
                                required
                                value={user.last_name}
                                placeholder="Enter your last name..."
                                onChange={(e) => setUser({ ...user, last_name: e.target.value || '' })}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 6 }}>
                            <Select
                                label='Location'
                                data={['DC-Caledonia', 'DC']}
                                defaultValue={'DC-Caledonia'}
                                onChange={(value) => {
                                    if (value === 'DC-Caledonia' || value === 'DC') {
                                        setUser({ ...user, location: value });
                                    } else {
                                        console.error(`Invalid scope value: ${value}`);
                                    }
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 6 }}>
                            <Select
                                label='Scope'
                                data={['User', 'Admin', 'SuperAdmin']}
                                defaultValue={'Admin'}
                                onChange={(value) => {
                                    if (value === 'User' || value === 'Admin' || value === 'SuperAdmin') {
                                        setUser({ ...user, scope: value });
                                    } else {
                                        console.error(`Invalid scope value: ${value}`);
                                    }
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 6 }}>
                            <PasswordInput
                                label='Password'
                                type="password"
                                value={password.password}
                                placeholder="Enter your Password..."
                                onChange={(e) => setPassword({ ...password, password: e.target.value || '' })}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 6 }}>
                            <PasswordInput
                                label='Confrim Password'
                                type="password"
                                value={password.confirmPassword}
                                placeholder="Enter your password again..."
                                onChange={(e) => setPassword({ ...password, confirmPassword: e.target.value || '' })}
                            />
                        </Grid.Col>
                    </Grid>
                    <div className={classes.buttonContainer}>
                        <Button className={classes.button} onClick={handleSave}> Save</Button>
                    </div>
                </div>
            </Modal>
            <div className={'main'}>
                <h2 className={'title'}>User Role Access</h2>
                <Grid >
                    {userList.length > 0 && (<Grid.Col span={12} >
                        <Table verticalSpacing="xs" className={classes.table} striped highlightOnHover stickyHeader>
                            <Table.Thead className={classes.thead} style={{ width: '100%', color: theme.colors.gray[0], backgroundColor: theme.colors.gray[9] }} >
                                <Table.Tr>
                                    <Table.Th>First Name</Table.Th>
                                    <Table.Th>Last Name</Table.Th>
                                    <Table.Th>Email</Table.Th>
                                    <Table.Th>Location</Table.Th>
                                    <Table.Th>Role</Table.Th>
                                    <Table.Th>Actions</Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody className={classes.tbody}>
                                {userList.map((user: UserModalProps, index: number) => (
                                    <Table.Tr key={index}>
                                        <Table.Td>{user.first_name}</Table.Td>
                                        <Table.Td>{user.last_name}</Table.Td>
                                        <Table.Td>{user.email}</Table.Td>
                                        <Table.Td>{user.location}</Table.Td>
                                        <Table.Td>{user.scope}</Table.Td>
                                        <Table.Td><IconEdit size={21} className={classes.icon} onClick={() => handleModify(user)} /></Table.Td>
                                    </Table.Tr>
                                ))}
                            </Table.Tbody>
                        </Table>
                    </Grid.Col>)}
                </Grid>
                {/* <div className={classes.footer} style={{ backgroundColor: theme.colors.gray[1], color: theme.colors.gray[0], border: `1px solid ${theme.colors.gray[0]}` }}>
                    <Button variant="subtle" color="gray" size="xs" style={{ color: theme.colors.gray[10], backgroundColor: theme.colors.gray[1] }}>
                        <IconPlus size={21} className={classes.icon} style={{ marginRight: '20px' }} />
                        Add User
                    </Button>
                </div> */}
            </div>
        </>
    )
};

export default UserRoleAccess;