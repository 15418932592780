import { Button } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import React, { useEffect, useState } from 'react';
import { STORES_PAIRS } from '../../constants/stores';

pdfMake.vfs = pdfFonts.vfs;

interface SizeQuantity {
    size: string;
    quantity: number;
}
interface StyleData {

    supplier_name: string;
    total_qty: number;
    location: string;
    first_name: string;
}
interface AllocationData {
    allocation_id: number;
    storeName: string[];
    style_id: number;
    supplierName: string;
    po_no: string;
    style_no: string;
    description: string;
    color: string;
    cost: number;
    msrp: number;
    sizeQuantities: SizeQuantity[][];
    styles: StyleData;
}

interface StoreStyle {
    [key: string]: string[];
}
const initialFilterState = {
    store: '',
    supplier_name: '',
    style_no: '',
    po_no: ''
};

interface TransferReportProps {
    po_id?: number;
    po_no: string;
    token: string;
    filter: typeof initialFilterState;
}

type StorePair = {
    [key: string]: string; // Key is a string, value is a number
};
const TransferReport: React.FC<TransferReportProps> = ({ po_id, po_no, filter, token }) => {
    const [reportData, setReportData] = useState<AllocationData[]>([]);
    const getData = async () => {
        try {
            const response = await fetch('/allocation/transferList', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    po_id,
                    ...filter,
                    po_no,
                    report: true
                })
            });
            const { data } = await response.json();
            setReportData(data);
        } catch (error) {
            console.log('Error generating report:', error);
            alert('Error generatin report');
        }
    }
    useEffect(() => {
        getData();
    }, [])
    const generateStylePDF = () => {
        let docDef = {
            content: [
                {
                    text: 'Not In Transfer List',
                    fontSize: 20,
                    bold: true,
                    alignment: 'center',
                    decoration: 'underline',
                    margin: [0, 0, 0, 20],
                },
                {
                    table: {
                        headerRows: 1,
                        header: ['Store Name', 'Style Nos'],
                        widths: ['*', '*'],
                        body: buildNonTransferTable(reportData)
                    },
                    layout: {
                        hLineWidth: () => 0.5,
                        vLineWidth: () => 0.5,
                        hLineColor: () => '#000000',
                        vLineColor: () => '#000000',
                    }
                }
            ],
            styles: {
                header: {
                    fontSize: 12,
                    bold: true
                }
            }
        };

        pdfMake.createPdf(docDef as TDocumentDefinitions).open();
    }


    let tempTotal = 0;
    const generatePDF = () => {
        tempTotal = 0;
        let docDefinition = {
            content: [
                {
                    text: 'Transfer List',
                    fontSize: 20,
                    bold: true,
                    alignment: 'center',
                    decoration: 'underline',
                    margin: [0, 0, 0, 20],
                },
                {
                    columns: [
                        {
                            text: `Supplier Name: ${filter.supplier_name}`,
                            fontSize: 16,
                            bold: true,
                            alignment: 'center',
                            margin: [0, 0, 0, 8]
                        },
                        {
                            text: `PO No: ${filter.po_no}`,
                            fontSize: 16,
                            bold: true,
                            alignment: 'center',
                            margin: [0, 0, 0, 8]
                        },
                        {
                            text: `Store Name: ${filter.store}`,
                            fontSize: 16,
                            bold: true,
                            alignment: 'center',
                            margin: [0, 0, 0, 16]
                        },
                    ]
                },
                {
                    width: 'auto',
                    margin: [-34, 0, 0, 0],
                    table: {
                        widths: [140, 180, 50, 40, 35, 45, 30],
                        body: buildTableBody(reportData)
                    },
                    layout: {
                        hLineColor: () => '#000000',
                        vLineColor: () => '#000000',
                        hLineWidth: () => 1,
                        vLineWidth: () => 1
                    },
                }, {
                    text: `Total Quantity: ${tempTotal}`,
                    fontSize: 14,
                    bold: true,
                    alignment: 'right',
                    margin: [0, 15, 0, 0]
                }
            ],
            defaultStyle: {
                fontSize: 12
            }
        };

        pdfMake.createPdf(docDefinition as TDocumentDefinitions).open();
    };
    const bodyRowStyle = {
        fillColor: '#CCCCCC', // Background color
        color: '#000000', // Text color
        bold: true
    };

    const buildNonTransferTable = (data: AllocationData[]) => {
        let body = [];
        let notInStore: StoreStyle = Object.keys(STORES_PAIRS).reduce((acc, store) => {
            acc[store] = [];
            return acc;
        }, {} as StoreStyle);

        body.push([
            { text: "Store Name", style: bodyRowStyle },
            { text: "Style Nos", style: bodyRowStyle }
        ]);
        data.filter(row =>
            (!filter.supplier_name || row.supplierName.toLowerCase().includes(filter.supplier_name.toLowerCase())) &&
            (!filter.style_no || row.style_no.toLowerCase().includes(filter.style_no.toLowerCase())) &&
            (!filter.supplier_name || row.supplierName.toLowerCase().includes(filter.supplier_name.toLowerCase())) &&
            (!filter.po_no || row.po_no.toLowerCase().includes(filter.po_no.toLowerCase())))
            .forEach(row => {
                // Apply the filter here
                if (row.storeName.length !== 15) {
                    Object.keys(STORES_PAIRS).forEach(store => {
                        if (!row.storeName.includes(store)) {
                            notInStore[store] = [...notInStore[store], row.style_no];
                        }
                    });
                }
            });

        Object.keys(notInStore).forEach(store => {
            body.push([
                { text: store },
                { text: notInStore[store].join(', ') }
            ]);
        });

        return body;
    }

    const buildTableBody = (data: AllocationData[]) => {
        let body = [];
        body.push([
            { text: "Style Number", style: bodyRowStyle },
            { text: "description", style: bodyRowStyle },
            { text: "color", style: bodyRowStyle },
            { text: "Store Name", style: bodyRowStyle },
            { text: "Size", style: bodyRowStyle },
            { text: "Quantity", style: bodyRowStyle },
            { text: "Total", style: bodyRowStyle }
        ]);
        data.filter(row =>
            (!filter.supplier_name || row.supplierName.toLowerCase().includes(filter.supplier_name.toLowerCase())) &&
            (!filter.style_no || row.style_no.toLowerCase().includes(filter.style_no.toLowerCase())) &&
            (!filter.supplier_name || row.supplierName.toLowerCase().includes(filter.supplier_name.toLowerCase())) &&
            (!filter.po_no || row.po_no.toLowerCase().includes(filter.po_no.toLowerCase())))
            .forEach(row => {
                // Apply the filter here
                row.storeName
                    .filter(storeName => (!filter.store || storeName.toLowerCase() === filter.store.toLowerCase()))
                    .forEach((storeName, i) => {
                        const storeIndex = row.storeName.indexOf(storeName);
                        const total = row.sizeQuantities[storeIndex].reduce((acc, curr) => acc + curr.quantity, 0);
                        tempTotal += total;
                        row.sizeQuantities[storeIndex].forEach((sq, j) => {
                            let dataRow = [];
                            dataRow.push(j === 0 ? row.style_no : "");
                            dataRow.push(j === 0 ? row.description : "");
                            dataRow.push(j === 0 ? row.color : "");
                            dataRow.push(j === 0 ? STORES_PAIRS[storeName] : "");
                            dataRow.push(sq.size);
                            dataRow.push(sq.quantity);
                            dataRow.push(j === 0 ? total : "");
                            body.push(dataRow);
                        });
                    });
            });
        return body;
    };
    const downloadPdf = () => {
        if (filter.store === '') {
            alert('Please select a store');
            return;
        } else {
            generatePDF();
            generateStylePDF();
        }
    }

    return (
        <Button onClick={downloadPdf}><IconDownload /></Button>
    );
};

export default TransferReport;