// userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserSession } from '../../interface/User';
// Define the initial state for the user
interface UserState {
    user: IUserSession | null;
    sessionId: string | null;
    isLoggedIn: boolean;
    loading: boolean;
    error: string | null;
}

const initialState: UserState = {
    user: null,
    sessionId: null,
    isLoggedIn: false,
    loading: false,
    error: null,
};

// Create the user slice
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // Action to set the user after a successful login
        loginRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        loginSuccess: (state, action: PayloadAction<{ sessionId: string, user: IUserSession }>) => {
            state.user = action.payload.user;
            state.sessionId = action.payload.sessionId; // Store SESSIONID in state
            state.isLoggedIn = true;
            state.loading = false;
            state.error = null;
        },
        loginFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        },
        // Action to clear the user data when logged out
        logout: (state) => {
            state.sessionId = null;
            state.user = null;
            state.isLoggedIn = false;
            state.loading = false;
            state.error = null;
        },
    },
});

// Export the actions and the reducer
export const { loginRequest, loginSuccess, loginFailure, logout } = userSlice.actions;
export default userSlice.reducer;
