import { Image } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React from "react";
import { useSelector } from "react-redux";
import logo from "../assets/DC_CAL_LOGO_01_DARK.svg";
import NavBar from "../components/NavBar/NavBar";

const HomePage: React.FC = () => {
    // const user = useSelector((state: any) => state.user.user);
    const user = useSelector((state: any) => state.user.user) || {};
    const isSmallScreen = useMediaQuery('(max-width: 1150px)');
    return (
        <div>
            <NavBar />
            <div style={{ marginLeft: 20, marginTop: 50, display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'center', alignItems: 'center' }}>
                <h1>Welcome, {user?.first_name} {user?.last_name}</h1>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Image src={logo} alt="SVP IMS" width={isSmallScreen ? '30%' : '600px'} height={isSmallScreen ? '30%' : '600px'} opacity={0.04} />
                </div>
            </div>
        </div>
    );
};

export default HomePage;