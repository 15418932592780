import { Table, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classes from './InvoiceTable.module.css';

interface InvoiceTableProps {
    poNo?: string;
    poId?: string | number;
}

const InvoiceTable: React.FC<InvoiceTableProps> = ({ poNo = '', poId = '' }: InvoiceTableProps) => {
    const token = useSelector((state: any) => state.user.sessionId);
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
    const [tableData, setTableData] = useState<any>([]);
    const [supplier, setSupplier] = useState('');
    const [totalStyles, setTotalStyles] = useState<number>();
    const getTableData = async (poId: string) => {
        try {
            const response = await fetch('/po/poDetails', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ poId: poId }),
            });
            if (response.status !== 200) {
                setTableData([]);
                setSupplier('');
                setTotalStyles(0);
            }
            const data = await response.json();
            setTableData(data.storeData);
            setSupplier(data.supplier);
            setTotalStyles(data.uniqueStyles);
        } catch (error) {
            console.log('Invocie data fetch error:', error);
        }
    }
    useEffect(() => {
        getTableData(poId.toString());
    }, [poId])
    return (
        <div className={classes.gridColDiv} style={{ backgroundColor: theme.colors.gray[0], color: theme.colors.gray[10] }}>
            <div className={classes.stickyDiv} style={{ backgroundColor: theme.colors.gray[0], color: theme.colors.gray[10], zIndex: 2 }}>
                <div className={classes.gridRowDiv} >
                    <Text className={classes.textWithInput}>Po No:</Text>
                    <Text className={classes.textWithInput}>{totalStyles === 0 || totalStyles === undefined ? 'New PO' : poNo}</Text>
                </div>
                <div className={classes.gridRowDiv} >
                    <Text className={classes.textWithInput}>Supplier:</Text>
                    <Text className={classes.textWithInput}>{supplier}</Text>
                </div>
                <div className={classes.gridRowDiv} >
                    <Text className={classes.textWithInput}>Total Styles:</Text>
                    <Text className={classes.textWithInput}>{totalStyles}</Text>
                </div>
            </div>
            <Table border={1} withColumnBorders striped className={classes.table}>
                <Table.Thead >
                    <Table.Tr className={classes.tableHeader} style={{ backgroundColor: theme.colors.gray[8], color: theme.colors.gray[0] }}>
                        <Table.Th>Store</Table.Th>
                        <Table.Th>No of Styles</Table.Th>
                        <Table.Th>Quantity</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody style={{overflowY: 'scroll'}}>
                    {tableData && Object.keys(tableData).map((store) => (
                        <tr key={store}>
                            <td>{store}</td>
                            <td>{tableData[store].totalStyles}</td>
                            <td>{tableData[store].totalQuantity}</td>
                        </tr>
                    ))}
                    {/* {tableData && tableData?.map((item: any) => (
                        <Table.Tr key={item.store}>
                            <Table.Td>{item.store}</Table.Td>
                            <Table.Td>{item.noOfStyles}</Table.Td>
                            <Table.Td>{item.quantity}</Table.Td>
                        </Table.Tr>
                    ))} */}
                </Table.Tbody>
            </Table>
        </div>
    );
};


export default InvoiceTable;