// sheet

import { Button, Checkbox, Collapse, Grid, Input, Loader, Menu, Modal, Select, Table, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconArrowDown, IconArrowUp, IconChevronDown, IconChevronUp, IconClearAll, IconColumns, IconDeviceFloppy, IconFilter, IconPlus, IconTrash, IconX } from '@tabler/icons-react';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import NavBar from '../../components/NavBar/NavBar';
import classes from './Sheet.module.css';
import '../Global.css';
import { useSelector } from 'react-redux';
interface TableColumn {
    title: string;
    name: string;
    width: number;
    ASC?: boolean;
    checked: boolean;
}
const useDebouncee = (callback: () => void, delay: number) => {
    const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>();

    const debouncedCallback = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(callback, delay);
    };

    return debouncedCallback;
};

const Sheet: React.FC = () => {
    const isMobile = useMediaQuery('(max-width: 900px)');
    const [tableData, setTableData] = useState<any[]>([]);
    const [mainData, setMainData] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const initalSearch = {
        start_received_date: '',
        end_received_date: '',
        supplier: '',
        category: '',
        po: '',
        status: '',
    }
    const [searchTerm, setSearchTerm] = useState(initalSearch);
    const [stats, setStats] = useState<any[]>([]);
    const [activeRow, setActiveRow] = useState<number | null>(null)
    // const debouncedSearchTerm = useDebounce(searchTerm, 500); 
    const startX = useRef<number>(0);
    const startWidth = useRef<number>(0);
    const resizingColumnIndex = useRef<number | null>(null);
    const [modifiedData, setModifiedData] = useState<any[]>([]);
    const theme = useMantineTheme();
    const [columns, setColumns] = useState<TableColumn[]>([
        { title: 'Received Date', name: 'received_date', width: isMobile ? 300 : 190, checked: true },
        { title: 'Supplier', name: 'supplier', width: isMobile ? 300 : 190, checked: true },
        { title: 'skid Count', name: 'skid_count', width: isMobile ? 300 : 150, checked: true },
        { title: 'Condensed Skid Count', name: 'condensed_skid_count', width: isMobile ? 300 : 50, checked: true },
        { title: 'Category', name: 'category', width: isMobile ? 300 : 150, checked: true },
        { title: 'PO', name: 'po', width: isMobile ? 300 : 200, checked: true },
        { title: 'Status', name: 'status', width: isMobile ? 300 : 180, checked: true },
        { title: 'Notes', name: 'notes', width: isMobile ? 300 : 300, checked: true },
        { title: 'Bin', name: 'bin', width: isMobile ? 300 : 150, checked: true },
        { title: 'Invoice Date', name: 'invoice_date', width: isMobile ? 300 : 150, checked: true },
        { title: 'Invoice Number', name: 'invoice_number', width: isMobile ? 300 : 150, checked: true },
        { title: 'Invoice Qty', name: 'invoice_qty', width: isMobile ? 300 : 50, checked: true },
        { title: 'Invoice Value', name: 'invoice_value', width: isMobile ? 300 : 50, checked: true },
        { title: 'Due Date', name: 'due_date', width: isMobile ? 300 : 50, checked: true },
        { title: 'Submitted Date', name: 'submitted_date', width: isMobile ? 300 : 150, checked: true },
        { title: 'Invoice Files', name: 'invoice_files', width: isMobile ? 300 : 450, checked: true },
    ]);
    const initalSelect = ['received_date', 'skid_count', 'condensed_skid_count', 'supplier', 'category', 'po', 'status', 'notes', 'bin', 'invoice_date', 'invoice_number', 'invoice_qty', 'invoice_value', 'due_date', 'submitted_date', 'invoice_files'];
    const [showModal, setShowModal] = useState(false);
    const [selectedItems, setSelectedItems] = useState<string[]>(initalSelect);
    const [openedRow, setOpenedRow] = useState<number | null>(null);
    const token = useSelector((state: any) => state.user.sessionId);

    const handleMouseDown = (index: number, e: React.MouseEvent) => {
        startX.current = e.clientX;
        startWidth.current = columns[index].width;
        resizingColumnIndex.current = index;
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (resizingColumnIndex.current !== null) {
            const newWidth = startWidth.current + (e.clientX - startX.current);
            const newColumns = [...columns];
            newColumns[resizingColumnIndex.current].width = newWidth;
            setColumns(newColumns);
        }
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        resizingColumnIndex.current = null;
    };

    useEffect(() => {
        getData();
        if (typeof window !== "undefined") {
            if (localStorage.getItem("sheetData")) {
                let sheetData = JSON.parse(localStorage.getItem("sheetData") || "[]");
                if (sheetData.length > 0) {
                    setTableData(sheetData);
                    setMainData(sheetData);
                }
            }
            setTableData(JSON.parse(localStorage.getItem("sheetData") || "[]"));
            setMainData(JSON.parse(localStorage.getItem("sheetData") || "[]"));
        }
    }, [page, limit]);

    // Function to format the date
    const formatDate = (dateString: string | null): string => {
        if (!dateString) return ''; // Return empty string if no date
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // Returns YYYY-MM-DD
    };

    const getData = async () => {
        try {
            const response = await fetch(`/sheet/getshipments`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            if (response.status !== 200) {
                console.log('Unable to get the data.');
            }
            if (stats) {
                setStats(stats);
            }
            setMainData(data);
            setFilteredData(data);
            setModifiedData([]);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDataChange = (index: number, field: string, data: any) => {
        setTableData((prevData) => {
            let newData = [...prevData];
            newData[index][field] = data;
            return newData;
        })
        if (!modifiedData.includes(index)) {
            setModifiedData((prevData) => [...prevData, index]);
        };
    }
    const handleSave = async (tableData: any, newData?: boolean) => {
        let incompleteData = false;
        for (let i = 0; i < tableData.length; i++) {
            const item = tableData[i];
            if (!item.received_date || !item.supplier || !item.category || !item.status || !item.po) {
                incompleteData = true;
                break;
            }
        }
        try {
            if (incompleteData) {
                alert('Please fill in all the required fields for each item before saving.');
                return;
            }
            let formData = tableData.map((item: any, index: number) => {
                return modifiedData.includes(index) ? item : null;
            }).filter((item: any) => item !== null);

            // Send the data to the server
            const response = await fetch(`/sheet/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(newData ? tableData : formData),
            });
            const data = await response.json();
            if (response.status !== 200) {
                console.log('Unable to get the data.');
                alert('Unable to save the data.')
                return;
            }
            if (stats) {
                setStats(stats);
            }
            getData();
            setModifiedData([]);
            alert('Data saved successfully');
        } catch (error) {
            console.error(error);
        }
    };

    const handleAddRow = () => {
        setTableData([...tableData, {
            condensed_skid_count: 0, status: 'Pending'
        }]);
        // scroll to the bottom of the table
        const table = document.getElementById('table');
        if (table) {
            table.scrollIntoView({ behavior: 'smooth', block: 'end' }); 
        }
    };
    const deleteRow = async (id: number) => {
        try {
            const response = await fetch(`/sheet/delete/shipments/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            if (response.status !== 200) {
                console.log('Unable to get the data.');
            }
            if (data) {
                alert(data.message);
            }
        } catch (error) {
            console.error('Error deleting row:', error);
        }
    }
    const handleDeleteRow = (index: number, id: number) => {
        if (id) {
            let confirm = window.confirm("Are you sure you want to delete this item?");
            if (confirm) {
                setTableData(tableData.filter((_, i) => i !== index));
                deleteRow(id);
            }
        } else {
            setTableData(tableData.filter((_, i) => i !== index));
        }
    };


    const debouncedSave = useDebouncee(() => {
        if (typeof window !== "undefined") {
            localStorage.setItem("tableData", JSON.stringify(tableData));
        }

        // Check if there are rows without an ID
        if (tableData.some((row) => !row.id)) {
            let updateData = tableData.map((row) => {
                if (!row.id && row.received_date && row.supplier && row.category && row.po && row.status) {
                    return row;
                }
                return null; // Return null for rows that shouldn't be updated
            }).filter((row) => row !== null); // Filter out null values
            if (updateData.length > 0) {
                handleSave(updateData, true); // Save the valid rows
            }
        }
    }, 5 * (1000)); // Adjust the delay as needed

    useEffect(() => {
        debouncedSave(); // Call the debounced function
    }, [tableData, debouncedSave]); // Include debouncedSave in the depende


    const handleFileChange = (index: number, files: FileList | null) => {
        if (files) {
            // get the indexed row file
            const row = tableData[index];
            handleUploadFile(row.id, files);
            // check if the row has invoice_files which matches from the files
            let existingFiles = row.invoice_files || []; // Ensure it’s an array
            // existingFiles.push(...files);
            existingFiles.push(...Array.from(files));
            handleDataChange(index, 'invoice_files', existingFiles);
        }
    };
    const handleUploadFile = async (id: number, files: FileList) => {
        // Handle file upload logic here
        const formData = new FormData();
        Array.from(files)?.forEach((file: File) => {
            formData.append("files", file);
        });

        if (!formData) {
            alert('Error uploading file');
            return;
        }
        try {
            const response = await axios.put(`/sheet/shipments/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const data = await response.data;
            if (response.status !== 200) {
                alert('Error uploading file');
            }
            alert('File uploaded successfully');
        } catch (error) {
            console.error(error);
        }
    }

    const deleteFile = async (id: number, fileName: string) => {
        try {
            const response = await axios.put(`/sheet/delete-invoice/shipments/${id}/${fileName}`);
            const data = await response.data;
            if (response.status !== 200) {
                alert('Error deleting file');
            }
            alert('File deleted successfully');
        } catch (error) {
            console.log(error)
        }
    }
    const handleDeleteFile = (index: number, fileIndex: number) => {
        deleteFile(tableData[index].id, tableData[index].invoice_files[fileIndex]);
        const updatedData = [...tableData];
        const updatedFiles = updatedData[index].invoice_files.filter((_: any, i: number) => i !== fileIndex);
        updatedData[index].invoice_files = updatedFiles;
        setTableData(updatedData);
    };

    const handleDownloadFile = (index: number, fileIndex: number) => {
        const file = tableData[index]?.invoice_files?.[fileIndex];

        if (file instanceof File) {
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();

            setTimeout(() => {
                URL.revokeObjectURL(fileURL);
                document.body.removeChild(link);
            }, 100);
        } else {
            fetch(`/sheet/download/${file}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((response) => {
                    if (!response.ok) {
                        alert('File not found');
                        throw new Error('File not found');
                    }
                    return response.blob(); // Get the blob of the file
                })
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', file); // Set the filename for download
                    document.body.appendChild(link);
                    link.click(); // Trigger the download
                    link.remove(); // Cleanup
                })
                .catch((error) => {
                    console.error('Error downloading file:', error);
                });
        }
    };

    const handleResize = (index: number) => {
        if (resizingColumnIndex.current !== null) {
            setTableData((prevTableData) => {
                const newColumns = [...prevTableData];
                const column = newColumns[index];
                column.width = resizingColumnIndex.current;
                return newColumns;
            })
        }
    }

    const handleSort = (index: number) => {
        const currentColumn = columns[index];

        if (currentColumn.ASC === undefined) {
            setColumns((prevColumns) => {
                let newColumns = [...prevColumns];
                newColumns[index].ASC = true;
                return newColumns;
            })
            setTableData((prevTableData: any) => {
                return prevTableData.sort((a: any, b: any) => {
                    if (a[currentColumn.name] === null && b[currentColumn.name] === null) return 0;
                    if (a[currentColumn.name] === null) return -1;  // Put nulls first
                    if (b[currentColumn.name] === null) return 1;   // Put nulls first

                    if (typeof a[currentColumn.name] === 'string') {
                        return a[currentColumn.name].localeCompare(b[currentColumn.name]);
                    }
                    if (typeof a[currentColumn.name] === 'number') {
                        return a[currentColumn.name] - b[currentColumn.name];
                    }
                    return a[currentColumn.name].localeCompare(b[currentColumn.name]);
                });
            })
        } else if (currentColumn.ASC === true) {
            setColumns((prevColumns) => {
                let newColumns = [...prevColumns];
                newColumns[index].ASC = false;
                return newColumns;
            })
            setTableData((prevTableData: any) => {
                return prevTableData.sort((a: any, b: any) => {
                    if (a[currentColumn.name] === null && b[currentColumn.name] === null) return 0;
                    if (a[currentColumn.name] === null) return -1;  // Put nulls first
                    if (b[currentColumn.name] === null) return 1;   // Put nulls first

                    if (typeof a[currentColumn.name] === 'string') {
                        return b[currentColumn.name].localeCompare(a[currentColumn.name]);
                    }
                    if (typeof a[currentColumn.name] === 'number') {
                        return b[currentColumn.name] - a[currentColumn.name];
                    }
                    return b[currentColumn.name].localeCompare(a[currentColumn.name]);
                });
            })
        } else if (currentColumn.ASC === false) {
            setColumns((prevColumns) => {
                let newColumns = [...prevColumns];
                newColumns[index].ASC = undefined;
                return newColumns;
            })
            setTableData((prevTableData: any) => {
                return prevTableData.sort((a: any, b: any) => {
                    if (a[currentColumn.name] === null && b[currentColumn.name] === null) return 0;
                    if (a[currentColumn.name] === null) return -1;  // Put nulls first
                    if (b[currentColumn.name] === null) return 1;   // Put nulls first

                    if (typeof a[currentColumn.name] === 'string') {
                        return b[currentColumn.name].localeCompare(a[currentColumn.name]);
                    }
                    if (typeof a[currentColumn.name] === 'number') {
                        return b[currentColumn.name] - a[currentColumn.name];
                    }
                    return b[currentColumn.name].localeCompare(a[currentColumn.name]);
                });
            })
        }

    }

    // Handle checkbox toggle
    const handleCheck = (value: string) => {
        if (selectedItems.includes(value)) {
            setSelectedItems(selectedItems.filter((item) => item !== value));
        } else {
            setSelectedItems([...selectedItems, value]);
        }
    };
    const setFilteredData = (data: any) => {
        setTableData(data.filter((row: any) => {
            // Check all conditions for this row
            const isReceivedDateValid =
                searchTerm.start_received_date === '' && searchTerm.end_received_date === '' ? true :
                    searchTerm.start_received_date === '' ? row.received_date <= searchTerm.end_received_date :
                        searchTerm.end_received_date === '' ? row.received_date >= searchTerm.start_received_date :
                            row.received_date >= searchTerm.start_received_date && row.received_date <= searchTerm.end_received_date;

            const isSupplierValid = searchTerm.supplier === '' ? true : row.supplier.toLowerCase().includes(searchTerm.supplier.toLowerCase());
            const isCategoryValid = searchTerm.category === '' ? true : row.category.toLowerCase().includes(searchTerm.category.toLowerCase());
            const isStatusValid = searchTerm.status === '' ? true : row.status.toLowerCase().includes(searchTerm.status.toLowerCase());
            const isPoValid = searchTerm.po === '' ? true : row.po.toLowerCase().includes(searchTerm.po.toLowerCase());

            // Return true if all conditions are valid, otherwise false
            return isReceivedDateValid && isSupplierValid && isCategoryValid && isStatusValid && isPoValid;
        }));
    }

    const handleModalOpen = () => {
        setShowModal(true);
    }
    const handleFilter = () => {
        setShowModal(false);
        setFilteredData(mainData);
    }
    const handleRowColorChange = (index: number) => {
        setActiveRow(index);
    }
    return (
        <div>
            <Modal
                opened={showModal}
                onClose={() => setShowModal(false)}
                centered
                withCloseButton={false}
                size="lg"
            >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '10px', padding: '20px' }}>
                    <Grid>
                        <Grid.Col span={12}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                                <Text fw={500} c="#black" size='md' >Received Date:</Text>
                                <Input type='date' placeholder="start date" value={searchTerm.start_received_date} onChange={(e) => setSearchTerm({ ...searchTerm, start_received_date: e.target.value })} />
                                <Input type='date' placeholder="end date" value={searchTerm.end_received_date} onChange={(e) => setSearchTerm({ ...searchTerm, end_received_date: e.target.value })} />
                            </div>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput title='Supplier' placeholder="Search by supplier" value={searchTerm.supplier} onChange={(e) => setSearchTerm({ ...searchTerm, supplier: e.target.value })} />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput title='Category' placeholder="Search by category" value={searchTerm.category} onChange={(e) => setSearchTerm({ ...searchTerm, category: e.target.value })} />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput title='Status' placeholder="Search by status" value={searchTerm.status} onChange={(e) => setSearchTerm({ ...searchTerm, status: e.target.value })} />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput title='PO' placeholder="Search by PO" value={searchTerm.po} onChange={(e) => setSearchTerm({ ...searchTerm, po: e.target.value })} />
                        </Grid.Col>

                    </Grid>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: '10px', paddingTop: '30px' }}>
                        <Button variant="light" style={{ color: theme.colors.gray[0], backgroundColor: theme.colors.gray[8] }} onClick={() => setSearchTerm(initalSearch)}><IconClearAll />Clear</Button>
                        <Button variant="light" style={{ color: theme.colors.gray[0], backgroundColor: theme.colors.gray[8] }} onClick={handleFilter}>Apply</Button>
                    </div>
                </div>
            </Modal>
            <NavBar />
            <div className={'main'}>
                <h2 className={'title'}>Sheet</h2>
                {tableData.length !== 0 ? (
                    <div>
                        <div className={classes.main}>
                            <Table
                                id={'table'}
                                verticalSpacing={0.}
                                // horizontalSpacing="sm"
                                highlightOnHover
                                withRowBorders
                                withColumnBorders
                                withTableBorder
                                className={classes.table}
                            >
                                <Table.Thead>
                                    <Table.Tr className={classes.thead}>
                                        {columns.map((column, index) => (
                                            selectedItems.includes(column.name) &&
                                            <Table.Th key={index} className={classes.th} style={{ width: column.width, color: theme.colors.gray[1] }} onClick={() => handleSort(index)}>
                                                {column.title} {column.ASC === true ? <IconArrowUp className={classes.iconHeader} /> : column.ASC === false ? <IconArrowDown className={classes.iconHeader} /> : null}
                                                <div
                                                    className="resizer"
                                                    onMouseDown={(e) => handleMouseDown(index, e)}
                                                    onResize={() => handleResize(index)}
                                                />
                                            </Table.Th>
                                        ))}
                                        {selectedItems.length > 0 && (<th className={classes.th} style={{ color: '#black', borderLeft: '1px solid #black' }}>
                                            Delete
                                        </th>)}
                                    </Table.Tr>
                                </Table.Thead>
                                <tbody className={classes.tbody}>
                                    {tableData.map((item: any, index: number) => (
                                        <Table.Tr className={item.id ? classes.tr : classes.trNew} style={{ backgroundColor: activeRow === index ? theme.colors.gray[1] : '' }} key={index} >
                                            {selectedItems.includes('received_date') && (<Table.Td className={classes.cell} >
                                                <Input onFocus={(e) => handleRowColorChange(index)} size='17px' type='date' value={formatDate(item.received_date)} onChange={(e) => { handleDataChange(index, 'received_date', e.target.value) }} />
                                            </Table.Td>)}
                                            {selectedItems.includes('supplier') && (<Table.Td className={classes.cell} >
                                                <Input onFocus={(e) => handleRowColorChange(index)} size='17px' value={item.supplier} onChange={(e) => { handleDataChange(index, 'supplier', e.target.value) }} />
                                            </Table.Td>)}
                                            {selectedItems.includes('skid_count') && (<Table.Td className={classes.cell} >
                                                <Input onFocus={(e) => handleRowColorChange(index)} size='17px' type='number' value={item.skid_count || 0} onChange={(e) => { handleDataChange(index, 'skid_count', e.target.value) }} />
                                            </Table.Td>)}
                                            {selectedItems.includes('condensed_skid_count') && (<Table.Td className={classes.cell} >
                                                <Input onFocus={(e) => handleRowColorChange(index)} size='17px' type='number' value={item.condensed_skid_count} onChange={(e) => { handleDataChange(index, 'condensed_skid_count', e.target.value) }} />
                                            </Table.Td>)}
                                            {selectedItems.includes('category') && (<Table.Td className={classes.cell} >
                                                <Select
                                                    onFocus={(e) => handleRowColorChange(index)}
                                                    placeholder="Category"
                                                    searchable
                                                    value={item.category}
                                                    data={[
                                                        { value: 'FTWR', label: 'FTWR' },
                                                        { value: 'App', label: 'App' },
                                                        { value: 'Acc', label: 'Acc' },
                                                        { value: 'Other', label: 'Other' },
                                                    ]}
                                                    className={classes.selectDropdown}
                                                    onChange={(e) => { handleDataChange(index, 'category', e) }}
                                                />
                                            </Table.Td>)}
                                            {selectedItems.includes('po') && (<Table.Td className={classes.cell} >
                                                <Input onFocus={(e) => handleRowColorChange(index)} size='17px' value={item.po} onChange={(e) => { handleDataChange(index, 'po', e.target.value) }} />
                                            </Table.Td>)}
                                            {selectedItems.includes('status') && (<Table.Td className={classes.cell} >
                                                <Select
                                                    onFocus={(e) => handleRowColorChange(index)}
                                                    size='17px'
                                                    value={item.status}
                                                    searchable
                                                    onChange={(e) => { handleDataChange(index, 'status', e) }}
                                                    data={[
                                                        { value: 'Pending', label: 'Pending' },
                                                        { value: 'In Process', label: 'In Process' },
                                                        { value: 'Done', label: 'Done' },
                                                    ]}
                                                />
                                            </Table.Td>)}
                                            {selectedItems.includes('notes') && (<Table.Td className={classes.cell} >
                                                <Input size='17px' value={item.notes} onFocus={(e) => handleRowColorChange(index)} onChange={(e) => { handleDataChange(index, 'notes', e.target.value) }} />
                                            </Table.Td>)}
                                            {selectedItems.includes('bin') && (<Table.Td className={classes.cell} >
                                                <Input onFocus={(e) => handleRowColorChange(index)} size='17px' value={item.bin} onChange={(e) => { handleDataChange(index, 'bin', e.target.value) }} />
                                            </Table.Td>)}
                                            {selectedItems.includes('invoice_date') && (<Table.Td className={classes.cell} >
                                                <Input onFocus={(e) => handleRowColorChange(index)} size='17px' type='date' value={formatDate(item.invoice_date)} onChange={(e) => { handleDataChange(index, 'invoice_date', e.target.value) }} />
                                            </Table.Td>)}
                                            {selectedItems.includes('invoice_number') && (<Table.Td className={classes.cell} >
                                                <Input onFocus={(e) => handleRowColorChange(index)} size='17px' type='text' value={item.invoice_number} onChange={(e) => { handleDataChange(index, 'invoice_number', e.target.value) }} />
                                            </Table.Td>)}
                                            {selectedItems.includes('invoice_qty') && (<Table.Td className={classes.cell} >
                                                <Input onFocus={(e) => handleRowColorChange(index)} size='17px' type='number' value={item.invoice_qty} onChange={(e) => { handleDataChange(index, 'invoice_qty', e.target.value) }} />
                                            </Table.Td>)}
                                            {selectedItems.includes('invoice_value') && (<Table.Td className={classes.cell} >
                                                <Input onFocus={(e) => handleRowColorChange(index)} size='17px' type='number' value={item.invoice_value} onChange={(e) => { handleDataChange(index, 'invoice_value', e.target.value) }} />
                                            </Table.Td>)}
                                            {selectedItems.includes('due_date') && (<Table.Td className={classes.cell} >
                                                <Input onFocus={(e) => handleRowColorChange(index)} size='17px' type='date' value={formatDate(item.due_date)} onChange={(e) => { handleDataChange(index, 'due_date', e.target.value) }} />
                                            </Table.Td>)}
                                            {selectedItems.includes('submitted_date') && (<Table.Td className={classes.cell} >
                                                <Input onFocus={(e) => handleRowColorChange(index)} size='17px' type='date' value={formatDate(item.submitted_date)} onChange={(e) => { handleDataChange(index, 'submitted_date', e.target.value) }} />
                                            </Table.Td>)}
                                            {selectedItems.includes('invoice_files') && (<Table.Td className={classes.cell} >
                                                <Input onFocus={(e) => handleRowColorChange(index)} size='10px'
                                                    type='file'
                                                    multiple
                                                    onChange={(e) => handleFileChange(index, e.target.files)}
                                                    className={classes.fileInput}
                                                />
                                                {item.invoice_files?.length ? (
                                                    openedRow === index ? <IconChevronUp className={classes.iconSmall} onClick={() => setOpenedRow(null)} /> : <IconChevronDown className={classes.iconSmall} onClick={() => setOpenedRow(index)} />) : null}
                                                <Collapse in={openedRow === index}>
                                                    <ul className={classes.fileList}>
                                                        {item.invoice_files && Array.from(item.invoice_files)?.map((file: any, fileIndex: number) => {
                                                            return (
                                                                <li key={fileIndex} className={classes.fileListItem}>
                                                                    <div className={classes.fileName} onClick={() => handleDownloadFile(index, fileIndex)}>
                                                                        {file.name ? file.name : file}
                                                                    </div>
                                                                    <IconX className={classes.icon} onClick={() => handleDeleteFile(index, fileIndex)} />
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </Collapse>
                                            </Table.Td>)}
                                            {selectedItems.length > 0 && (<Table.Td className={classes.cell} style={{ border: `1px solid ${theme.colors.gray[7]}` }}>
                                                <IconTrash className={classes.icon} onClick={() => handleDeleteRow(index, item.id as number)} />
                                            </Table.Td>)}
                                        </Table.Tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        <div className={classes.fixed}>
                            <Button style={{ backgroundColor: theme.colors.gray[9] }} size='xs' onClick={handleModalOpen}><IconFilter /> Filter </Button>
                            <Menu closeOnItemClick={false}>
                                <Menu.Target>
                                    <Button style={{ backgroundColor: theme.colors.gray[9] }} size='xs'><IconColumns />Columns</Button>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <Menu.Item key='all'>
                                        <div style={{ fontWeight: 'bold', backgroundColor: '#black' }}>
                                            <Checkbox
                                                label='All'
                                                checked={selectedItems.length === columns.length}
                                                color='green'
                                                onChange={() => selectedItems.length === columns.length ? setSelectedItems([]) : setSelectedItems(columns.map((item: TableColumn) => item.name))}
                                            />
                                        </div>
                                    </Menu.Item>
                                    {columns && columns.map((item: TableColumn, index: number) => (
                                        <Menu.Item key={item.name}>
                                            <Checkbox
                                                label={item.title}
                                                checked={selectedItems.includes(item.name)}
                                                color='green'
                                                onChange={() => handleCheck(item.name)}
                                            />
                                        </Menu.Item>
                                    ))}
                                </Menu.Dropdown>
                            </Menu>
                            {selectedItems.length > 0 && (<IconDeviceFloppy className={classes.icon} style={{ color: theme.colors.gray[9] }} onClick={() => handleSave(tableData)} />)}
                            <IconPlus className={classes.icon} style={{ color: theme.colors.gray[9] }} onClick={handleAddRow} />
                        </div>
                    </div>) : (
                    <div style={{ display: 'flex', marginTop: '50px', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '10px', paddingTop: '30px' }}>
                        <Loader color='black' size="xl" />
                        <Text>Getting sheet data </Text>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Sheet;