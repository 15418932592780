import { Button, Grid, Modal, PasswordInput, Select, Table, TextInput, useMantineTheme } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NavBar from '../../components/NavBar/NavBar';
import classes from './Stats.module.css';
import UserModalProps from '../Admin/userModal';

const Stats: React.FC = () => {
    const [userList, setUserList] = useState([]);
    const [password, setPassword] = useState<{ password: string, confirmPassword: string }>({ password: '', confirmPassword: '' })
    const [userModal, setUserModal] = useState(false);
    const [user, setUser] = useState({} as UserModalProps);
    const theme = useMantineTheme();
    const token = useSelector((state: any) => state.user.sessionId);

    const getData = async () => {
        try {
            const response = await fetch(`/allocation/stats`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            const { data, message } = await response.json();
            if (response.status !== 200) {
                console.log('Unable to get the data.');
                return;
            }
            setUserList(data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const handleModify = async (user: UserModalProps) => {
        await setUser(user);
        setUserModal(true);
    }

    return (
        <>
            <NavBar />
            <div className={'main'}>
                <h2 className={'title'}>Stats</h2>
                <Grid >
                    {userList.length > 0 && (<Grid.Col span={12} >
                        <Table verticalSpacing="xs" className={classes.table} striped highlightOnHover stickyHeader>
                            <Table.Thead className={classes.thead} style={{ width: '100%', color: theme.colors.gray[0], backgroundColor: theme.colors.gray[9] }} >
                                <Table.Tr>
                                    <Table.Th>First Name</Table.Th>
                                    <Table.Th>Email</Table.Th>
                                    <Table.Th>Scope</Table.Th>
                                    <Table.Th>Total Styles</Table.Th>
                                    <Table.Th>Total Quantity</Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody className={classes.tbody}>
                                {userList.map((user: any, index: number) => (
                                    <Table.Tr key={index}>
                                        <Table.Td>{user.first_name}</Table.Td>
                                        <Table.Td>{user.email}</Table.Td>
                                        <Table.Td>{user.scope}</Table.Td>
                                        <Table.Td>{user.allocation_count}</Table.Td>
                                        <Table.Td>{user.total_qty}</Table.Td>
                                    </Table.Tr>
                                ))}
                            </Table.Tbody>
                        </Table>
                    </Grid.Col>)}
                </Grid>
                {/* <div className={classes.footer} style={{ backgroundColor: theme.colors.gray[1], color: theme.colors.gray[0], border: `1px solid ${theme.colors.gray[0]}` }}>
                    <Button variant="subtle" color="gray" size="xs" style={{ color: theme.colors.gray[10], backgroundColor: theme.colors.gray[1] }}>
                        <IconPlus size={21} className={classes.icon} style={{ marginRight: '20px' }} />
                        Add User
                    </Button>
                </div> */}
            </div>
        </>
    )
};

export default Stats;