import { Button } from '@mantine/core';
import pdfMake from "pdfmake/build/pdfmake";
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import React from 'react';
import { BolData } from '../../interface/BillOfLading';
import { IconPrinter } from '@tabler/icons-react';
import { useSelector } from 'react-redux';
import { getShippingDetails } from '../../Utils/utils';
// client\public\svp_sports_drk_blue.png
interface BolPrintProps {
    bol: BolData;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    variant?: 'default' | 'outline' | 'light' | 'filled';
}
const convertImageToBase64 = async (url: string): Promise<string> => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

const BolPrint: React.FC<BolPrintProps> = ({ bol, size, variant }) => {
    const logo = './letter_head_final.png';
    const user = useSelector((state: any) => state.user.user);
    // const logo = './letter_head.png';
    const handlePrintHash = async () => {
        try {
            if (!bol) {
                alert('Can not find the data.');
                return;
            }
            generatePDF();
        } catch (error) {
            console.log(`Error :`, error);
        }
    }

    const generatePDF = async () => {
        const imageDataUrl = await convertImageToBase64(logo);
        const shippingDetails = getShippingDetails(user.location);
        const noOfRows = (bol.po?.length ?? 0) < 3 ? 3 : (bol.po?.length ?? 0) > 5 ? 5 : (bol.po?.length ?? 0);
        let contentData: Content[] = [
            {
                // Add a rectangle to create a border around the page
                canvas: [
                    {
                        type: 'rect',
                        x: 0,
                        y: 0,
                        w: 600, // Width of the page in points (A4 size)
                        h: 780, // Height of the page in points (A4 size)
                        lineColor: 'black',
                        lineWidth: 1,
                        // fillColor: 'blue' // Set to null for no fill
                    }
                ]
            },
            {
                columns: [
                    {
                        image: imageDataUrl,
                        alignment: 'left',
                        margin: [0, 0, 0, 0],
                        fit: [65, 200], // Add this property to specify the image size
                        style: 'redBackground'
                    },
                    {
                        table: {
                            widths: [200],
                            body: [
                                [{ text: `BILL OF LADING `, fontSize: 15, bold: true, color: '#04162e' }],
                                [{ text: shippingDetails.address, fontSize: 10, margin: [0, -8, 0, 0],color: '#04162e' }],
                                [{ text: `Email:${shippingDetails.email}`, fontSize: 10, margin: [0, -7, 0, 0],color: '#04162e' }],
                            ],
                        }, layout:{
                            defaultBorder: false,
                        },margin: [-85, -3, 0, 0],
                    },{
                        table: {
                            headerRows: 1,
                            widths: [200, 100],
                            body: [
                                [{ text: `B OF L NO. `, style: 'header', border: true, borderColor: 'white', borderWidth: 3 },
                                { text: `DATE:`, style: 'header', border: true, borderColor: 'white', borderWidth: 3 }],
                                [{ text: bol.bol_no, alignment: 'left',fontSize: 12, style: 'body' },
                                { text: bol.bol_date?.split('T')[0],fontSize: 12, style: 'body' }],
                            ],
                        },
                        alignment: 'right',
                        width: 300,
                        margin: [-21, 0, 0, 0],
                        style: 'table'
                    },
                ],
                margin: [0, -780, 0, 0],
            },
            {
                table: {
                    headerRows: 1,
                    widths: [300, 281],
                    body: [
                        [{ text: `SHIPPER `, style: 'header', border: true, borderColor: 'white', borderWidth: 3 },
                        { text: `SHIP TO`, style: 'header', border: true, borderColor: 'white', borderWidth: 3 }],
                        [
                            {
                                table: {
                                    headerRows: 1,
                                    widths: [80, 205],
                                    body: [
                                        [{ text: 'NAME', style: 'title' }, { text: bol.shipper?.name, style: 'data' }],
                                        [{ text: 'ADDRESS', style: 'title' }, { text: bol.shipper?.address, style: 'data' }],
                                        [{ text: 'POSTAL CODE', style: 'title' }, { text: bol.shipper?.postal_code, style: 'data' }],
                                        [{ text: 'SID NO', style: 'title' }, { text: bol.shipper?.sid_no, style: 'datay' }]
                                    ]
                                },
                                margin: [-2, -2, 0, 0]
                            },
                            {
                                table: {
                                    headerRows: 1,
                                    widths: [80, 187],
                                    body: [
                                        [{ text: 'NAME', style: 'title' }, { text: bol.receiver?.name, style: 'data' }],
                                        [{ text: 'ADDRESS', style: 'title' }, { text: bol.receiver?.address, style: 'data' }],
                                        [{ text: 'POSTAL CODE', style: 'title' }, { text: bol.receiver?.postal_code, style: 'data' }],
                                        [{ text: 'CID NO', style: 'title' }, { text: bol.receiver?.cid_no, style: 'datay' }]
                                    ]
                                },
                                margin: [-2, -2, 0, 0]
                            }
                        ],
                        [{
                            table: {
                                headerRows: 1,
                                widths: [80, 205],
                                body: [
                                    [{ text: 'THIRD PARTY FREIGHT CHARGES BILL TO', style: 'title', alignment: 'center', colSpan: 2 }, {}],
                                    [{ text: 'NAME', style: 'title' }, { text: bol.third_party?.name, style: 'data' }],
                                    [{ text: 'ADDRESS', style: 'title' }, { text: bol.third_party?.address, style: 'data' }],
                                    [{ text: 'POSTAL CODE', style: 'title' }, { text: bol.third_party?.postal_code, style: 'data' }],
                                    [{ text: 'TELEPHONE', style: 'title' }, { text: bol.third_party?.telephone, style: 'datay' }]
                                ]
                            },
                            margin: [-2, -3, 0, 0]
                        }, {
                            table: {
                                widths: [80, 187],
                                body: [
                                    [{ text: 'CARRIER NAME', style: 'title' }, { text: bol.trailer, style: 'data' }],
                                    [{ text: 'TRAILER NO.', style: 'title' }, { text: '', style: 'data' }],
                                    [{ text: 'SERIAL NO', style: 'title' }, { text: '', style: 'data' }],
                                    [{ text: 'SCAC', style: 'title' }, { text: '', style: 'data' }],
                                    // loop 2 times and add the data if not then blank join the po_no with /
                                    [{ text: 'PO NO.', style: 'title' }, { text: `${(bol?.po?.length ?? 0) > 1 ? ' ' : `PO # ${bol.po?.[0]?.po_no || ''}`} `, style: 'data', fontSize: 12, bold: true }]
                                ]
                            },
                            margin: [-2, -3, 0, 0]
                        }],
                        [{
                            table: {
                                headerRows: 1,
                                widths: [294],
                                heights: [10, 43],
                                body: [
                                    [{ text: 'SPECIAL INSTRUCTIONS', style: 'title', alignment: 'center' }],
                                    [{ text: bol.note, style: 'data', alignment: 'left', color: 'red' },],
                                ]
                            },
                            margin: [-2, -4, 0, 0]
                        }, {
                            table: {
                                widths: [80, 80, 98],
                                body: [
                                    [{ text: [{ text: 'FREIGHT CHARGE TERMS \n', style: 'title' }, { text: 'Freight charges prepaid unless marked otherwise', fontSize: 8, style: 'title' }], style: 'title', colSpan: 3 }, {}, {}],
                                    [{
                                        table: {
                                            headerRows: 1,
                                            widths: [50, 20],
                                            body: [
                                                [{ text: 'PREPAID', style: 'title' }, { text: '' }],
                                            ],
                                            layout: 'noBorders'
                                        },
                                        margin: [-4, 0, 0, 0],
                                        border: false
                                    }, {
                                        table: {
                                            headerRows: 1,
                                            widths: [50, 20],
                                            body: [
                                                [{ text: 'COLLECT', style: 'title' }, { text: '', border: false }],
                                            ]
                                        },
                                        margin: [-4, 0, 0, 0],
                                        border: false
                                    }, {
                                        table: {
                                            headerRows: 1,
                                            widths: [70, 18],
                                            body: [
                                                [{ text: 'THIRD PARTY', style: 'title' }, { text: '', border: false }],
                                            ]
                                        },
                                        margin: [-4, 0, 0, 0],
                                        border: false
                                    }],
                                    [{ text: 'Master bill of lading with attached underlying bills of', colSpan: 3, fillColor: '#cfcfcf', fontSize: 10 }, {}, {}]
                                ]
                            },
                            margin: [-2, -4, 0, 0]
                        }],
                        [{
                            table: {
                                headerRows: 1,
                                widths: [180, 60, 38, 38, 34, 190],
                                body: [
                                    [{ text: 'CUSTOMER ORDER NO.', style: 'title' },
                                    { text: 'NO OF SKIDS', style: 'title' },
                                    { text: 'WEIGHT/PALLET', style: 'title', colSpan: 2 },
                                    { text: 'PALLET', style: 'title' },
                                    { text: 'SLIP', style: 'title' },
                                    { text: 'ADDITIONAL SHIPPER INFO', style: 'title' }
                                    ],
                                    ...Array(noOfRows).fill(null).map((_, index) => [
                                        { text: `${bol.po?.[index]?.po_no || ''}`, style: 'data' },
                                        { text: `${bol.po?.[index]?.skid || ''}`, style: 'data' },
                                        { text: '', style: 'data' },
                                        { text: 'Y', style: 'data' },
                                        { text: 'N', style: 'data' },
                                        { text: '', style: 'data' },
                                    ]),
                                    [{ text: 'TOTAL', style: 'title' },
                                    { text: (bol.po?.reduce((acc: number, po: { skid: number }) => acc + po.skid, 0) || 0).toString(), style: 'data' },
                                    { text: '', style: 'data' },
                                    { text: '', style: 'data', colSpan: 3, fillColor: '#cfcfcf' },
                                    { text: '', style: 'data' },
                                    { text: '', style: 'data' }
                                    ]
                                ]
                            }, margin: [-2, -4, 0, 0], alignment: 'center', colSpan: 2
                        }, {}], [
                            {
                                table: {
                                    headerRows: 1,
                                    widths: [60, 40, 30, 30, 30, 40, 192, 50, 40],
                                    body: [
                                        [{ text: 'STYLE', style: 'title' },
                                        { text: 'TYPE', style: 'title' },
                                        { text: 'QTY', style: 'title' },
                                        { text: 'TYPE', style: 'title' },
                                        { text: 'WGT', style: 'title' },
                                        { text: 'HM(X)', style: 'title' },
                                        { text: 'DESCRIPTION OF ARTICLES, SPECIAL MARKS & EXCEPTIONS', style: 'title' },
                                        { text: 'NMFC NO.', style: 'title' },
                                        { text: 'CLASS', style: 'title' },
                                        ],
                                        [
                                            { text: '', style: 'data' },
                                            { text: '', style: 'data' },
                                            { text: '', style: 'data' },
                                            { text: '', style: 'data' },
                                            { text: '', style: 'data' },
                                            { text: '', style: 'data' },
                                            { text: bol.type || ' ', style: 'data', bold: true },
                                            { text: '', style: 'data' },
                                            { text: '', style: 'data' },
                                        ],
                                        [
                                            { text: '', style: 'data' },
                                            { text: '', style: 'data' },
                                            { text: '', style: 'data' },
                                            { text: '', style: 'data' },
                                            { text: '', style: 'data' },
                                            { text: '', style: 'data' },
                                            { text: `TOTAL SKIDS: ${bol.po?.reduce((acc: number, po: { skid: number }) => acc + po.skid, 0) || 0}`, style: 'data' },
                                            { text: '', style: 'data' },
                                            { text: '', style: 'data' },
                                        ],
                                        [
                                            { text: '', style: 'data' },
                                            { text: '', style: 'data' },
                                            { text: '', style: 'data' },
                                            { text: '', style: 'data' },
                                            { text: '', style: 'data' },
                                            { text: '', style: 'data' },
                                            { text: bol.description || ' ', style: 'data' },
                                            { text: '', style: 'data' },
                                            { text: '', style: 'data' },
                                        ]
                                    ]
                                },
                                margin: [-2, -4, 0, 0], alignment: 'center', colSpan: 2
                            }, {}
                        ], [
                            {
                                text: `Where the rate is dependent on value, shippers are required to state specifically in writing the agreed or declared value of the property as follows: "The agreed or declared value of the property is specifaically stated by the shipper to be not exceeding _____________per ____________.`,
                                margin: [-2, -4, 0, 0], alignment: 'justify', fontSize: 10, border: [true, true, true, true]
                            }, {
                                table: {
                                    headerRows: 1,
                                    widths: [70, 70, 120],
                                    body: [
                                        [{ text: 'COD AMOUNT', style: 'title' }, { text: `$${bol.amount}`, style: 'data', colSpan: 2 }, {}],
                                        [{ text: 'FEE TERMS', style: 'title', colSpan: 3 }, {}, {}],
                                        [{
                                            table: {
                                                widths: [50, 30],
                                                body: [
                                                    [{ text: 'COLLECT', style: 'title' }, { text: `  `, style: 'data' }]
                                                ]
                                            },
                                            margin: [-4, 0, 0, 0], height: 10, layout: {
                                                defaultBorder: false,
                                            }
                                        },
                                        {
                                            table: {
                                                widths: [50, 15],
                                                body: [
                                                    [{ text: 'PREPAID', style: 'title' }, { text: `  `, style: 'data' }]
                                                ]
                                            },
                                            margin: [-4, 0, 0, 0], height: 15, layout: {
                                                defaultBorder: false,
                                            }
                                        },
                                        {
                                            table: {
                                                widths: [95, 15],
                                                body: [
                                                    [{ text: 'CUSTOMER CHECK', style: 'title' }, { text: `  `, style: 'data' }]
                                                ]
                                            },
                                            margin: [-4, 0, 0, 0], height: 20, layout: {
                                                defaultBorder: false,
                                            }
                                        }],
                                    ]
                                },
                                margin: [-2, -4, 0, 0], alignment: 'center',
                            }
                        ], [
                            {
                                text: `NOTE: Liability limitation for loss or damage in this shipment may be applicable. See 49 USC § 14706(c)(1)(A) and (B).`,
                                margin: [-2, 0, 0, 0], alignment: 'center', fontSize: 9, border: [true, true, true, true], colSpan: 2, fillColor: '#cfcfcf', bold: true
                            }, {}
                        ], [
                            {
                                text: `Received, subject to individually detemined rates or contracts that have been agreed upon in writing between the carrier and shipper, if applicable, otherwise to thte rates, classifications, and rules that have been established by the carrier and are available to the shipper, on request, and to all applicable state and federal regulations.`,
                                margin: [-2, 0, 0, 0], alignment: 'justify', fontSize: 10, border: [false, false, true, false]
                            }, {
                                table: {
                                    widths: [280],
                                    body: [
                                        [{ text: 'The carrier shall not make delivery of this shipment without payment of charges and all other lawful fees.', fontSize: 10 }],
                                        [{ text: 'SHIPPER SIGNATURE', style: 'title' }],
                                        [{ text: ' ', border: [false, false, false, false], height: 30 }]
                                    ]
                                },
                                margin: [-4, -2, 0, 0], alignment: 'center', layout: {
                                    defaultBorder: false,
                                }
                            }
                        ], [
                            {
                                table: {
                                    widths: [200, 200, 170],
                                    body: [
                                        [{ text: 'SHIPPER SIGNATURE & DATE', style: 'title' }, { text: 'CARRIER SIGNATURE & PICK-UP DATE', style: 'title' }, { text: 'TRAILER LOADED', style: 'title' }],
                                        [{ text: ' ' }, { text: ' ' },
                                        {
                                            table: {
                                                widths: [140, 20],
                                                body: [
                                                    [{ text: 'BY SHIPPER', style: 'data', fillColor: '#cfcfcf', fontSize: 10, border: [false, false, true, true] }, { text: ' ', style: 'data', border: [false, false, true, true] }],
                                                    [{ text: 'BY DRIVER', style: 'data', fillColor: '#cfcfcf', fontSize: 10, border: [false, false, true, true] }, { text: ' ', style: 'data', border: [false, false, true, true] }]
                                                ],
                                            },
                                            layout: {
                                                defaultBorder: false,
                                            },
                                            margin: [-4, -2, 0, 0],
                                        }],
                                        [{ text: `(${bol.bol_date?.split('T')[0]})`, alignment: 'center', fontSize: 20, bold: true }, { text: `(${bol.bol_date?.split('T')[0]})`, alignment: 'center', fontSize: 20, bold: true },
                                        {
                                            table: {
                                                widths: [140, 20],
                                                body: [
                                                    [{ text: 'FREIGHT COUNTED', style: 'title', border: [false, false, true, true], colSpan: 2 }, {}],
                                                    [{ text: 'BY SHIPPER', style: 'data', fillColor: '#cfcfcf', fontSize: 10, border: [false, false, true, true] }, { text: ' ', style: 'data', border: [false, false, true, true] }],
                                                    [{ text: 'BY DRIVER/PALLETS SAID TO CONTAIN', style: 'data', fillColor: '#cfcfcf', fontSize: 10, border: [false, false, true, true] }, { text: ' ', style: 'data', border: [false, false, true, true] }],
                                                    [{ text: 'BY DRIVER PIECES', style: 'data', fillColor: '#cfcfcf', fontSize: 10, border: [false, false, true, true] }, { text: ' ', style: 'data', border: [false, false, true, true] }]
                                                ],
                                            },
                                            layout: {
                                                defaultBorder: false,
                                            },
                                            margin: [-4, -2, 0, 0],
                                        }],
                                    ],

                                },
                                margin: [-4, -2, 0, 0], alignment: 'center',
                                layout: {
                                    defaultBorder: true,
                                }, colSpan: 2,
                            }, {}
                        ]
                    ],
                },
                margin: [0, -4, 0, 0],
                layout: {
                    defaultBorder: false,
                }
            },
        ];

        // border the entire page
        const docDefinition: TDocumentDefinitions = {
            content: contentData,
            pageSize: 'LETTER',
            pageMargins: [5, 5, 5, 5],
            pageOrientation: 'portrait',
            styles: {
                tabe: {
                },
                header: {
                    fillColor: '#04162e',
                    color: 'white',
                    fontSize: 10,
                    bold: true,
                    alignment: 'center'
                },
                body: {
                    color: 'black',
                    fontSize: 15,
                    alignment: 'center'
                },
                title: {
                    fillColor: '#04162e',
                    color: 'white',
                    fontSize: 10,
                    bold: true,
                    alignment: 'left'
                },
                data: {
                    fontSize: 10,
                    bold: true,
                    alignment: 'left'
                }
            },
            background: [
                {
                    // Draw a border around the page
                    canvas: [
                        {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 500,  // A4 width
                            h: 800,  // A4 height
                            lineWidth: 5,
                            lineColor: 'black',
                            fillOpacity: 0,
                        }
                    ]
                }
            ]
        };

        pdfMake.createPdf(docDefinition).open();
    };

    return (
        <Button className={'btn'} onClick={handlePrintHash} size={size || 'sm'} variant={variant || 'default'}> <IconPrinter /></Button>
    )
}

export default BolPrint;