import {
    IconCalculator,
    IconNotes,
    IconReport,
    IconTransfer,
    IconTruck,
    IconUser,
    IconUserShield
} from '@tabler/icons-react';

export const MENU_ITEMS = [
    {
        label: 'Calculator',
        icon: IconCalculator,
        links: [
            { label: 'Allocation', link: '/allocation', access: 'Admin' },
            { label: 'Restock', link: '/restock', access: 'Admin' },
        ],
        access: 'Admin'
    },
    {
        label: 'User',
        icon: IconUser,
        links: [
            { label: 'Floor User', link: '/floorUser', access: 'Admin' },
            { label: 'Bin', link: '/bin', access: 'Admin' },
        ],
        access: 'Admin'
    },
    // {
    //     label: 'Sheets',
    //     icon: IconReportAnalytics,
    //     links: [
    //         { label: 'Sheet', link: '/sheet', access: 'Admin' },
    //         { label: 'Log Sheet', link: '/logSheet', access: 'Admin' },
    //     ],
    //     access: 'Admin'
    // },
    {
        label: 'Operations', icon: IconNotes,
        links: [
            { label: 'Print label', link: '/printLabel', access: 'Admin' },
            { label: 'Style Alloc', link: '/styleAlloc', access: 'Admin' },
        ],
        access: 'Admin'
    },
    {
        label: 'HR', icon: IconUserShield,
        links: [
            { label: 'Manage Users', link: '/manageUsers', access: 'Hr' },
            { label: 'Stats', link: '/stats', access: 'Hr' },
            { label: 'Bill of Lading', link: '/bol', access: 'Hr' },
        ],
        access: 'Hr'
    },
    {
        label: 'BOL', icon: IconTruck,
        links: [
            { label: 'Bill of Lading', link: '/bolList', access: 'User' },
        ],
        access: 'User'
    },
    {
        label: 'Inventory',
        icon: IconTransfer,
        links: [
            { label: 'Transfer List', link: '/poList', access: 'Admin', data: { status: 'transferList' } },
            { label: 'Style List', link: '/styleList', access: 'Admin' },
            { label: 'PO Worksheet', link: '/poList', access: 'Admin', data: { status: 'poWorksheet' } },
        ],
        access: 'Admin'
    },
    {
        label: 'Admin', icon: IconReport,
        links: [
            { label: 'Users', link: '/users', access: 'SuperAdmin' },
            { label: 'Register', link: '/register', access: 'SuperAdmin' },
            { label: 'Modify Allocation', link: '/modifyAllocation', access: 'SuperAdmin' },
            { label: 'Automate Transfer', link: '/automateTransfer', access: 'SuperAdmin' },
        ],
        access: 'SuperAdmin'
    },


];

export const userAccess = ['/', '*', '/profile', '/login', '/bolList'];


export const adminAccess = [...userAccess,
    '/allocation',
    '/restock',
    '/floorUser',
    '/bin',
    '/sheet',
    '/logSheet',
    '/printLabel',
    '/styleAlloc',
    '/styleList',
    '/transferList',
    '/poWorksheet',
    '/poList',
    '/poList',
];

export const hrAccess = [...adminAccess,
    '/stats',
    '/manageUsers',
    '/bol'
];

export const superAdminAccess = [...hrAccess,
    '/users',
    '/userRoleAccess',
    '/deleteAllocation',
    '/register',
    '/modifyAllocation',
    '/automateTransfer'
];