import { Grid, Table, TextInput, useMantineTheme } from '@mantine/core';
import React, { ChangeEvent, useEffect } from 'react';
import { StoreData } from '../../interface/AllocationTable';
import classes from './AllocationTable.module.css';
interface AllocationTableProps {
    stores: { store: string, checked: boolean }[];
    setStores?: (stores: { store: string, checked: boolean }[]) => void;
    sizes: string[];
    totalProductionQuantities: number[];
    setTotalProductionQuantities: (value: number[]) => void;
    standardQuantities: number[];
    setStandardQuantities: (value: number[]) => void;
    tableData: StoreData[];
    setTableData: (value: StoreData[]) => void;
    calculateClicked: boolean;
    setCalculateClicked: (value: boolean) => void;
    calculateBtnClicked: boolean;
    totalProductionSum: number;
    setTotalProductionSum: (value: number) => void;
}
const AllocationTable: React.FC<AllocationTableProps> = ({
    stores,
    setStores,
    sizes,
    totalProductionQuantities,
    setTotalProductionQuantities,
    standardQuantities,
    setStandardQuantities,
    tableData,
    setTableData,
    calculateClicked,
    setCalculateClicked,
    calculateBtnClicked,
    totalProductionSum,
    setTotalProductionSum
}) => {
    const theme = useMantineTheme();

    useEffect(() => {
        const newSum = totalProductionQuantities.reduce((total, currentQuantity) => total + currentQuantity, 0);
        setTotalProductionSum(newSum);
    }, [totalProductionQuantities]);

    const handleProductionQuantities = (event: ChangeEvent<HTMLInputElement>, sizeIndex: number) => {
        const newTotalProductionQuantity = parseInt(event.target.value, 10) || 0;
        let newQuantities = [...totalProductionQuantities];
        newQuantities[sizeIndex] = newTotalProductionQuantity;
        setTotalProductionQuantities(newQuantities);
    };

    const handleStandardQuantityChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const newQuantity = parseInt(event.target.value, 10) || 0;
        let newQuantities = [...standardQuantities];
        newQuantities[index] = newQuantity;
        setStandardQuantities(newQuantities);
    };
    const calculateRowTotal = (rowIndex: number) => {
        return tableData[rowIndex].sizeQuantities.reduce((total, sizeData) => total + sizeData.quantity, 0);
    };
    const handleQuantityChange = (
        event: ChangeEvent<HTMLInputElement>,
        storeIndex: number,
        sizeIndex: number
    ) => {
        const newData = [...tableData];
        newData[storeIndex].sizeQuantities[sizeIndex].quantity = parseInt(
            event.target.value,
            10
        );
        setTableData(newData);
    };
    const processDiscrepancies = (storesData: StoreData[], remainingQuantities: number[]) => {
        storesData.forEach((storeData, storeIndex) => {
            const standardQuantity = standardQuantities[storeIndex];

            // Skip processing for stores with a standard quantity of zero
            if (standardQuantity === 0) return;

            storeData.sizeQuantities.forEach((sizeData, sizeIndex) => {
                // Allocate remaining sizes to stores that have not received any, ensuring all sizes are allocated
                if (remainingQuantities[sizeIndex] > 0 && sizeData.quantity === 0) {
                    let allocation = Math.min(1, remainingQuantities[sizeIndex]); // Allocate at least one unit if possible
                    sizeData.quantity += allocation;
                    remainingQuantities[sizeIndex] -= allocation;
                }
            });
        });

        // Ensure no store's allocation exceeds its standard quantity
        storesData.forEach((storeData, storeIndex) => {
            const standardQuantity = standardQuantities[storeIndex];
            // Continue to ensure no store's allocation exceeds the defined standard quantity
            if (storeData.sizeQuantities.reduce((sum, sizeData) => sum + sizeData.quantity, 0) > standardQuantity) {
                scaleDownStoreAllocation(storeData, standardQuantity);
            }
        });
    };


    const scaleDownStoreAllocation = (storeData: StoreData, standardQuantity: number) => {
        let reductionNeeded = storeData.sizeQuantities.reduce((sum, sizeData) => sum + sizeData.quantity, 0) - standardQuantity;

        if (reductionNeeded > 0) {
            const reducibleSizes = storeData.sizeQuantities.filter(sizeData => sizeData.quantity > 1);
            const totalReducibleQuantity = reducibleSizes.reduce((sum, sizeData) => sum + sizeData.quantity, 0);

            reductionNeeded = Math.min(reductionNeeded, totalReducibleQuantity);

            reducibleSizes.forEach(sizeData => {
                const reductionRatio = sizeData.quantity / totalReducibleQuantity;
                const reductionAmount = Math.ceil(reductionNeeded * reductionRatio);
                // Ensure not reducing below 1
                const newQuantity = Math.max(sizeData.quantity - reductionAmount, 1);
                reductionNeeded -= (sizeData.quantity - newQuantity);
                sizeData.quantity = newQuantity;
            });

            // Handle any remaining reduction needed due to rounding, ensuring quantities do not drop below 1
            if (reductionNeeded > 0) {
                const largestSizeData = reducibleSizes.sort((a, b) => b.quantity - a.quantity)[0];
                largestSizeData.quantity = Math.max(largestSizeData.quantity - reductionNeeded, 1);
            }
        }
    };
    const createPriorities = (storesData: StoreData[]): StoreData[] => {
        // Pair store names with their standard quantities and sort by quantity descending
        const storePriorities = storesData.map((store, index) => ({
            name: store.storeName,
            quantity: standardQuantities[index], // Assume standardQuantities are aligned with storesData
            storeData: store
        }));

        // Sort by standard quantity in descending order
        storePriorities.sort((a, b) => b.quantity - a.quantity);

        // Return the sorted store data based on the priority
        return storePriorities.map(sp => sp.storeData);
    };

    const adjustAllocations = (storesData: StoreData[], totalReceived: number): void => {
        let remainingQuantities = totalProductionQuantities.map((total, index) =>
            total - storesData.reduce((sum, store) => sum + store.sizeQuantities[index]?.quantity, 0)
        );

        const prioritizedStores = createPriorities(storesData);

        prioritizedStores.forEach(storeData => {
            const storeIndex = storesData.indexOf(storeData);
            const standardQuantity = standardQuantities[storeIndex] || 0;
            let storeTotalAllocation = storeData.sizeQuantities.reduce((sum, sizeData) => sum + sizeData?.quantity, 0);

            if (storeTotalAllocation > standardQuantity) {
                scaleDownStoreAllocation(storeData, standardQuantity);
                storeTotalAllocation = storeData.sizeQuantities.reduce((sum, sizeData) => sum + sizeData?.quantity, 0);
            }

            // Redistribute remaining quantities to stores under their standard allocation
            storeData.sizeQuantities.forEach((sizeData, sizeIndex) => {
                if (remainingQuantities[sizeIndex] > 0 && storeTotalAllocation < standardQuantity) {
                    let additionalAllocation = Math.min(remainingQuantities[sizeIndex], standardQuantity - storeTotalAllocation);
                    sizeData.quantity += additionalAllocation;
                    remainingQuantities[sizeIndex] -= additionalAllocation;
                    storeTotalAllocation += additionalAllocation;
                }
            });
        });

        processDiscrepancies(storesData, remainingQuantities);
    };
    const validateTotalAllocations = (storesData: StoreData[]): void => {
        // First, correct any over-allocation
        storesData.forEach(storeData => {
            storeData.sizeQuantities.forEach((sizeData, sizeIndex) => {
                const totalAllocated = storesData.reduce((sum, store) => sum + store.sizeQuantities[sizeIndex].quantity, 0);
                if (totalAllocated > totalProductionQuantities[sizeIndex]) {
                    let excess = totalAllocated - totalProductionQuantities[sizeIndex];

                    // Start reduction from the least prioritized stores
                    for (let i = storesData.length - 1; i >= 0 && excess > 0; i--) {
                        let storeSizeQuantity = storesData[i].sizeQuantities[sizeIndex];
                        let reduction = Math.min(storeSizeQuantity.quantity, excess);
                        storeSizeQuantity.quantity -= reduction;
                        excess -= reduction;
                    }
                }
            });
        });

        // // Then, ensure each store's allocation meets its standard quantity if possible, distributing across sizes
        // storesData.forEach((storeData, storeIndex) => {
        //     let needed = standardQuantities[storeIndex] - storeData.sizeQuantities.reduce((sum, sizeData) => sum + sizeData.quantity, 0);

        //     while (needed > 0) {
        //         // Distribute the needed quantity across all sizes proportionally
        //         const remainingQuantities = storeData.sizeQuantities.map((sizeData, sizeIndex) => 
        //             totalProductionQuantities[sizeIndex] - storesData.reduce((sum, store) => sum + store.sizeQuantities[sizeIndex].quantity, 0)
        //         );

        //         // Calculate the proportional allocation for each size
        //         let totalRemaining = remainingQuantities.reduce((a, b) => a + b, 0);
        //         if (totalRemaining <= 0) break;

        //         for (let sizeIndex = 0; sizeIndex < storeData.sizeQuantities.length; sizeIndex++) {
        //             if (needed <= 0) break;
        //             let proportion = remainingQuantities[sizeIndex] / totalRemaining;
        //             let increase = Math.min(needed, Math.floor(proportion * needed), remainingQuantities[sizeIndex]);
        //             storeData.sizeQuantities[sizeIndex].quantity += increase;
        //             needed -= increase;
        //         }
        //     }
        // });
    };
    const allocateSizesToStores = (): void => {
        const totalStandardQuantity = standardQuantities.reduce((sum, quantity) => sum + quantity, 0);
        const totalProduction = totalProductionQuantities.reduce((sum, quantity) => sum + quantity, 0);

        // Check if total standard quantity exceeds total production quantity
        if (totalStandardQuantity > totalProduction) {
            alert("Total standard quantity exceeds total production quantities. Allocation cannot proceed.");
            return; // Exit the function early
        }

        let totalRemainingQuantities = [...totalProductionQuantities];
        const prioritizedStores = createPriorities(tableData);

        for (const storeData of prioritizedStores) {
            const storeIndex = tableData.indexOf(storeData);
            const standardQuantity = standardQuantities[storeIndex];
            let storeTotalAllocation = 0;

            // Calculate total quantities remaining before this store's allocation
            const totalAvailable = totalRemainingQuantities.reduce((sum, quantity) => sum + quantity, 0);

            for (let sizeIndex = 0; sizeIndex < storeData.sizeQuantities.length; sizeIndex++) {
                if (totalAvailable > 0) {
                    const sizeProportion = totalRemainingQuantities[sizeIndex] / totalAvailable;
                    let allocation = Math.round(standardQuantity * sizeProportion);
                    allocation = Math.min(allocation, totalRemainingQuantities[sizeIndex]);
                    storeData.sizeQuantities[sizeIndex].quantity = allocation;


                    storeTotalAllocation += allocation;
                }
            }

            // Check if total allocation is less than standard and try to increase it
            if (storeTotalAllocation < standardQuantity) {
                for (let sizeIndex = 0; sizeIndex < storeData.sizeQuantities.length; sizeIndex++) {
                    const potentialIncrease = Math.min(standardQuantity - storeTotalAllocation, totalRemainingQuantities[sizeIndex]);
                    storeData.sizeQuantities[sizeIndex].quantity += potentialIncrease;
                    totalRemainingQuantities[sizeIndex] -= potentialIncrease;
                    storeTotalAllocation += potentialIncrease;
                }
            }
        }

        // After all stores have been processed, make final adjustments
        adjustAllocations(tableData, totalProductionQuantities.reduce((sum, quantity) => sum + quantity, 0));
        validateTotalAllocations(tableData);
        setTableData(tableData);
    };
    // Use useEffect to recalculate allocations when needed
    useEffect(() => {
        if (calculateClicked) {
            allocateSizesToStores();
            setCalculateClicked(false);
        }
    }, [standardQuantities, totalProductionQuantities, calculateClicked, calculateBtnClicked]);

    const updateTableData = () => {
        const data = stores.filter(store => store.checked).map(store => {
            return {
                storeName: store.store,
                sizeQuantities: sizes.map(size => {
                    return {
                        size: size,
                        quantity: 0,
                    };
                }),
            };
        });
        setTableData(data);
        const newStandardQuantities = stores.filter((store: any) => store.checked).map((store: any, index:number)=> standardQuantities[index] || 0);
        setStandardQuantities(newStandardQuantities);
    };
    useEffect(() => {
        updateTableData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sizes, stores]);
    const calculateTotalStdQtySum = (): number => {
        return standardQuantities.reduce((total, currentQuantity) => total + (currentQuantity || 0), 0);
    };
    const calculateTotalAllocation = (sizeIndex: number) => {
        return tableData.reduce(
            (total, row) => {
                // Check if row and row.sizeQuantities are not null
                if (row && row.sizeQuantities) {
                    // Check if sizeIndex is within the bounds of the sizeQuantities array
                    if (sizeIndex < row.sizeQuantities.length) {
                        return total + row.sizeQuantities[sizeIndex].quantity;
                    }
                }
                return total;
            },
            0
        );
    };
    const calculateTotalSizeQuantitiesSum = (): number => {
        return tableData.reduce((total, _rowData, rowIndex) => total + calculateRowTotal(rowIndex), 0);
    };

    const calculateOverstock = (sizeIndex: number) => {
        const totalAllocation = calculateTotalAllocation(sizeIndex);
        const totalProductionQuantity = totalProductionQuantities[sizeIndex] || 0;
        const result = Math.max(totalProductionQuantity - totalAllocation);
        return isNaN(result) ? 0 : result;
    };

    return (
        <div>
            <Grid>
                <Grid.Col span={11}>
                    <Table border={1} striped stickyHeader withColumnBorders withTableBorder highlightOnHover>
                        <Table.Thead>
                            <Table.Tr className={classes.tableHeader} style={{ backgroundColor: theme.colors.gray[8], color: theme.colors.gray[0] }}>
                                <Table.Th>Store</Table.Th>
                                {sizes.length > 0 && sizes.map((size: string) => <Table.Th key={size}>{size}</Table.Th>)}
                                <Table.Th>Total</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            <Table.Tr>
                                <Table.Th>RCV QTY</Table.Th>
                                {sizes.length > 0 && sizes.map((size: string, index: number) => <Table.Td key={size}>
                                    <TextInput
                                        variant='unstyled'
                                        type='number'
                                        value={totalProductionQuantities[index]}
                                        onChange={(event) => handleProductionQuantities(event, index)}
                                        className={classes.inputNumber}
                                    />
                                </Table.Td>)}
                                <Table.Td><TextInput variant='unstyled' type='number' value={totalProductionQuantities.reduce((a, b) => a + b, 0)} className={classes.inputNumber} /></Table.Td>{/* Total*/}
                            </Table.Tr>

                            {tableData.map((row, rowIndex) => (
                                <Table.Tr key={row.storeName} >
                                    <Table.Th >{row.storeName}</Table.Th>
                                    {row.sizeQuantities.map((sizeData, sizeIndex) => (

                                        <Table.Td key={sizeData.size} >
                                            <TextInput
                                                type="number"
                                                value={sizeData.quantity}
                                                onChange={(e) =>
                                                    handleQuantityChange(e, rowIndex, sizeIndex)
                                                }
                                                style={{ width: "100%" }}
                                                variant='unstyled'
                                                onWheel={(e) => e.currentTarget.blur()}
                                            />
                                        </Table.Td>
                                    ))}
                                    <Table.Td style={{ borderLeft: '1px solid #d0d0d0' }}>
                                        <TextInput
                                            type="number"
                                            value={calculateRowTotal(rowIndex) || ''}
                                            readOnly
                                            variant='unstyled'
                                            onWheel={(e) => e.currentTarget.blur()}
                                        />
                                    </Table.Td>
                                </Table.Tr>
                            ))}
                            <Table.Tr>
                                <Table.Th>Total Allocation</Table.Th>
                                {sizes.length > 0 && sizes.map((size: string, index: number) => <Table.Td key={size}><TextInput variant='unstyled' type='number' value={calculateTotalAllocation(index) || 0} className={classes.inputNumber} /></Table.Td>)}
                                <Table.Td><TextInput variant='unstyled' value={calculateTotalSizeQuantitiesSum() || 0} type='number' className={classes.inputNumber} /></Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Th>Overstock</Table.Th>
                                {sizes.length > 0 && sizes.map((size: string, index: number) => <Table.Td key={size}><TextInput variant='unstyled' value={calculateOverstock(index) || 0} type='number' className={classes.inputNumber} /></Table.Td>)}
                                <Table.Td><TextInput variant='unstyled' value={Math.max(totalProductionSum - calculateTotalSizeQuantitiesSum(), 0)} type='number' className={classes.inputNumber} /></Table.Td>
                            </Table.Tr>
                        </Table.Tbody>
                    </Table>
                </Grid.Col>
                <Grid.Col span={1}>
                    <Table striped stickyHeader withColumnBorders withTableBorder highlightOnHover>
                        <Table.Thead>
                            <Table.Tr className={classes.tableHeader} style={{ backgroundColor: theme.colors.gray[8], color: theme.colors.gray[0] }}>
                                <Table.Th>STD</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            <Table.Tr>
                                <Table.Td>
                                    <TextInput
                                        variant='unstyled'
                                        type='number'
                                        className={classes.inputNumber}
                                        value={totalProductionQuantities.reduce((a, b) => a + b, 0) - calculateTotalStdQtySum()}
                                    />
                                </Table.Td> {/*RCV QTY TOTAL */}
                            </Table.Tr>
                            {stores.filter((store: { store: string, checked: boolean }) => store.checked).map((store: { store: string, checked: boolean }, index: number) => {
                                return (
                                    <Table.Tr key={index}>
                                        <Table.Td>
                                            <TextInput
                                                variant='unstyled'
                                                type='number'
                                                className={classes.inputNumber}
                                                value={standardQuantities[index]}
                                                onChange={(e) => handleStandardQuantityChange(e, index)}
                                            />
                                        </Table.Td>
                                    </Table.Tr>
                                );
                            })
                            }
                            <Table.Tr>
                                <Table.Td><TextInput variant='unstyled' value={calculateTotalStdQtySum() || 0} type='number' className={classes.inputNumber} /></Table.Td> {/* TOTAL Allocation*/}
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td><TextInput variant='unstyled' value='' type='number' className={classes.inputNumber} /></Table.Td> {/*TOTAL Overstock */}
                            </Table.Tr>
                        </Table.Tbody>
                    </Table>
                </Grid.Col>
            </Grid>
        </div>
    );
};

export default AllocationTable;