import { Button, Divider, Grid, Group, Modal, Select, Table, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconArrowLeft, IconArrowRight, IconFilter } from '@tabler/icons-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import NavBar from '../../components/NavBar/NavBar';
import BolPrint from '../../components/Print/BolPrint';
import BolReportDownload from '../../components/Print/BolReport';
import { BolData } from '../../interface/BillOfLading';
import classes from '../Hr/BillOfLading.module.css';


const BolList: React.FC = () => {
    const [bolList, setBolList] = useState([] as any[]);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        total: 1
    });
    const theme = useMantineTheme();
    const token = useSelector((state: any) => state.user.sessionId);
    const initialValues: BolData = {
        id: '',
        dclocation: '',
        third_party: null,
        bol_type: '',
        shipper: {
            name: '',
            address: '',
            postal_code: '',
            sid_no: ''
        },
        receiver: {
            name: '',
            address: '',
            postal_code: '',
            cid_no: ''
        },
        po: [],
        bol_no: '',
        amount: null,
        type: '',
        status: false,
        payment_status: false,
        trailer: '',
        supplier: '',
        bol_date: '',
        note: '',
        description: '',
        invoice_no: '',
        invoice_file: '',
        created_at: '',
        updated_at: ''
    }
    const columns = useMemo(() => [
        {
            accessorKey: 'index',
            header: 'No.'
        },
        {
            accessorKey: 'shipper',
            header: 'Shipper'
        },
        {
            accessorKey: 'receiver',
            header: 'Receiver'
        },
        {
            accessorKey: 'po_no',
            header: 'PO No.'
        },
        {
            accessorKey: 'bol_date',
            header: 'Bol Date',
            width: '100px'
        },
        {
            accessorKey: 'amount',
            header: 'Rate'
        },
        {
            accessorKey: 'type',
            header: 'Load/Unload'
        },
        {
            accessorKey: 'status',
            header: 'Status'
        },
        {
            accessorKey: 'bol_no',
            header: 'Bol No.'
        },
        {
            accessorKey: 'payment_status',
            header: 'Payment Status'
        },
        {
            accessorKey: 'trailer',
            header: 'Trailer Info.'
        },
        {
            accessorKey: 'action',
            header: 'Action'
        }
    ], []);
    const isSmallScreen = useMediaQuery('(max-width: 668px)');
    const [filterModal, setFilterModal] = useState(false);
    const initialFilter = {
        shipper: '',
        receiver: '',
        po_no: '',
        bol_no: '',
        type: '',
        status: null as boolean | null,
        payment_status: null as boolean | null,
        trailer: '',
        supplier: '',
        start_date: '',
        end_date: ''
    }
    const [filter, setFilter] = useState(initialFilter);
    const handleReset = async () => {
        await setFilter({ ...initialFilter });
        // await getBolList();
    }

    useEffect(() => {
        getBolList(pagination.page, pagination.limit);
    }, [pagination.page]);

    const getBolList = async (page: number, limit: number) => {
        try {
            const response = await fetch(`/bol/getBolListG2000`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    page: page,
                    limit: limit,
                    ...filter
                })
            });
            const data = await response.json();
            setBolList(data.data);
            setPagination({
                ...pagination,
                page: page,
                total: Math.ceil(data.totalRows / pagination.limit)
            });
        } catch (error) {
            console.log('Error in getBolList:', error);
        }
    }
    const handlePagination = (operation: string) => {
        if (operation === 'increase') {
            setPagination({ ...pagination, page: pagination.page === pagination.total ? pagination.page : pagination.page + 1 })
        } else {
            setPagination({ ...pagination, page: pagination.page === 1 ? pagination.page : pagination.page - 1 })
        }
    }

    const handleFilter = () => {
        // setPagination({ ...pagination, page: 1 });
        getBolList(1, pagination.limit);
        setFilterModal(false);
    }
    const handleFilterChange = (column: string, value: string | boolean) => {
        setFilter({ ...filter, [column]: value });
    }
    return (
        <div>
            {/* Filter Modal */}
            <Modal opened={filterModal} onClose={() => setFilterModal(false)} title="Filter" size={'xl'}>
                <Grid>
                    <Grid.Col span={{ base: 12, xs: 6 }}>
                        <TextInput label="Shipper" placeholder="Enter Shipper" value={filter.shipper} onChange={(e) => handleFilterChange('shipper', e.target.value)} />
                        <TextInput label="Receiver" placeholder="Enter Receiver" value={filter.receiver} onChange={(e) => handleFilterChange('receiver', e.target.value)} />
                        <TextInput label="PO No." placeholder="Enter PO No." value={filter.po_no} onChange={(e) => handleFilterChange('po_no', e.target.value)} />
                        <TextInput label="Bol No." placeholder="Enter Bol No." value={filter.bol_no} onChange={(e) => handleFilterChange('bol_no', e.target.value)} />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, xs: 6 }}>
                        <Select
                            label="Status"
                            placeholder="Select Status"
                            data={[
                                { label: 'Pending', value: 'false' },
                                { label: 'Done', value: 'true' }
                            ]}
                            value={filter.status?.toString()}
                            onChange={(value) => handleFilterChange('status', value === 'true')}
                        />
                        <Select
                            label="Payment Status"
                            placeholder="Select Payment Status"
                            data={[
                                { label: 'Unpaid', value: 'false' },
                                { label: 'Paid', value: 'true' }
                            ]}
                            value={filter.payment_status?.toString()}
                            onChange={(value) => handleFilterChange('payment_status', value === 'true')}
                        />
                        <TextInput label="Supplier" placeholder="Enter Supplier" value={filter.supplier} onChange={(e) => handleFilterChange('supplier', e.target.value)} />
                        <TextInput label="Type" placeholder="Enter Type" value={filter.type} onChange={(e) => handleFilterChange('type', e.target.value)} />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, xs: 6 }}>
                        <TextInput label="Start Date" type='date' placeholder="Enter Start Date" value={filter.start_date} onChange={(e) => handleFilterChange('start_date', e.target.value.split('T')[0])} />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, xs: 6 }}>
                        <TextInput label="End Date" type='date' placeholder="Enter End Date" value={filter.end_date} onChange={(e) => handleFilterChange('end_date', e.target.value.split('T')[0])} />
                    </Grid.Col>
                </Grid>
                <div className={classes.buttonDiv} style={{ marginTop: '20px' }}>
                    <Button onClick={handleFilter}>Apply</Button>
                    <Button variant="outline" onClick={handleReset}>Reset</Button>
                    <Button variant="outline" onClick={() => setFilterModal(false)}>Cancel</Button>
                </div>
            </Modal>
            <NavBar />
            <div className={'container'}>
                <div className={'containerMainDiv'}>
                    {/* <h2>Bill of Lading for {bolList[0]?.trailer}</h2> */}
                    <div className={'containerTableDiv'}>
                        <Table verticalSpacing={2} highlightOnHover stickyHeader stripedColor={theme.colors.blue[0]} style={{ marginTop: '10px' }}>
                            <Table.Thead className={'containerTableHead'} style={{ fontSize: '1.1 rem' }}>
                                <Table.Tr>
                                    {columns.map((column) => (
                                        <Table.Th key={column.accessorKey} style={{ width: column.width || 'auto' }}>{column.header}</Table.Th>
                                    ))}
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                                {bolList?.map((bol: any, index: number) => (
                                    // <Table.Tr key={bol.id} style={{ backgroundColor: bol.status && bol.payment_status ? '#89ab92' : undefined }}>
                                    <Table.Tr key={bol.id} style={{ backgroundColor: bol.status && bol.payment_status ? '#008000' : undefined }}>
                                        <Table.Td>{index + 1 + (pagination.page - 1) * pagination.limit}</Table.Td>
                                        <Table.Td>{bol.shipper?.name}</Table.Td>
                                        <Table.Td>{bol.receiver?.name}</Table.Td>
                                        <Table.Td>{bol.po?.map((po: any) => po.po_no).join('/ ')}</Table.Td>
                                        <Table.Td>{bol.bol_date?.split('T')[0]}</Table.Td>
                                        <Table.Td>{bol?.amount}</Table.Td>
                                        <Table.Td>{bol?.type}</Table.Td>
                                        <Table.Td>{bol?.status ? 'Done' : 'Pending'}</Table.Td>
                                        <Table.Td style={{ fontWeight: 'bold' }}>{bol?.bol_no}</Table.Td>
                                        <Table.Td>{bol?.payment_status ? 'Paid' : 'Unpaid'}</Table.Td>
                                        <Table.Td>{bol?.trailer}</Table.Td>
                                        <Table.Td><BolPrint bol={bol} size='xs' variant='outline' /></Table.Td>
                                    </Table.Tr>
                                ))}
                            </Table.Tbody>
                        </Table>
                    </div>
                    <Divider style={{ marginBottom: '10px' }} />
                    <div className={'containerFooterDiv'}>
                        <Grid style={{ width: '100%' }}>
                            {!isSmallScreen && (<Grid.Col span={{ base: 12, xs: 4 }}>
                                <div className='rowDiv' style={{ gap: '10px', justifyContent: 'flex-start' }}>
                                    <BolReportDownload />
                                </div>
                                {/* <IconReport className='icon' onClick={() => handleReport()} /> */}
                            </Grid.Col>)}
                            <Grid.Col span={isSmallScreen ? 12 : 4}>
                                <Group justify='center'>
                                    {isSmallScreen && <BolReportDownload />}
                                    <div className={'paginationGroup'}>
                                        <Button disabled={pagination.page === 1} className={'pagincationIcon'} size='xs' onClick={() => handlePagination('decrease')}><IconArrowLeft /></Button>
                                        <Text size='lg' fw={500}>{pagination.page}/{pagination.total || 1}</Text>
                                        <Button disabled={pagination.page === pagination.total} className={'pagincationIcon'} size='xs' onClick={() => handlePagination('increase')}><IconArrowRight /></Button>
                                        {isSmallScreen && (
                                            <div className='rowDiv' style={{ gap: '10px', justifyContent: 'flex-end' }}>
                                                <Button variant='outline' onClick={() => setFilterModal(true)}><IconFilter /></Button>
                                            </div>)}
                                    </div>
                                </Group>
                            </Grid.Col>
                            {!isSmallScreen && (<Grid.Col span={{ base: 12, xs: 4 }}>
                                <div className='rowDiv' style={{ gap: '10px', justifyContent: 'flex-end' }}>
                                    <Button variant='outline' onClick={() => setFilterModal(true)}><IconFilter /> Filter</Button>
                                </div>
                            </Grid.Col>)}
                        </Grid>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default BolList;