import { Button, Grid, Group, Image, Modal, Select, Table, Text, TextInput, useMantineTheme } from '@mantine/core';
import { IconArrowLeft, IconArrowRight, IconDeviceFloppy, IconEye, IconFilter, IconRestore } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';
import { useDebounce } from '../../Hooks/useDebounce';
import { PoNoWithStatus } from '../../interface/AllocationInvoice';
import '../Global.css';
import classes from './StyleList.module.css';

const StyleList: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [rowData, setRowData] = useState<PoNoWithStatus>();
    const token = useSelector((state: any) => state.user.sessionId);
    const [tableData, setTableData] = useState<any[]>([]);
    const [filterModal, setFilterModal] = useState<boolean>(false);
    const [filter, setFilter] = useState<{ style_no: string, supplier_name: string }>({
        style_no: '',
        supplier_name: '',
    });
    const [pagination, setPagination] = useState<{ page: number, limit: number, total?: number }>({ page: 1, limit: 10, total: 1 });
    const [viewModal, setViewModal] = useState<boolean>(false);
    const [selectedStyle, setSelectedStyle] = useState<{ image: string, style_no: string }>({ image: '', style_no: '' });
    const theme = useMantineTheme();
    const styleSearch = useDebounce(filter.style_no, 600);
    const initialPagination = {
        page: 1,
        limit: 10,
        total: 1
    }

    useEffect(() => {
        // setPagination(initialPagination);
        getData(pagination);
    }, [styleSearch]);

    const getData = async (newPagination: { page: number, limit: number }, style_no?: string) => {
        try {
            const response = await fetch('/style/getStyleList', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    ...filter,
                    style_no: style_no ? style_no : filter.style_no,
                    ...newPagination,
                })
            });
            const { data, total, current } = await response.json();
            if (response.status === 200) {
                await setPagination({ ...newPagination, total: total, page: current });
                await setTableData(data);
                return;
            }
            alert('Could not find the data. Please check the filter values again.');
            setTableData([]);
            await setPagination({ ...pagination, page: 1, total: 1 });
            return;

        } catch (error) {
            console.log('Error fetcing transfer data.')
        }
    }
    const handlePagination = (operation: string) => {
        let newPagination = pagination;
        if (operation === 'increase') {
            setPagination({ ...pagination, page: pagination.page === pagination.total ? pagination.page : pagination.page + 1 });
            newPagination = { ...pagination, page: pagination.page === pagination.total ? pagination.page : pagination.page + 1 };
        } else {
            setPagination({ ...pagination, page: pagination.page === 1 ? pagination.page : pagination.page - 1 });
            newPagination = { ...pagination, page: pagination.page === 1 ? pagination.page : pagination.page - 1 };
        }
        getData({ page: newPagination.page, limit: newPagination.limit });
    }
    // Function to render the expanded row details

    const handleApplyFilter = async () => {
        await getData(pagination);
        setFilterModal(false);
    }
    const handleReset = async () => {
        await setPagination({ page: 1, limit: 10, total: 1 });
        await setFilter({ style_no: '', supplier_name: '' })
        getData({ page: 1, limit: 10 }, '');
    }

    // Handle input change for any field
    const handleChange = (index: number, field: string, value: string) => {
        const updatedTableData = [...tableData]; // Create a shallow copy of the data
        updatedTableData[index] = { ...updatedTableData[index], [field]: value, modified: true }; // Update the specific cell
        setTableData(updatedTableData); // Update the state with new data
    };
    const handleSave = async () => {
        let modifiedData = tableData.filter((item: any) => item?.modified === true);
        if (modifiedData.length === 0) {
            alert('No modified data.');
            return;
        }
        try {
            const response = await fetch('/style/saveList', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    data: modifiedData
                })
            });
            const data = await response.json();
            if (response.status !== 200) {
                alert('Something went wrong.');
                return;
            }
            alert('Successfully updated data');
        } catch (error) {
            console.log('Error saving data:', error);
        }

    }
    const handleOpenModal = async (image: any, style_no: string) => {
        if (image?.startsWith('data:image/')) {
            await setSelectedStyle({ image, style_no })
        } else {
            await setSelectedStyle({ image: `/styleImages/${image}`, style_no })
        }
        setViewModal(true);
    }

    const ViewImageModal = () => {
        return (
            <Modal
                opened={viewModal}
                onClose={() => setViewModal(false)}
                title={`Style No:  ${selectedStyle?.style_no}`}
                size={'xl'}
            >
                <div className={classes.viewModal}>
                    <Image
                        src={`${selectedStyle?.image}`}
                        alt={`${selectedStyle?.style_no}`}
                    />
                </div>
            </Modal>

        )
    }

    return (
        <div>
            <NavBar />
            <ViewImageModal />
            <div className={'main'}>
                <h2 className={'title'}>Style List</h2>
                <div style={{ width: '100%', marginBottom: '50px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                        <TextInput
                            placeholder='Search style No . . .'
                            value={filter.style_no}  // Pass the value of the search bar
                            onChange={(e) => setFilter({ ...filter, style_no: e.target.value.toUpperCase() })}
                            style={{ marginBottom: '15px', flexGrow: 1 }}
                        />
                        <Button variant='outline' className={classes.bottomBtn} onClick={() => setFilterModal(true)} ><IconFilter /> Filter</Button>
                        <Button variant='outline' className={classes.bottomBtn} onClick={handleSave} ><IconDeviceFloppy /> Save</Button>
                    </div>
                    <Table stickyHeader withColumnBorders verticalSpacing={'11.5'} withTableBorder>
                        <Table.Thead>
                            <Table.Tr className={classes.tableHead}>
                                <Table.Th style={{ width: '20px' }}>View</Table.Th>
                                <Table.Th>Supplier</Table.Th>
                                <Table.Th>Style No</Table.Th>
                                <Table.Th>Description</Table.Th>
                                <Table.Th>Color</Table.Th>
                                <Table.Th>Cost</Table.Th>
                                <Table.Th>MSRP</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {tableData && tableData.map((item: any, index: number) => {
                                return (
                                    <Table.Tr key={index}>
                                        <Table.Td><IconEye
                                            className={classes.icon}
                                            onClick={() => {
                                                handleOpenModal(item.image, item.style_no);
                                            }} /></Table.Td>
                                        <Table.Td>{item.supplier_name}</Table.Td>
                                        <Table.Td>{item.style_no}</Table.Td>
                                        <Table.Td>
                                            <TextInput
                                                variant="unstyled"
                                                value={item.description}
                                                onChange={(e) => handleChange(index, 'description', e.target.value)} // Update description
                                            />
                                        </Table.Td>
                                        <Table.Td>
                                            <TextInput
                                                variant="unstyled"
                                                value={item.color}
                                                onChange={(e) => handleChange(index, 'color', e.target.value)} // Update color
                                            />
                                        </Table.Td>
                                        <Table.Td>
                                            <TextInput
                                                variant="unstyled"
                                                value={item.cost}
                                                type='number'
                                                onChange={(e) => handleChange(index, 'cost', e.target.value)} // Update cost
                                            />
                                        </Table.Td>
                                        <Table.Td>
                                            <TextInput
                                                variant="unstyled"
                                                value={item.msrp}
                                                type='number'
                                                onChange={(e) => handleChange(index, 'msrp', e.target.value)} // Update msrp
                                            />
                                        </Table.Td>
                                    </Table.Tr>
                                )
                            })}
                        </Table.Tbody>
                    </Table>
                </div>
            </div>
            <div className={"footerDiv"}>
                <div></div>
                <Group>
                    <div className={classes.paginationGroup}>
                        <Button disabled={pagination.page === 1} className={classes.pagincationIcon} size='xs' onClick={() => handlePagination('decrease')}><IconArrowLeft /></Button>
                        <Text size='lg' fw={500}>{pagination.page}/{pagination.total || 1}</Text>
                        <Button disabled={pagination.page === pagination.total} className={classes.pagincationIcon} size='xs' onClick={() => handlePagination('increase')}><IconArrowRight /></Button>
                    </div>
                </Group>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '15px' }}>
                    <div style={{ paddingTop: '7px', cursor: 'pointer' }} onClick={handleReset}>
                        <IconRestore />
                    </div>
                    <Select
                        data={['10', '20', '30']}
                        defaultValue={'10'}
                        style={{ width: '70px', borderRadius: '10px', height: '100%', border: `1px solid black`, paddingBottom: '35px' }}
                        variant='unstyled'
                        onChange={(value) => {
                            setPagination({ ...pagination, limit: parseInt(value || '10') });
                            getData({ ...pagination, limit: parseInt(value || '10') });
                        }}
                    />
                </div>
            </div>
            <Modal
                opened={filterModal}
                onClose={() => setFilterModal(false)}
                title={`Filter Options`}
                centered
                size={'lg'}
            >
                <div>
                    <Grid>
                        <Grid.Col span={6} style={{ gap: '20px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <Table withTableBorder withColumnBorders stickyHeader>
                                    <Table.Tbody>
                                        <Table.Tr>
                                            <Table.Th className={classes.tableHeadModal}>Style No</Table.Th>
                                            <Table.Td ><TextInput
                                                type='text'
                                                variant='unstyled'
                                                value={filter.style_no}
                                                className={classes.infoText}
                                                onChange={(e) => setFilter({ ...filter, style_no: e.target.value.toUpperCase() })}
                                            /></Table.Td>
                                        </Table.Tr>
                                    </Table.Tbody>
                                </Table>
                            </div>
                        </Grid.Col>
                        <Grid.Col span={6} >
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <Table withTableBorder withColumnBorders stickyHeader>
                                    <Table.Tbody>
                                        <Table.Tr>
                                            <Table.Th className={classes.tableHeadModal}>Supplier</Table.Th>
                                            <Table.Td ><TextInput
                                                type='text'
                                                variant='unstyled'
                                                value={filter.supplier_name}
                                                className={classes.infoText}
                                                onChange={(e) => setFilter({ ...filter, supplier_name: e.target.value.toUpperCase() })}
                                            /></Table.Td>
                                        </Table.Tr>
                                    </Table.Tbody>
                                </Table>
                            </div>
                        </Grid.Col>
                    </Grid>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <Button style={{ backgroundColor: theme.colors.gray[8], width: '20vw' }} onClick={handleApplyFilter}>Apply</Button>
                </div>
            </Modal>
        </div>
    )
}


export default StyleList;