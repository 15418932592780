// PrivateRoute.tsx
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { adminAccess, hrAccess, superAdminAccess, userAccess } from '../components/NavBar/MenuItems';
import useCheckAuthToken from '../Hooks/useCheckAuthToken';
import LoginPage from '../pages/Login';
import NotFound from '../pages/NotFound';


export default function PrivateRoute({ page }: { page: React.ReactNode }) {
    const isAuthenticated = useCheckAuthToken();
    const scope = useSelector((state: any) => state.user?.user?.scope);  // Get the user's role (scope)
    const location = useLocation();  // Get the current route
    const getAccessList = (role: string) => {
        if (role === 'SuperAdmin') {
            return superAdminAccess;
        } else if (role === 'Admin') {
            return adminAccess;
        } else if (role === 'User') {
            return userAccess;
        } else if(role === 'Hr'){
            return hrAccess;
        }
        return [];
    };
    const list = getAccessList(scope);
    const hasAccess = list.includes(location.pathname);
    if(!scope) return <LoginPage />
    return (
        <React.Fragment>
            {isAuthenticated ? hasAccess ? page : <NotFound /> : <LoginPage />}
        </React.Fragment>
    );  
}