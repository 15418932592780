import { Grid, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import NavBar from '../../components/NavBar/NavBar';
import PoData from '../../components/PoData/PoData';
import PurchaseOrders from '../../components/PurchaseOrdersList/PurchaseOrders';
import StyleData from '../../components/StyleData/StyleData';
import classes from './StyleAlloc.module.css';

const StyleAlloc = () => {
    const theme = useMantineTheme();
    const isMobile = useMediaQuery("(min-width: 0) and (max-width: 550px)") || false;

    return (
        <div>
            <NavBar />
            <div className={classes.main}>
                <Grid style={{ width: '100%' }}>
                    <Grid.Col span={isMobile ? 12 : 8}>
                        <StyleData />
                        <PoData />
                    </Grid.Col>
                    <Grid.Col span={isMobile ? 12 : 4} style={{ backgroundColor:theme.colors.gray[1]}}>
                        <PurchaseOrders />
                    </Grid.Col>
                </Grid>
            </div>
        </div>
    )
}

export default StyleAlloc;