import { Button, Checkbox, ComboboxItem, Grid, Group, Select, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPono } from '../../api/poList';
import AllocationTable from '../../components/AllocationTable/AllocationTable';
import InvoiceTable from '../../components/InvoiceTable/InvoiceTable';
import NavBar from '../../components/NavBar/NavBar';
import StyleTable from '../../components/TransitTable/StyleTable';
import { STORES, STORES_PAIRS } from '../../constants/stores';
import { useDebounce } from '../../Hooks/useDebounce';
import { PoNoWithStatus } from '../../interface/AllocationInvoice';
import { StoreData } from '../../interface/AllocationTable';
import { StyleTableData } from '../../interface/TransitTable';
import { allocationPaperPDF } from '../../PDFs/allocationPaper';
import { sizeAllocationPDF } from '../../PDFs/sizeAllocation';
import classes from './Allocation.module.css';
// import classes from './Restock.module.css';

const RestockCalculator: React.FC = () => {
    const theme = useMantineTheme();
    const isSmallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
    const token = useSelector((state: any) => state.user.sessionId);
    const [poNoList, setPoNoList] = useState<PoNoWithStatus[]>([]);
    const [po, setPo] = useState<{ poNo: string, poId: string }>();
    const [stores, setStores] = useState<{ store: string, checked: boolean }[]>(STORES.map((store: string) => ({ store: store, checked: true })));
    const [sizes, setSizes] = useState<string[]>([]);
    const [totalProductionQuantities, setTotalProductionQuantities] = useState<number[]>([]);
    const [standardQuantities, setStandardQuantities] = useState<number[]>([]);
    const [totalProductionSum, setTotalProductionSum] = useState<number>(0);
    const [calculateClicked, setCalculateClicked] = useState(false);
    const [tableData, setTableData] = useState<StoreData[]>([]);
    const [calculateBtnClicked, setCalculateBtnClicked] = useState(false);
    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [supplierList, setSupplierList] = useState<{ label: string, value: string }[]>([]);
    const [poSearchTerm, setPoSeachTerm] = useState('');
    const [supplierSearchTerm, setSupplierSearchTerm] = useState('');
    const poSearch = useDebounce(poSearchTerm, 800);
    const supplierSearch = useDebounce(supplierSearchTerm, 800);
    const [styleTableData, setStyleTableData] = useState<StyleTableData[]>([]);
    const initialAllocation = {
        poNo: '',
        poId: '',
        supplierName: '',
        styleNo: '',
        cost: '',
        msrp: '',
        notes: '',
        binLocation: '',
        description: '',
        color: ''
    }
    const [allocation, setAllocation] = useState<
        {
            poNo: string,
            poId: string,
            supplierName: string,
            styleNo: string,
            cost: string,
            msrp: string,
            notes: string,
            binLocation: string,
            description: string,
            color: string
        }>(initialAllocation);

    const getStyle = async (styleNo: string) => {
        try {
            const response = await fetch(`/style/rms/styleInfo?style_no=${styleNo}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const { data, message } = await response.json();
            if (response.status === 200) {
                // await setSizes(data.metaDatasizes);
                // await setTotalProductionQuantities(data.metaData.quantity);
                await setStyleTableData(data.styleTable);
                await setAllocation({
                    ...allocation,
                    color: data.style.color,
                    description: data.style.description,
                    binLocation: data.style.binLocation,
                });
                await setSizes(data.style.sizes);
                await setTotalProductionQuantities(data.style.quantities);
            }
            if (response.status === 201) {
                await setStyleTableData([]);

            }
        } catch (error) {
            console.log('Error getting style data:', error)
        }
    };

    const getPonoData = async (searchTerm?: string) => {
        try {
            const response = await getPono(token, searchTerm || '');
            const data = response?.data;
            if (data === null) {
                setPoNoList([{ poNo: `+ Create ${searchTerm?.toUpperCase() || ''}` }]);
                return;
            }
            setPoNoList([...data, { poNo: `+ Create ${searchTerm?.toUpperCase() || ''}` }]);
        } catch (error) {
            console.log(error);
        }
    }
    const getSuppliers = async (searchTerm?: string) => {
        if (!searchTerm || searchTerm === '') return;
        try {
            const response = await fetch(`/supplier/getAllsuppliers?searchTerm=${searchTerm}&limit=5&page=1`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            if (response.status === 204 || response.status === 304) {
                await setSupplierList([...supplierList, { label: `+ Create ${searchTerm?.toUpperCase() || ''}`, value: `+ Create ${searchTerm?.toUpperCase() || ''}` }])
                // if (data.length === 0) {
                // }
            }
            if (response.status === 200) {
                const { data, message } = await response.json();
                setSupplierList(data.map((item: any) => ({ label: item.supplier_name, value: item.supplier_name })));
            }
        } catch (error) {
            console.log('Error getting suppliers:', error)
        }
    }
    useEffect(() => {
        getPonoData(poSearchTerm);
    }, [poSearch]);

    useEffect(() => {
        getSuppliers(supplierSearchTerm);
    }, [supplierSearch])

    const createSupplier = async (supplierName: string) => {
        try {
            const response = await fetch('/supplier/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ supplierName })
            });
            const { data, message } = await response.json();

            if (response.status === 200) {
                await setSupplierList([...supplierList, { label: data, value: data }]);
                await setAllocation({ ...allocation, supplierName: data });
            }
        } catch (error) {
            console.log('Error creating supplier:', error);
            return null;
        }
    }
    const handlePoNoChange = (value: string | null, option: ComboboxItem) => {
        if (value?.startsWith('+ Create ')) {
            const poNumber = value.split('+ Create ')[1].toUpperCase() ?? '';
            //check if po number alredy exists in the poNoList
            if (poNoList.some((item: any) => item.poNo === poNumber)) {
                // set the existing po number and do not add it to the poNoList
                setPo({ poNo: poNumber, poId: poNoList.find((item: any) => item.poNo === poNumber)?.poId?.toString() || '' })
                return;
            } else {
                setPo({ poNo: poNumber, poId: '' });
                setPoNoList([...poNoList, { poNo: poNumber, poId: 0 }])
            }
        } else {
            setPo({ poNo: value ?? '', poId: poNoList.find((item) => item.poNo === value)?.poId?.toString() || '' });
        }
    }
    const handleSupplierChange = async (value: string | null, option: ComboboxItem) => {
        if (value?.startsWith('+ Create ')) {
            let supplier = value.split('+ Create ')[1].toUpperCase() ?? '';
            await createSupplier(supplier);
            // setAllocation({ ...allocation, supplierName: value || '' });
            // setSupplierList([...supplierList, { label: supplier, value: supplier }])
        } else {
            setAllocation({ ...allocation, supplierName: value || '' });
        }
    }

    const handleSearchChange = (value: string) => {
        // getPono(value);
        setPoSeachTerm(value.toUpperCase());
    }
    const handleSupplierSearch = (value: string) => {
        setSupplierSearchTerm(value.toUpperCase());
    }

    const handleStyleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        getStyle(allocation.styleNo);
    };

    const handleReset = async (skipSaveConfirmation = false) => {
        const confirmReset = window.confirm("Do you want to reset the form?");
        if (confirmReset) {
            await setTableData([]);
            await setAllocation(initialAllocation);
            await setTotalProductionQuantities([]);
            await setStores(STORES.map((store: string) => ({ store: store, checked: true })));
            await setStandardQuantities(Array(STORES.length).fill(0));
            await setSizes([]);
            await setStyleTableData([]);
            return;
        } else {
            return;
        }
    };

    const handleCheckAll = (checked: boolean) => {
        if (checked) {
            setStores(STORES.map((store: string) => ({ store: store, checked: true })));
        } else {
            setStores(STORES.map((store: string) => ({ store: store, checked: false })));
        }
    };

    const handleSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        let newSizes = value.split(',');
        setSizes(value ? newSizes : []);
        setTotalProductionQuantities(newSizes.map((size: string, index: number) => totalProductionQuantities[index] || 0));
    }


    const handleCalculateClick = () => {
        setCalculateBtnClicked(!calculateBtnClicked);
        setCalculateClicked(true);
    };

    const calculateTotalAllocation = (sizeIndex: number) => {
        return tableData.reduce(
            (total, row) => {
                // Check if row and row.sizeQuantities are not null
                if (row && row.sizeQuantities) {
                    // Check if sizeIndex is within the bounds of the sizeQuantities array
                    if (sizeIndex < row.sizeQuantities.length) {
                        return total + row.sizeQuantities[sizeIndex].quantity;
                    }
                }
                return total;
            },
            0
        );
    };

    const calculateOverstock = (sizeIndex: number) => {
        const totalAllocation = calculateTotalAllocation(sizeIndex);
        const totalProductionQuantity = totalProductionQuantities[sizeIndex] || 0;
        const result = Math.max(totalProductionQuantity - totalAllocation);
        return isNaN(result) ? 0 : result;
    };


    const onSave = async () => {
        let tempPoId = allocation?.poId;
        if (allocation?.poId === null || allocation?.poId === undefined) {
            await setPo({ ...po, poId: allocation?.poNo.toUpperCase().toString() || '', poNo: allocation?.poNo || '' });
            tempPoId = allocation?.poId;
        }
        if (!allocation?.supplierName ||
            !allocation?.styleNo ||
            !allocation?.cost ||
            !allocation?.msrp ||
            allocation?.supplierName.trim() === '' ||
            allocation?.styleNo.trim() === '' ||
            allocation?.cost.trim() === '' ||
            allocation?.msrp.trim() === '' ||
            sizes.length === 0 ||
            totalProductionQuantities.length === 0
        ) {
            alert('Please fill in all required fields');
            return;
        }
        if (totalProductionQuantities.length !== sizes.length) {
            alert('Please enter appropriate quantity for each size');
            return;
        }
        let noteWithBin = allocation?.binLocation.trim() === '' ? allocation?.notes : `${allocation?.notes} | Bin Location: ${allocation?.binLocation}`;
        const statusArray = Array(tableData.length).fill(false);
        const initialArray = Array(tableData.length).fill('SVP');
        const data = {
            poNo: po?.poNo,
            po_id: tempPoId,
            supplier_name: allocation?.supplierName,
            styleNo: allocation?.styleNo,
            description: allocation?.description,
            color: allocation?.color,
            cost: allocation?.cost,
            msrp: allocation?.msrp,
            notes: noteWithBin,
            status: statusArray,
            binlocation: allocation?.binLocation.toUpperCase() || null,
            storeName: tableData.map(data => data.storeName),
            sizeQuantities: tableData.map(data => data.sizeQuantities),
            receivedQty: sizes.map((size, index) => ({
                size: size,
                quantity: totalProductionQuantities[index],
            })),
            total: totalProductionQuantities.reduce((a, b) => a + b, 0),
            totalAllocationPerSize: sizes.map((_size, index) => calculateTotalAllocation(index)),
            overstockPerSize: sizes.map((_size, index) => calculateOverstock(index)),
            // assuming that allocationId is available
            initial: initialArray
        };

        try {
            const response = await fetch('/allocation/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            if (response.status === 200) {
                setPo({ poId: responseData.data.po_id, poNo: po?.poNo || '' });
                alert('Data saved successfully');
                setIsError(false);
                handleReset(true); // Pass true to skip save confirmation
            } else if (response.status === 400) {
                alert('Style No already exists');
                setMessage('Error while saving data');
                setIsError(true);
            } else {
                alert(message ? message : 'Error while saving data');
            }
            setModalIsOpen(true); // Open the modal
        } catch (error: any) {
            setMessage('Error while saving data: ' + error.message);
            setIsError(true);
            setModalIsOpen(true); // Open the modal
        }
    };
    const downloadAllPDFs = async () => {
        if (sizes.length === 0 || tableData.length === 0 || totalProductionQuantities.length === 0) {
            alert('Please enter appropriate quantity for each size');
            return;
        }
        await allocationPaperPDF(
            sizes,
            tableData,
            totalProductionQuantities,
            totalProductionSum,
            allocation,
            po?.poNo || ''
        );
        await sizeAllocationPDF(sizes, 6, tableData, allocation.styleNo);
    }

    return (
        <div>
            <NavBar />
            <div className={classes.main}>
                <div>
                    <Grid>
                        <Grid.Col span={{ xs: 12, sm: 12, md: 3, xl: 3 }} className={classes.gridColDiv}>
                            <div style={{ marginTop: '30px', height: '35vh', overflow: 'auto', marginBottom: '10px', scrollbarWidth: 'none' }}>
                                <StyleTable styleData={styleTableData} style_no={allocation?.styleNo} />
                            </div>
                        </Grid.Col>
                        <Grid.Col span={{ xs: 12, sm: 12, md: 6, xl: 6 }} className={classes.gridColDiv}>
                            <h2 className={'title'} style={{ marginTop: '30px' }}>Restock</h2>
                            <div className={classes.gridRowDiv} >
                                <Text className={classes.textWithInput}>Po No:</Text>
                                <Select
                                    data={Array.from({ length: poNoList.length }, (_, index) => poNoList[index].poNo)}
                                    placeholder="Select Po No"
                                    style={{ flexGrow: 1 }}
                                    value={po?.poNo || null}
                                    searchable
                                    clearable
                                    onChange={handlePoNoChange}
                                    onSearchChange={handleSearchChange}
                                />
                            </div>
                            <Grid>
                                <Grid.Col span={{ xs: 12, sm: 6, md: 6, xl: 6 }} className={classes.gridColDiv}>
                                    <div className={classes.gridRowDiv} >
                                        <Text className={classes.textWithInput}>Supplier:</Text>
                                        {/* <TextInput style={{ flexGrow: 1 }} value={allocation?.supplierName} onChange={(e) => setAllocation({ ...allocation, supplierName: e.target.value })} /> */}
                                        <Select
                                            data={supplierList}
                                            style={{ flexGrow: 1 }}
                                            searchable
                                            clearable
                                            value={allocation.supplierName}
                                            onChange={handleSupplierChange}
                                            onSearchChange={handleSupplierSearch}
                                        />
                                    </div>
                                    <div className={classes.gridRowDiv}>
                                        <Text className={classes.textWithInput}>Style No:</Text>
                                        <TextInput
                                            style={{ flexGrow: 1 }}
                                            value={allocation?.styleNo}
                                            onChange={(e) => setAllocation({ ...allocation, styleNo: e.target.value })}
                                            onBlur={handleStyleChange}
                                        />
                                    </div>
                                    <div className={classes.gridRowDiv} >
                                        <Text className={classes.textWithInput}>Description:</Text>
                                        <TextInput style={{ flexGrow: 1 }} value={allocation?.description} onChange={(e) => setAllocation({ ...allocation, description: e.target.value })} />
                                    </div>
                                    <div className={classes.gridRowDiv} >
                                        <Text className={classes.textWithInput}>Notes:</Text>
                                        <TextInput style={{ flexGrow: 1 }} value={allocation?.notes} onChange={(e) => setAllocation({ ...allocation, notes: e.target.value })} />
                                    </div>
                                </Grid.Col>
                                <Grid.Col span={{ xs: 12, sm: 6, md: 6, xl: 6 }} className={classes.gridColDiv}>
                                    <div className={classes.gridRowDiv} >
                                        <Text className={classes.textWithInput}>Color:</Text>
                                        <TextInput style={{ flexGrow: 1 }} value={allocation?.color} onChange={(e) => setAllocation({ ...allocation, color: e.target.value })} />
                                    </div>
                                    <div className={classes.gridRowDiv} >
                                        <Text className={classes.textWithInput}>Cost:</Text>
                                        <TextInput style={{ flexGrow: 1 }} value={allocation?.cost} onChange={(e) => setAllocation({ ...allocation, cost: e.target.value })} />
                                    </div>
                                    <div className={classes.gridRowDiv} >
                                        <Text className={classes.textWithInput}>MSRP:</Text>
                                        <TextInput style={{ flexGrow: 1 }} value={allocation?.msrp} onChange={(e) => setAllocation({ ...allocation, msrp: e.target.value })} />
                                    </div>
                                    <div className={classes.gridRowDiv} >
                                        <Text className={classes.textWithInput}>BIN:</Text>
                                        <TextInput style={{ flexGrow: 1 }} value={allocation?.binLocation} onChange={(e) => setAllocation({ ...allocation, binLocation: e.target.value })} />
                                    </div>
                                </Grid.Col>
                            </Grid>
                        </Grid.Col>
                        <Grid.Col span={{ xs: 12, sm: 12, md: 3, xl: 3 }} className={classes.gridColDiv}>
                            <div className={classes.gridColDiv} style={{ marginTop: '30px', height: '35vh', overflow: 'auto', marginBottom: '10px' }}>
                                <InvoiceTable poNo={po?.poNo} poId={po?.poId} />
                            </div>
                        </Grid.Col>
                    </Grid>
                </div>
                <div className={classes.sizeStoreDiv} style={{ backgroundColor: theme.colors.gray[1], color: theme.colors.gray[10] }}>
                    <div className={classes.rowDiv} >
                        <Text className={classes.textWithInput}>Sizes:</Text>
                        <TextInput size='xs' style={{ flexGrow: 1 }} value={sizes} onChange={handleSizeChange} />
                    </div>
                    <div className={classes.rowDiv} >
                        <Text className={classes.textWithInput}>Stores:</Text>
                        <div style={{ flexGrow: 1 }} >
                            <Group className={classes.checkboxGroup}  >
                                <Checkbox color={theme.colors.gray[8]} defaultChecked value="checkall" label="Check All" onChange={(event) => handleCheckAll(event.currentTarget.checked)} />
                                {STORES.map((store) => <Checkbox
                                    color={theme.colors.gray[8]}
                                    key={store}
                                    label={STORES_PAIRS[store]}
                                    checked={stores.find((item) => item.store === store)?.checked}
                                    onChange={(event) => {
                                        setStores((prev) => prev.map((item) => item.store === store ? { ...item, checked: !item.checked } : item))
                                        // adjust the size of the standard quantity based on the checked status
                                        // setStandardQuantities(stores.map((item: { store:string, checked:boolean}, index)=> item.checked ? standardQuantities[index] : -1).filter((item:number)=> item !== -1));
                                    }}
                                    value={store}
                                />)}
                            </Group>
                        </div>
                    </div>
                </div>
                <div>
                    <AllocationTable
                        stores={stores}
                        sizes={sizes}
                        totalProductionQuantities={totalProductionQuantities}
                        setTotalProductionQuantities={setTotalProductionQuantities}
                        standardQuantities={standardQuantities}
                        setStandardQuantities={setStandardQuantities}
                        tableData={tableData}
                        setTableData={setTableData}
                        calculateClicked={calculateClicked}
                        setCalculateClicked={setCalculateClicked}
                        calculateBtnClicked={calculateBtnClicked}
                        totalProductionSum={totalProductionSum}
                        setTotalProductionSum={setTotalProductionSum}
                    />
                </div>
                <div className={classes.buttonDiv}>
                    <Button style={{ backgroundColor: theme.colors.gray[9] }} onClick={handleCalculateClick} >Calculate</Button>
                    <Button style={{ backgroundColor: theme.colors.gray[9] }} onClick={() => handleReset()} >Reset</Button>
                    <Button style={{ backgroundColor: theme.colors.gray[9] }} onClick={onSave} >Save</Button>
                    <Button style={{ backgroundColor: theme.colors.gray[9] }} onClick={downloadAllPDFs} >Print</Button>
                </div>
            </div>
        </div>
    );
}

export default RestockCalculator;